import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import CountdownClock from 'react-countdown-clock';
import { Button, LoadingSpinner, Timer } from '../../components';
import { ArrowLeftLongIcon, ArrowRightLongIcon, InfoCircleIcon, PauseIcon, PlayIcon, VolumeOffIcon } from '../../icons';
import { SkipExercisePopup } from '../SkipExercisePopup';
import { ExitTrainingPopup } from '../ExitTrainingPopup';
import { CONFIG } from '../../constants';
import { fetchAccountProfile, fetchDailyExercises, fetchTrainStatus } from '../../redux/apis';
import { useDispatch, useSelector } from '../../redux/store';
import { ExerciseModel } from '../../resources/models';
import { ExercisesService } from '../../services';

export interface TrainingModalProps {
  className?: string;
  exercise: ExerciseModel;
  reportWhenFinished?: boolean;
  onClose(result: 'finished' | 'skipped' | 'cancelled'): void;
}

export enum TRAIN_STATUS {
  READY = 'ready',
  PLAYING = 'playing',
  FINISHED = 'finished',
}

export enum TRAIN_SIDE {
  LEFT = 'left',
  RIGHT = 'right',
}

const timerThemes = {
  default: {
    bg: '#041D24',
    text: '#FFF',
    arrow: '#97B7BE',
    active: '#23B3D6',
    inactive: '#03617B',
    overlay: '#000000AD',
  },
};

export const TrainingModal: FC<TrainingModalProps> = ({ className, exercise, reportWhenFinished = true, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const videoRef = useRef<HTMLVideoElement>();
  const audioRef = useRef(new Audio('/assets/audios/exercise_music.mp3'));
  const { profile } = useSelector((state) => state.patient);

  const [status, setStatus] = useState(TRAIN_STATUS.READY);
  const [side, setSide] = useState(exercise.getLeftTotalTime() ? TRAIN_SIDE.LEFT : TRAIN_SIDE.RIGHT);
  const [readyTime, setReadyTime] = useState(20);
  const [remainTime, setRemainTime] = useState(exercise.getLeftTotalTime());
  const [leftCount, setLeftCount] = useState(exercise.getLeftRepeatCount());
  const [rightCount, setRightCount] = useState(exercise.isSymmetric() ? 0 : exercise.getRightRepeatCount());
  const [playing, setPlaying] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState<boolean>();
  const [popup, _setPopup] = useState<string>();

  const timerTheme = timerThemes[CONFIG.THEME] || timerThemes.default;

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    if (audioRef.current) {
      audioRef.current.loop = true;
      audioRef.current.play();
    }

    return () => {
      document.body.style.overflow = 'auto';
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.remove();
      }
    };
  }, []);

  const onFinish = () => {
    if (!reportWhenFinished) {
      onClose('finished');
      return;
    }

    ExercisesService.finishTraining(profile.id, exercise.id, { doneAt: new Date().toISOString() })
      .then(() => {
        dispatch(fetchAccountProfile({ force: true, showSpinner: false, showError: false }));
        dispatch(fetchDailyExercises({ force: true, showSpinner: false, showError: false }));
        dispatch(fetchTrainStatus({ force: true, showSpinner: false, showError: false }));
      })
      .finally(() => {
        onClose('finished');
      });
  };

  const onReadyTimeout = (time) => {
    if (time <= 0) {
      onClickSkipButton();
    } else {
      setReadyTime(time);
    }
  };

  const onTrainTimeout = (time) => {
    setRemainTime(time);
    if (!isPlaying) {
      videoRef.current.pause();
    }
    if (side === TRAIN_SIDE.LEFT) {
      if (time <= 0) {
        if (!exercise.isSymmetric() && exercise.getRightRepeatCount()) {
          setLeftCount(0);
          setSide(TRAIN_SIDE.RIGHT);
          setReadyTime(20);
          setRemainTime(exercise.getRightTotalTime());
          setStatus(TRAIN_STATUS.READY);
        } else {
          setRemainTime(0);
          setPlaying(false);
          setStatus(TRAIN_STATUS.FINISHED);
          onFinish();
        }
        videoRef.current.pause();
        return;
      }
      if (time % exercise.getLeftActionTime() === 0) {
        setLeftCount(time / exercise.getLeftActionTime());
        videoRef.current.currentTime = 0;
        videoRef.current.play();
      }
    } else {
      if (time <= 0) {
        setRemainTime(0);
        setPlaying(false);
        setStatus(TRAIN_STATUS.FINISHED);
        videoRef.current.pause();
        onFinish();
        return;
      }
      if (time % exercise.getRightActionTime() === 0) {
        setRightCount(time / exercise.getRightActionTime());
        videoRef.current.currentTime = 0;
        videoRef.current.play();
      }
    }
  };

  const setPopup = (popup) => {
    _setPopup(popup);
    if (popup) {
      setPlaying(false);
      if (status === TRAIN_STATUS.PLAYING) {
        videoRef.current.pause();
      }
    } else if (videoLoaded) {
      if (status === TRAIN_STATUS.PLAYING) {
        const totalTime = side === TRAIN_SIDE.LEFT ? exercise.getLeftActionTime() : exercise.getRightActionTime();
        videoRef.current.currentTime = remainTime % totalTime;
        videoRef.current.play();
      }
      setPlaying(true);
    }
  };

  const onVideoLoaded = () => {
    setVideoLoaded(true);
    setPlaying(true);
  };

  const exerciseUrl = useMemo(() => exercise.getAnimation().src, [exercise]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.src = exerciseUrl;
      videoRef.current.preload = 'auto';
      videoRef.current.load();
    }
  }, [exerciseUrl]);

  const onAddTime = useCallback((secs: number) => {
    setReadyTime((prev) => prev + secs);
  }, []);

  const onTogglePause = useCallback(() => {
    setPlaying((prev) => {
      if (prev) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      return !prev;
    });
  }, []);

  const onClickSkipButton = () => {
    if (videoLoaded && status === TRAIN_STATUS.READY) {
      setReadyTime(0);

      let trainSide = side;
      if (trainSide === TRAIN_SIDE.LEFT && leftCount <= 0) {
        trainSide = TRAIN_SIDE.RIGHT;
        setSide(trainSide);
      }
      if (trainSide === TRAIN_SIDE.RIGHT && rightCount <= 0) {
        setPlaying(false);
        onClose('skipped');
        return;
      }

      if (trainSide === TRAIN_SIDE.LEFT) {
        setRemainTime(exercise.getLeftTotalTime());
      } else {
        setRemainTime(exercise.getRightTotalTime());
      }

      videoRef.current.currentTime = 0;
      videoRef.current.play();
      setStatus(TRAIN_STATUS.PLAYING);
    } else {
      setPopup('skip');
    }
  };

  const onSkip = () => {
    onClose('skipped');
  };

  const isPlaying = playing && remainTime > 0 && !popup;

  const modalClass = classNames(
    'training-modal fixed top-0 left-0 w-screen h-screen z-max bg-training-bg cursor-default',
    popup && 'hidden',
    className,
  );
  const modalContentClass = classNames(
    'w-full h-full flex-center flex-col bg-training-overlay text-training-text px-6 py-10 transition-all',
  );

  if (videoLoaded === false) {
    return (
      <div className={modalClass}>
        <div className={modalContentClass}>
          <div className="typo-h1">{t('toast.loadingVideoFailed')}</div>
          <Button className="absolute right-10 top-10" variant="text" onClick={() => onClose('cancelled')}>
            <i className="fa fa-times text-2xl" />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={modalClass}>
        <div className={modalContentClass}>
          <div className="flex-center relative flex-col">
            <div className="pointer-events-none absolute h-72 w-72 bg-primary opacity-10 blur-3xl" />

            {status === TRAIN_STATUS.READY && (
              <div className="font-weight-medium text-training-text">{t('common.getReady')}</div>
            )}
            {status === TRAIN_STATUS.READY ? (
              <Timer
                className="mt-2 text-8xl font-light text-training-text"
                time={readyTime}
                playing={playing && readyTime > 0 && !popup}
                onTick={onReadyTimeout}
              />
            ) : (
              <Timer
                className="mt-2 text-8xl font-light text-training-text"
                time={remainTime}
                playing={isPlaying}
                onTick={onTrainTimeout}
              />
            )}
            {status === TRAIN_STATUS.PLAYING && isPlaying && (
              <PauseIcon className="mt-2 cursor-pointer text-training-text" size={32} onClick={onTogglePause} />
            )}
          </div>
          <div className={classNames('flex-center relative mb-2 mt-4 h-[50vh] flex-1')}>
            <video
              ref={videoRef}
              className={classNames('max-h-full max-w-full rounded-10p', side === TRAIN_SIDE.RIGHT && 'rotate-y-180')}
              playsInline
              onCanPlayThrough={onVideoLoaded}
              onError={() => setVideoLoaded(false)}
            />
            {!videoLoaded && <LoadingSpinner className="absolute" />}
          </div>
          <div className="mt-6 flex flex-col items-center">
            {videoLoaded && status === TRAIN_STATUS.READY && (
              <>
                {side === TRAIN_SIDE.LEFT && rightCount > 0 && (
                  <div
                    className="typo-h2 mb-9 cursor-pointer !text-training-text"
                    onClick={() => setSide(TRAIN_SIDE.RIGHT)}
                  >
                    {t('common.rightSide')} <i className="fa fa-arrow-right ml-2" />
                  </div>
                )}
                {side === TRAIN_SIDE.RIGHT && leftCount > 0 && (
                  <div
                    className="typo-h2 mb-9 cursor-pointer !text-training-text"
                    onClick={() => setSide(TRAIN_SIDE.LEFT)}
                  >
                    <i className="fa fa-arrow-left mr-2" /> {t('common.leftSide')}
                  </div>
                )}
                <Button className="!w-84" theme="secondary" onClick={() => onAddTime(5)}>
                  +5S
                </Button>
              </>
            )}
            {status === TRAIN_STATUS.PLAYING && (
              <>
                <div className="typo-h2 !text-training-text">{exercise.getLocalizedContent()?.title || ''}</div>
                <div className="mt-4 flex">
                  {exercise.getLeftRepeatCount() > 0 && (
                    <div
                      className="flex-center relative mx-3 overflow-hidden rounded-full"
                      style={{ background: timerTheme.bg, color: timerTheme.text }}
                    >
                      <CountdownClock
                        key={`${side}-${leftCount}`}
                        seconds={side === TRAIN_SIDE.LEFT ? exercise.getLeftActionTime() : 0}
                        size={100}
                        alpha={0.9}
                        weight={side === TRAIN_SIDE.LEFT ? 2 : 1}
                        fontSize={0}
                        color={side === TRAIN_SIDE.LEFT ? timerTheme.active : timerTheme.inactive}
                        paused={side !== TRAIN_SIDE.LEFT || !isPlaying}
                        showMilliseconds={false}
                      />
                      <div className="flex-center absolute">
                        <ArrowLeftLongIcon className="mr-2" style={{ color: timerTheme.arrow }} />
                        <span className="typo-button">{leftCount}</span>
                      </div>
                      {side !== TRAIN_SIDE.LEFT && (
                        <div className="absolute h-full w-full" style={{ background: timerTheme.overlay }} />
                      )}
                    </div>
                  )}
                  {!exercise.isSymmetric() && exercise.getRightRepeatCount() > 0 && (
                    <div
                      className="flex-center relative mx-3 overflow-hidden rounded-full"
                      style={{ background: timerTheme.bg, color: timerTheme.text }}
                    >
                      <CountdownClock
                        key={`${side}-${rightCount}`}
                        seconds={side === TRAIN_SIDE.RIGHT ? exercise.getRightActionTime() : 0}
                        size={100}
                        alpha={0.9}
                        weight={side === TRAIN_SIDE.RIGHT ? 2 : 1}
                        fontSize={0}
                        color={side === TRAIN_SIDE.RIGHT ? timerTheme.active : timerTheme.inactive}
                        paused={side !== TRAIN_SIDE.RIGHT || !isPlaying}
                        showMilliseconds={false}
                      />
                      <div className="flex-center absolute">
                        <span className="typo-button">{rightCount}</span>
                        <ArrowRightLongIcon className="ml-2" style={{ color: timerTheme.arrow }} />
                      </div>
                      {side !== TRAIN_SIDE.RIGHT && (
                        <div className="absolute h-full w-full" style={{ background: timerTheme.overlay }} />
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          {status === TRAIN_STATUS.PLAYING && !playing && (
            <div className="flex-center absolute left-0 top-0 z-[100] h-full w-full bg-overlay1">
              <Button
                className="!w-12.5 !rounded-full !p-0"
                theme="secondary"
                link={`/patients/${profile.id}/exercises/${exercise.id}/learn`}
              >
                <InfoCircleIcon size={24} />
              </Button>
              <Button className="mx-6 !h-25 !w-25 !rounded-full !p-0" theme="primary" onClick={onTogglePause}>
                <PlayIcon size={40} />
              </Button>
              <Button className="!w-12.5 !rounded-full !p-0" theme="secondary">
                <VolumeOffIcon size={24} />
              </Button>
            </div>
          )}
        </div>

        <Button
          className="absolute left-4 top-4 sm:left-16 sm:top-10"
          variant="text"
          onClick={() => setPopup('cancel')}
        >
          <i className="fa fa-arrow-left mr-3 text-xs" /> {t('common.cancel')}
        </Button>

        {videoLoaded && (
          <Button className="absolute right-4 top-4 sm:right-16 sm:top-10" variant="text" onClick={onClickSkipButton}>
            {t('common.skip')} <i className="fa fa-arrow-right ml-3 text-xs" />
          </Button>
        )}
      </div>

      {popup === 'skip' && (
        <SkipExercisePopup exercise={exercise} onSkip={onSkip} onClose={() => setPopup(undefined)} />
      )}

      {popup === 'cancel' && (
        <ExitTrainingPopup
          exercise={exercise}
          onExit={() => onClose('cancelled')}
          onClose={() => setPopup(undefined)}
        />
      )}
    </>
  );
};
