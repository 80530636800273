import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Appointments } from './Home';
import { AppointmentDetail } from './Detail';
import { AppointmentCall } from './Call';

export const AppointmentsRouting = () => {
  return (
    <Routes>
      <Route index element={<Appointments />} />
      <Route path=":id" element={<AppointmentDetail />} />
      <Route path=":id/call" element={<AppointmentCall />} />
    </Routes>
  );
};
