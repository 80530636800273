import React, { FC, MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Image } from '../../components';
import { ExerciseModel } from '../../resources/models';
import { useSelector } from '../../redux/store';

export interface ExerciseCardProps {
  className?: string;
  exercise: ExerciseModel;
  active: boolean;
  hideIcon?: boolean;
  onClick(): void;
}

export const ExerciseCard: FC<ExerciseCardProps> = ({ className, exercise, active, hideIcon, onClick }) => {
  const navigate = useNavigate();
  const { profile } = useSelector((root) => root.patient);

  const isLocked = !active && !exercise.scan?.doneAt;

  const onView = () => {
    if (!active) {
      return;
    }

    onClick();
  };

  const onViewInfo: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(`/patients/${profile.id}/exercises/${exercise.id}/learn`);
  };

  return (
    <div className={classNames('card1 flex h-22', active && 'cursor-pointer', className)} onClick={onView}>
      <Image
        wrapperClass="!w-auto h-full bg-black shrink-0"
        src={exercise.getCover()?.src}
        aspectRatio={1}
        spinner
        spinnerSize="sm"
      />
      <div className="flex flex-1 items-center justify-between px-4 py-2">
        <div className="typo-sm mr-4 max-w-36">{exercise.getLocalizedContent()?.title || ''}</div>
        {!hideIcon &&
          (active ? (
            <i className="fa fa-info-circle text-2xl text-primary" onClick={onViewInfo} />
          ) : isLocked ? (
            <i className="fa fa-lock text-2xl text-secondary" />
          ) : (
            <i className="fa fa-check-circle text-2xl text-secondary" />
          ))}
      </div>
    </div>
  );
};
