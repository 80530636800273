import { FC, useState } from 'react';
import * as Yup from 'yup';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormProvider, RHFTextField } from '../../components';
import { fetchAccountProfile } from '../../redux/apis';
import { getAccount } from '../../redux/selectors';
import { useDispatch, useSelector } from '../../redux/store';
import { AuthService, ToastService } from '../../services';

type FormData = {
  name: string;
};

export interface NameEditorProps {
  className?: string;
  onBack: () => void;
}

export const NameEditor: FC<NameEditorProps> = ({ className, onBack }) => {
  const dispatch = useDispatch();
  const user = useSelector(getAccount);
  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState(false);

  const formSchema = Yup.object().shape({
    name: Yup.string().required(t('validationErrors.requiredField')),
  });

  const defaultValues = {
    name: user?.name || '',
  };

  const methods = useForm<FormData>({
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const onSubmit = async (form: FormData) => {
    setSubmitting(true);
    AuthService.updateDoctorProfile({ name: form.name }, false)
      .then(() => {
        ToastService.success('toast.usernameUpdated');
        dispatch(fetchAccountProfile({ force: true, showSpinner: false, showError: false }));
      })
      .catch((err) => ToastService.showHttpError(err, 'toast.changeUsernameFailed'))
      .finally(() => setSubmitting(false));
  };

  return (
    <div className={classNames('flex h-full flex-col gap-6', className)}>
      <div className="flex-center relative">
        <div className="typo-link absolute left-0 p-2 font-semibold text-primary" onClick={onBack}>
          <i className="fa fa-angle-left" />
          <span className="ml-1.5">{t('common.back')}</span>
        </div>
        <h2 className="text-center">{t('settings.username')}</h2>
      </div>

      <FormProvider className="flex flex-col gap-4" methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <RHFTextField name="name" />
        <Button type="submit" fullWidth loading={submitting} disabled={!isValid}>
          {t('common.save')}
        </Button>
      </FormProvider>
    </div>
  );
};
