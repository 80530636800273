import React, { FC, MouseEventHandler } from 'react';
import classNames from 'classnames';

export interface RadioProps {
  className?: string;
  checked?: boolean;
  onClick?: MouseEventHandler;
}

export const Radio: FC<RadioProps> = ({ className, checked, onClick }) => {
  return (
    <div className={classNames('flex-center h-6 w-6 rounded-full border border-primary', className)} onClick={onClick}>
      {checked && <div className="h-4 w-4 rounded-full bg-primary" />}
    </div>
  );
};
