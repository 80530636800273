import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaginatedResultDto, PaginateRequestDto } from '../../resources/dtos';
import { EXERCISE_TYPE_VALUES, LANGUAGE, LOADING_STATUS } from '../../resources/enums';
import { ExerciseModel, IssueModel, PhotoTipModel, UserModel } from '../../resources/models';
import { RootState } from '../store';
import {
  setDailyExercises,
  setExercisesLoadingState,
  setFirstAidKits,
  setFirstAidKitsLoadingState,
  setIssues,
  setIssuesLoadingState,
  setPatientProfile,
  setPhotoTips,
  setPhotoTipsLoadingState,
  setProfileLoadingState,
  setScan,
  setSpineScan,
  setTrainStatus,
  setTrainStatusLoadingState,
} from '../reducers/patient.reducer';
import { ITrainStatus } from '../../resources/interfaces';
import { ExercisesService, PatientsService, ScansService, TipsService, ToastService } from '../../services';

export const fetchPatientProfile = createAsyncThunk<
  UserModel,
  {
    patientId: string;
    force?: boolean;
    showSpinner?: boolean;
    showError?: boolean;
  }
>(
  'patient/fetchPatientProfile',
  async (arg, api) => {
    api.dispatch(setProfileLoadingState(LOADING_STATUS.PENDING));
    return PatientsService.find(arg.patientId, arg.showSpinner)
      .then((data) => {
        api.dispatch(setPatientProfile(data));
        api.dispatch(setScan(data.scan));
        api.dispatch(setSpineScan(data.spineScan));
        return data;
      })
      .catch((err) => {
        if (arg.showError ?? true) {
          ToastService.showHttpError(err, 'toast.loadingAccountProfileFailed');
        }
        api.dispatch(setProfileLoadingState(LOADING_STATUS.FAILED));
        api.dispatch(setPatientProfile(undefined));
        throw err;
      });
  },
  {
    condition(arg, api) {
      const root = api.getState() as RootState;
      const { profile } = root.patient;
      return Boolean(arg.force || profile?.id !== arg.patientId);
    },
  },
);

export const fetchDailyExercises = createAsyncThunk<
  ExerciseModel[],
  {
    query?: { lang: LANGUAGE };
    force?: boolean;
    showSpinner?: boolean;
    showError?: boolean;
  }
>(
  'patient/fetchDailyExercises',
  async (arg, api) => {
    api.dispatch(setExercisesLoadingState(LOADING_STATUS.PENDING));
    const root = api.getState() as RootState;
    const { scan } = root.patient;
    return ScansService.getDailyExercises(scan.id, arg.query, arg.showSpinner)
      .then((data) => {
        api.dispatch(setDailyExercises(data));
        api.dispatch(setExercisesLoadingState(LOADING_STATUS.SUCCESS));
        return data;
      })
      .catch((err) => {
        if (arg.showError ?? true) {
          ToastService.showHttpError(err, 'toast.loadingExercisesFailed');
        }
        api.dispatch(setExercisesLoadingState(LOADING_STATUS.FAILED));
        throw err;
      });
  },
  {
    condition(arg, api) {
      const root = api.getState() as RootState;
      const { scan, exercisesLoadingStatus } = root.patient;
      return Boolean(scan && (arg.force || exercisesLoadingStatus === LOADING_STATUS.NONE));
    },
  },
);

export const fetchTrainStatus = createAsyncThunk<
  ITrainStatus,
  {
    force?: boolean;
    showSpinner?: boolean;
    showError?: boolean;
  }
>(
  'patient/fetchTrainStatus',
  async (arg, api) => {
    api.dispatch(setTrainStatusLoadingState(LOADING_STATUS.PENDING));
    const root = api.getState() as RootState;
    const { scan } = root.patient;
    return ScansService.getTrainStatus(scan.id, arg.showSpinner)
      .then((data) => {
        api.dispatch(setTrainStatus(data));
        api.dispatch(setTrainStatusLoadingState(LOADING_STATUS.SUCCESS));
        return data;
      })
      .catch((err) => {
        if (arg.showError ?? true) {
          ToastService.showHttpError(err, 'toast.loadingTrainStatusFailed');
        }
        api.dispatch(setTrainStatusLoadingState(LOADING_STATUS.FAILED));
        throw err;
      });
  },
  {
    condition(arg, api) {
      const root = api.getState() as RootState;
      const { scan, trainStatusLoadingStatus } = root.patient;
      return Boolean(scan && (arg.force || trainStatusLoadingStatus === LOADING_STATUS.NONE));
    },
  },
);

export const fetchIssues = createAsyncThunk<
  PaginatedResultDto<IssueModel>,
  {
    scanId: string;
    query?: PaginateRequestDto;
    force?: boolean;
    showSpinner?: boolean;
    showError?: boolean;
  }
>(
  'patient/fetchIssues',
  async (arg, api) => {
    api.dispatch(setIssuesLoadingState(LOADING_STATUS.PENDING));
    return ScansService.searchFeatures(arg.scanId, arg.query, arg.showSpinner)
      .then((data) => {
        api.dispatch(setIssues(data.result.splice(0, 5)));
        api.dispatch(setIssuesLoadingState(LOADING_STATUS.SUCCESS));
        return data;
      })
      .catch((err) => {
        if (arg.showError ?? true) {
          ToastService.showHttpError(err, 'toast.loadingIssuesFailed');
        }
        api.dispatch(setIssuesLoadingState(LOADING_STATUS.FAILED));
        throw err;
      });
  },
  {
    condition(arg, api) {
      const root = api.getState() as RootState;
      const { issuesLoadingStatus } = root.patient;
      return Boolean(arg.force || issuesLoadingStatus === LOADING_STATUS.NONE);
    },
  },
);

export const fetchPhotoTips = createAsyncThunk<
  PaginatedResultDto<PhotoTipModel>,
  {
    query?: PaginateRequestDto;
    force?: boolean;
    showSpinner?: boolean;
    showError?: boolean;
  }
>(
  'patient/fetchPhotoTips',
  async (arg, api) => {
    api.dispatch(setPhotoTipsLoadingState(LOADING_STATUS.PENDING));
    return TipsService.search(arg.query, arg.showSpinner)
      .then((data) => {
        api.dispatch(setPhotoTips(data.result));
        api.dispatch(setPhotoTipsLoadingState(LOADING_STATUS.SUCCESS));
        return data;
      })
      .catch((err) => {
        if (arg.showError ?? true) {
          ToastService.showHttpError(err, 'toast.loadingPhotoTipsFailed');
        }
        api.dispatch(setPhotoTipsLoadingState(LOADING_STATUS.FAILED));
        throw err;
      });
  },
  {
    condition(arg, api) {
      const root = api.getState() as RootState;
      const { photoTipsLoadingStatus } = root.patient;
      return Boolean(arg.force || photoTipsLoadingStatus === LOADING_STATUS.NONE);
    },
  },
);

export const fetchFirstAidKits = createAsyncThunk<
  ExerciseModel[],
  {
    query?: { lang: LANGUAGE };
    force?: boolean;
    showSpinner?: boolean;
    showError?: boolean;
  }
>(
  'patient/fetchFirstAidKits',
  async (arg, api) => {
    api.dispatch(setFirstAidKitsLoadingState(LOADING_STATUS.PENDING));
    return Promise.all(
      EXERCISE_TYPE_VALUES.map((type) =>
        ExercisesService.getFirstAidKit({ type, lang: arg.query?.lang }, arg.showSpinner).catch(() => undefined),
      ),
    )
      .then((res) => {
        const data = res.filter((item) => !!item);
        api.dispatch(setFirstAidKits(data));
        api.dispatch(setFirstAidKitsLoadingState(LOADING_STATUS.SUCCESS));
        return data;
      })
      .catch((err) => {
        if (arg.showError ?? true) {
          ToastService.showHttpError(err, 'toast.loadingFirstAidKitsFailed');
        }
        api.dispatch(setFirstAidKitsLoadingState(LOADING_STATUS.FAILED));
        throw err;
      });
  },
  {
    condition(arg, api) {
      const root = api.getState() as RootState;
      const { firstAidKitsLoadingStatus } = root.patient;
      return Boolean(arg.force || firstAidKitsLoadingStatus === LOADING_STATUS.NONE);
    },
  },
);
