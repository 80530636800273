import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, Image, ProgressBar } from '../../components';
import { IssueModel } from '../../resources/models';
import { useSelector } from '../../redux/store';

export interface IssueOverviewProps {
  className?: string;
  issue: IssueModel;
  hideButton?: boolean;
}

export const IssueOverview: FC<IssueOverviewProps> = ({ className, issue, hideButton }) => {
  const { t } = useTranslation();
  const { profile } = useSelector((root) => root.patient);

  const progress = issue?.getProbability() || 0;
  const content = issue?.getLocalizedContent();

  if (!content) {
    return null;
  }

  return (
    <div className={classNames('max-w-120', className)}>
      <Image
        wrapperClass="rounded-10p"
        placeholderClass="w-full h-full"
        src={issue.getCover()?.src}
        spinner
        aspectRatio={1}
      />

      <h3 className="mt-4 text-typo3">{content.title}</h3>
      <div className="mt-4">
        <div className="flex items-center justify-between text-xs text-primary">
          <span>{t('patient.healthIssues.issueProbability')}</span>
          <span>{Math.floor(progress)}%</span>
        </div>
        <ProgressBar theme="primary" percent={progress} size="sm" />
      </div>

      <div className="mt-6">
        <h6 className="text-typo3">{t('patient.possibleTriggeredSymptoms')}:</h6>
        <ul className="typo-sm mt-2 list-disc text-input-placeholder">
          {(content?.featureSymptoms || []).map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
        {!hideButton && (
          <Button className="mt-4" theme="primary" fullWidth link={`/patients/${profile.id}/exercises`}>
            {t('patient.goTraining')}
          </Button>
        )}
      </div>
    </div>
  );
};
