import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../components';
import { MainLayout } from '../../../../../layouts';
import { ExerciseDailyProgram, ExerciseTutorialCard, FirstAidKits, PatientLevelCard } from '../../../../../sections';
import { useSelector } from '../../../../../redux/store';

export const Exercises = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { profile } = useSelector((root) => root.patient);

  const onViewDailyProgram = () => {
    navigate(`/patients/${profile.id}/exercises/training`);
  };

  const sidebar = (
    <>
      <ExerciseTutorialCard />
      <FirstAidKits className="mt-10" />
    </>
  );

  return (
    <MainLayout sidebar={sidebar}>
      <PatientLevelCard showPostureScore linkToHome />

      <ExerciseDailyProgram className="mt-6" onViewDetails={onViewDailyProgram} />

      <Button className="mt-8" link="completed">
        {t('patientProfile.completedExercises')}
      </Button>
    </MainLayout>
  );
};
