import { ContentModel } from './content.model';
import { FileModel } from './file.model';
import { ScanIssueModel } from './scan-issue.model';
import { ISSUE_TYPE, GENDER } from '../enums';
import { store } from '../../redux/store';
import { getAccount } from '../../redux/selectors';
import { getLocaleContent } from '../../utils/helpers';

export class IssueModel {
  id: string;
  key: string;
  type: ISSUE_TYPE;
  content: Partial<ContentModel>[];
  coverMale: Partial<FileModel>;
  coverFemale: Partial<FileModel>;
  scans: ScanIssueModel[];
  createdAt: string;
  updatedAt: string;

  constructor(init?: Partial<IssueModel>) {
    const data = {
      id: null,
      ...init,
    };

    this.id = data.id;
    this.key = data.key;
    this.type = data.type;
    if (data.content && typeof data.content === 'object' && !Array.isArray(data.content)) {
      data.content = [data.content];
    }
    this.content = (data.content || []).map((item) => new ContentModel(item));
    if (data.coverMale) {
      this.coverMale = new FileModel(data.coverMale);
    }
    if (data.coverFemale) {
      this.coverFemale = new FileModel(data.coverFemale);
    }
    if (data.scans && typeof data.scans === 'object' && !Array.isArray(data.scans)) {
      data.scans = [data.scans];
    }
    this.scans = (data.scans || []).map((item) => new ScanIssueModel(item));
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

  getCover(): Partial<FileModel> {
    const account = getAccount(store.getState());
    // TODO: check for doctor
    // if (account?.gender === GENDER.FEMALE) {
    //   return this.coverFemale;
    // }
    return this.coverMale;
  }

  getLocalizedContent() {
    return getLocaleContent(this.content);
  }

  getProbability() {
    if (!this.scans?.length) {
      return 0;
    }
    const sum = this.scans.reduce((sum, item) => sum + item.probability, 0);
    return Math.round((sum / 2 / this.scans.length) * 100);
  }
}
