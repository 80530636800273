import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { LOADING_STATUS } from '../../resources/enums';
import { ITrainStatus } from '../../resources/interfaces';
import { ExerciseModel, IssueModel, PhotoTipModel, ScanModel, UserModel } from '../../resources/models';

export interface IPatientState {
  profile?: UserModel;
  profileLoadingStatus: LOADING_STATUS;
  scan?: ScanModel;
  spineScan?: ScanModel;
  lastScan?: ScanModel;
  scanLoadingStatus: LOADING_STATUS;
  issues: IssueModel[];
  issuesLoadingStatus: LOADING_STATUS;

  dailyExercises: ExerciseModel[];
  exercisesLoadingStatus: LOADING_STATUS;
  photoTips: PhotoTipModel[];
  photoTipsLoadingStatus: LOADING_STATUS;
  firstAidKits: ExerciseModel[];
  firstAidKitsLoadingStatus: LOADING_STATUS;
  trainStatus?: ITrainStatus;
  trainStatusLoadingStatus: LOADING_STATUS;
}

const initialState: IPatientState = {
  profile: undefined,
  profileLoadingStatus: LOADING_STATUS.NONE,
  scan: undefined,
  spineScan: undefined,
  lastScan: undefined,
  scanLoadingStatus: LOADING_STATUS.NONE,
  issues: [],
  issuesLoadingStatus: LOADING_STATUS.NONE,

  dailyExercises: [],
  exercisesLoadingStatus: LOADING_STATUS.NONE,
  photoTips: [],
  photoTipsLoadingStatus: LOADING_STATUS.NONE,
  firstAidKits: [],
  firstAidKitsLoadingStatus: LOADING_STATUS.NONE,
  trainStatusLoadingStatus: LOADING_STATUS.NONE,
};

export const PatientSlice = createSlice({
  name: 'Patient',
  initialState,
  reducers: {
    setPatientProfile: (state, action: PayloadAction<UserModel | undefined>) => {
      state.profile = action.payload;
      state.scan = undefined;
      state.spineScan = undefined;
      state.lastScan = undefined;
      state.scanLoadingStatus = LOADING_STATUS.NONE;
      state.issues = [];
      state.issuesLoadingStatus = LOADING_STATUS.NONE;
      state.dailyExercises = [];
      state.exercisesLoadingStatus = LOADING_STATUS.NONE;
      state.trainStatus = undefined;
      state.trainStatusLoadingStatus = LOADING_STATUS.NONE;
    },
    setProfileLoadingState: (state, action: PayloadAction<LOADING_STATUS>) => {
      state.profileLoadingStatus = action.payload;
    },
    setScan: (state, action: PayloadAction<ScanModel>) => {
      state.scan = action.payload;
    },
    setSpineScan: (state, action: PayloadAction<ScanModel>) => {
      state.spineScan = action.payload;
    },
    setLastScan: (state, action: PayloadAction<ScanModel>) => {
      state.lastScan = action.payload;
    },
    setScanLoadingState: (state, action: PayloadAction<LOADING_STATUS>) => {
      state.scanLoadingStatus = action.payload;
    },
    setDailyExercises: (state, action: PayloadAction<ExerciseModel[]>) => {
      state.dailyExercises = action.payload;
    },
    setExercisesLoadingState: (state, action: PayloadAction<LOADING_STATUS>) => {
      state.exercisesLoadingStatus = action.payload;
    },
    setIssues: (state, action: PayloadAction<IssueModel[]>) => {
      state.issues = action.payload;
    },
    setIssuesLoadingState: (state, action: PayloadAction<LOADING_STATUS>) => {
      state.issuesLoadingStatus = action.payload;
    },
    setPhotoTips: (state, action: PayloadAction<PhotoTipModel[]>) => {
      state.photoTips = action.payload;
    },
    setPhotoTipsLoadingState: (state, action: PayloadAction<LOADING_STATUS>) => {
      state.photoTipsLoadingStatus = action.payload;
    },
    setFirstAidKits: (state, action: PayloadAction<ExerciseModel[]>) => {
      state.firstAidKits = action.payload;
    },
    setFirstAidKitsLoadingState: (state, action: PayloadAction<LOADING_STATUS>) => {
      state.firstAidKitsLoadingStatus = action.payload;
    },
    setTrainStatus: (state, action: PayloadAction<ITrainStatus>) => {
      state.trainStatus = action.payload;
    },
    setTrainStatusLoadingState: (state, action: PayloadAction<LOADING_STATUS>) => {
      state.trainStatusLoadingStatus = action.payload;
    },
  },
});

export const {
  setPatientProfile,
  setProfileLoadingState,
  setScan,
  setSpineScan,
  setLastScan,
  setScanLoadingState,
  setDailyExercises,
  setExercisesLoadingState,
  setIssues,
  setIssuesLoadingState,
  setPhotoTips,
  setPhotoTipsLoadingState,
  setFirstAidKits,
  setFirstAidKitsLoadingState,
  setTrainStatus,
  setTrainStatusLoadingState,
} = PatientSlice.actions;

export default PatientSlice.reducer;
