import React, { FC, useEffect, useState } from 'react';
import { AnamnesisFormsPopup } from '../AnamnesisFormsPopup';
import { PolicyAgreementsPopup } from '../PolicyAgreementsPopup';
import { UserModel } from '../../resources/models';

export interface PatientIntakeFormsProps {
  className?: string;
  patient: UserModel;
  onClose(completed: boolean): void;
}

export const PatientIntakeForms: FC<PatientIntakeFormsProps> = ({ className, patient, onClose }) => {
  const [completeAnamnesis, setCompleteAnamnesis] = useState(true);
  const [completeAgreements, setCompleteAgreements] = useState(true);

  useEffect(() => {
    setCompleteAnamnesis(patient.isSubmittedAnamnesis);
    setCompleteAgreements(patient.isCompletedAgreements);
  }, [patient]);

  const onCloseAgreementsForm = (completed: boolean) => {
    if (completed) {
      setCompleteAgreements(true);
    } else {
      onClose(false);
    }
  };

  if (!completeAgreements) {
    return <PolicyAgreementsPopup className={className} patient={patient} onClose={onCloseAgreementsForm} />;
  }

  if (!completeAnamnesis) {
    return <AnamnesisFormsPopup className={className} patient={patient} onClose={onClose} />;
  }

  return null;
};
