import React from 'react';
import { useSelector } from 'react-redux';
import { LoadingSpinner } from '../LoadingSpinner';
import { RootState } from '../../redux/store';

export const SpinnerContainer = () => {
  const { loadingCount } = useSelector((state: RootState) => state.spinner);
  if (loadingCount <= 0) {
    return null;
  }

  return (
    <div className="flex-center fixed left-0 top-0 z-max h-screen w-screen bg-overlay1">
      <LoadingSpinner />
    </div>
  );
};
