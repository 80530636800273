import { FC, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ITab, Tabs } from '../../components';
import { WeeklyHoursForm } from '../WeeklyHoursForm';
import { DateOverridesForm } from '../DateOverridesForm';

export interface AvailabilityEditorProps {
  className?: string;
  onBack: () => void;
}

export const AvailabilityEditor: FC<AvailabilityEditorProps> = ({ className, onBack }) => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState('weekly');

  const tabs = useMemo<ITab[]>(
    () => [
      {
        value: 'weekly',
        label: t('appointments.availability.editor.weeklyHours'),
      },
      {
        value: 'dates',
        label: t('appointments.availability.editor.dateOverrides'),
      },
    ],
    [t],
  );

  return (
    <div className={classNames('flex h-full flex-col gap-4', className)}>
      <div>
        <div className="flex items-center justify-between">
          <div className="typo-link p-2 font-semibold text-primary" onClick={onBack}>
            <i className="fa fa-angle-left" />
            <span className="ml-1.5">{t('common.back')}</span>
          </div>
          <div>
            <h2 className="text-center">{t('appointments.availability.editor.title')}</h2>
          </div>
          <div className="h-6 w-6" />
        </div>
        <p className="mt-2 text-center">{t('appointments.availability.editor.description')}</p>
      </div>
      <Tabs tabs={tabs} value={activeTab} fullWidth onChange={setActiveTab} />

      {activeTab === 'weekly' && <WeeklyHoursForm />}
      {activeTab === 'dates' && <DateOverridesForm />}
    </div>
  );
};
