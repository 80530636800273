import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ScanPhoto } from '../ScanPhoto';
import { PHOTO_TYPE, SCAN_TYPE, STATUS } from '../../resources/enums';
import { ScanModel } from '../../resources/models';
import { ScansService, ToastService } from '../../services';
import { useSelector } from '../../redux/store';

export interface IScanPhotosViewProps {
  className?: string;
  scan?: ScanModel;
  link?: boolean;
  showText?: boolean;
}

export const ScanPhotosView: FC<IScanPhotosViewProps> = ({ className, scan, link, showText = false }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { profile } = useSelector((state) => state.patient);

  const onScanPhoto = async (type: PHOTO_TYPE) => {
    if (!link) {
      return;
    }

    let currentScan = scan;
    if (!currentScan) {
      currentScan = await ScansService.createPatientScan({ id: profile.id, type: SCAN_TYPE.POSTURE }).catch((err) => {
        ToastService.showHttpError(err, 'toast.creatingScanFailed');
        return undefined;
      });
    }

    if (!currentScan) {
      return;
    }

    if (currentScan.status === STATUS.PENDING) {
      navigate(`/patients/${profile.id}/scanner/${currentScan.id}/photos/${type}`);
    }
  };

  return (
    <div className={className}>
      <div className="grid grid-cols-3 gap-4">
        <ScanPhoto
          scan={scan}
          type={PHOTO_TYPE.FRONT}
          aspectRatio={3 / 4}
          label={showText ? `1. ${t('common.front')}` : null}
          onClick={link ? () => onScanPhoto(PHOTO_TYPE.FRONT) : undefined}
        />
        <ScanPhoto
          scan={scan}
          type={PHOTO_TYPE.SIDE}
          aspectRatio={3 / 4}
          label={showText ? `2. ${t('common.side')}` : null}
          onClick={link ? () => onScanPhoto(PHOTO_TYPE.SIDE) : undefined}
        />
        <ScanPhoto
          scan={scan}
          type={PHOTO_TYPE.SIT}
          aspectRatio={3 / 4}
          label={showText ? `3. ${t('common.backSitting')}` : null}
          onClick={link ? () => onScanPhoto(PHOTO_TYPE.SIT) : undefined}
        />
      </div>
    </div>
  );
};
