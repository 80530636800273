import React, { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ExerciseIcon, ScannerIcon } from '../../../../icons';
import { MainLayout } from '../../../../layouts';
import {
  CompareScanResults,
  CreditsCard,
  IssueOverview,
  PatientLevelCard,
  PatientProfileEdit,
  PatientProfileView,
  PatientScanOverview,
  ScanResultOverview,
  ScheduleConsultationButton,
  UnlockScanButton,
} from '../../../../sections';
import { setScan } from '../../../../redux/reducers/patient.reducer';
import { getAccount } from '../../../../redux/selectors';
import { useDispatch, useSelector } from '../../../../redux/store';
import { IssueModel, ScanModel } from '../../../../resources/models';

enum ViewMode {
  DEFAULT,
  EDIT_PROFILE,
  VIEW_SCAN,
  VIEW_ISSUE,
  COMPARE_SCANS,
}

export const PatientProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const account = useSelector(getAccount);
  const { profile, scan } = useSelector((root) => root.patient);

  const [viewMode, setViewMode] = useState(ViewMode.DEFAULT);
  const [selectedScan, setSelectedScan] = useState<ScanModel>();
  const [selectedIssue, setSelectedIssue] = useState<IssueModel>();
  const [comparingScans, setComparingScans] = useState<[ScanModel, ScanModel]>();

  const onViewScan = (scan: ScanModel) => {
    setSelectedScan(scan);
    setViewMode(ViewMode.VIEW_SCAN);
  };

  const onViewIssue = (issue: IssueModel) => {
    setSelectedIssue(issue);
    setViewMode(ViewMode.VIEW_ISSUE);
  };

  const onCompareScans = (scans: [ScanModel, ScanModel]) => {
    setComparingScans(scans);
    setViewMode(ViewMode.COMPARE_SCANS);
  };

  const onDeleteViewScan = () => {
    setSelectedScan(undefined);
    setViewMode(ViewMode.DEFAULT);
  };

  const onUnlockViewScan = () => {
    selectedScan.isSubscribed = true;
    const updatedScan = new ScanModel(selectedScan);
    setSelectedScan(updatedScan);
    if (selectedScan.id === scan?.id) {
      onUnlockScan(selectedScan);
    }
  };

  const onUnlockScan = (scan: ScanModel) => {
    dispatch(
      setScan(
        new ScanModel({
          ...scan,
          isSubscribed: true,
        }),
      ),
    );
  };

  const sidebar =
    viewMode === ViewMode.EDIT_PROFILE ? (
      <div>
        <div className="typo-link mb-4 font-semibold text-primary" onClick={() => setViewMode(ViewMode.DEFAULT)}>
          <i className="fa fa-angle-left" />
          <span className="ml-1.5">{t('common.back')}</span>
        </div>
        <PatientProfileEdit onUpdate={() => setViewMode(ViewMode.DEFAULT)} />
      </div>
    ) : viewMode === ViewMode.VIEW_SCAN ? (
      <div className="relative">
        <div
          className="typo-link absolute left-0 top-0 p-2 font-semibold text-primary"
          onClick={() => setViewMode(ViewMode.DEFAULT)}
        >
          <i className="fa fa-angle-left" />
          <span className="ml-1.5">{t('common.back')}</span>
        </div>
        <ScanResultOverview
          scan={selectedScan}
          onViewIssue={onViewIssue}
          onUnlock={onUnlockViewScan}
          onDelete={onDeleteViewScan}
        />
      </div>
    ) : viewMode === ViewMode.VIEW_ISSUE ? (
      <>
        <div className="typo-link p-2 font-semibold text-primary" onClick={() => setViewMode(ViewMode.VIEW_SCAN)}>
          <i className="fa fa-angle-left" />
          <span className="ml-1.5">{t('common.back')}</span>
        </div>
        <IssueOverview className="mt-4" issue={selectedIssue} />
      </>
    ) : viewMode === ViewMode.COMPARE_SCANS ? (
      <div className="relative">
        <div
          className="typo-link absolute left-0 top-0 p-2 font-semibold text-primary"
          onClick={() => setViewMode(ViewMode.DEFAULT)}
        >
          <i className="fa fa-angle-left" />
          <span className="ml-1.5">{t('common.back')}</span>
        </div>
        <CompareScanResults prevScan={comparingScans[0]} currentScan={comparingScans[1]} />
      </div>
    ) : (
      <div className="flex flex-col gap-6">
        {account.credits < 10 && <CreditsCard />}
        <PatientProfileView editable onEdit={() => setViewMode(ViewMode.EDIT_PROFILE)} />
        <div className="grid grid-cols-2 gap-x-4 gap-y-6">
          <Link
            className="card1 flex flex-col items-center gap-1 px-6 py-4 text-gray-dark transition-all hover:text-primary"
            to={`/patients/${profile.id}/scanner`}
          >
            <ScannerIcon size={32} />
            <span className="typo-button">{t('common.scanner')}</span>
          </Link>
          <Link
            className={classNames(
              'card1 flex flex-col items-center gap-1 px-6 py-4 text-gray-dark transition-all hover:text-primary',
              !scan?.isSubscribed && 'pointer-events-none !border-gray opacity-50',
            )}
            to={`/patients/${profile.id}/exercises`}
          >
            <ExerciseIcon size={32} />
            <span className="typo-button">{t('common.exercises')}</span>
          </Link>
        </div>
        <UnlockScanButton className="w-full" scan={scan} onUnlock={() => onUnlockScan(scan)} />
        <ScheduleConsultationButton />
      </div>
    );

  return (
    <MainLayout sidebar={sidebar} sidebarOverlap={Boolean(viewMode)}>
      <PatientLevelCard editable onEdit={() => setViewMode(ViewMode.EDIT_PROFILE)} />

      <PatientScanOverview className="mt-8" onViewScan={onViewScan} onCompareScans={onCompareScans} />
    </MainLayout>
  );
};
