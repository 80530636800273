import { useCallback, useEffect, useState } from 'react';

export interface WindowSize {
  windowWidth: number;
  windowHeight: number;
  portraitWindow: boolean;
}

export function useWindowSize(): WindowSize {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const onResize = useCallback(() => {
    setWidth(Math.min(window.innerWidth, window.outerWidth));
    setHeight(Math.min(window.innerHeight, window.outerHeight));
  }, []);

  useEffect(() => {
    const resizeHandler = () => {
      setTimeout(onResize, 0);
      setTimeout(onResize, 200);
      setTimeout(onResize, 500);
      setTimeout(onResize, 1000);
      setTimeout(onResize, 2000);
    };

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [onResize]);

  return {
    windowWidth: width,
    windowHeight: height,
    portraitWindow: width < height,
  };
}
