import React, { FC } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Popup } from '../../components';

export interface FillIntakeFormPopupProps {
  className?: string;
  onContinue(): void;
}

export const FillIntakeFormPopup: FC<FillIntakeFormPopupProps> = ({ className, onContinue }) => {
  const { t } = useTranslation();

  return (
    <Popup contentClass={classNames('text-typo1', className)} width="21rem" disableBackdrop>
      <h1>{t('patientList.fillIntakeFormPopup.title')}</h1>
      <p className="mt-1 text-gray-dark">
        <Trans i18nKey="patientList.fillIntakeFormPopup.content" />
      </p>
      <Button className="mt-4" fullWidth onClick={onContinue}>
        {t('common.continue')}
      </Button>
    </Popup>
  );
};
