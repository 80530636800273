import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Cropper, { Area } from 'react-easy-crop';
import { CheckIcon, CloseIcon } from '../../icons';
import { IPhoto } from '../../resources/interfaces';
import { cropImageFromGeometry } from '../../utils/helpers';

export interface PhotoCropPopupProps {
  className?: string;
  photo: IPhoto;
  aspectRatio?: number;
  onCrop(photo: IPhoto): void;
  onClose: VoidFunction;
}

export const PhotoCropPopup: FC<PhotoCropPopupProps> = ({ className, photo, aspectRatio, onCrop, onClose }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedArea, setCroppedArea] = useState<Area>();

  const imageRef = useRef<HTMLImageElement>();

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const onCropImage = () => {
    if (!imageRef.current || !croppedArea?.width) {
      return;
    }

    cropImageFromGeometry(imageRef.current, croppedArea, 1, 1).then((photo) => {
      onCrop(photo);
      onClose();
    });
  };

  return (
    <div className={classNames('flex-center fixed left-0 top-0 z-max h-screen w-screen bg-overlay1', className)}>
      <Cropper
        image={photo.dataURL}
        aspect={aspectRatio}
        crop={crop}
        zoom={zoom}
        rotation={rotation}
        onCropChange={setCrop}
        onZoomChange={setZoom}
        onRotationChange={setRotation}
        onCropComplete={(_, croppedAreaPixels) => setCroppedArea(croppedAreaPixels)}
        setImageRef={(ref) => (imageRef.current = ref.current)}
      />

      <div className="absolute bottom-6 flex items-center gap-6 lg:bottom-8 lg:gap-8">
        <div
          className={classNames(
            'flex-center h-12 w-12 cursor-pointer rounded-full border border-white bg-overlay1 text-white transition-all hover:scale-110',
            (!imageRef.current || !croppedArea?.width) && 'pointer-events-none opacity-50',
          )}
          onClick={onCropImage}
        >
          <CheckIcon size={28} />
        </div>
        <div
          className="flex-center h-12 w-12 cursor-pointer rounded-full border border-white bg-overlay1 text-white transition-all hover:scale-110"
          onClick={onClose}
        >
          <CloseIcon size={28} />
        </div>
      </div>
    </div>
  );
};
