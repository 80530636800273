import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormProvider, ITab, RHFGenderSelect, RHFPhoneInput, RHFTextField, Tabs } from '../../components';
import { getAccount } from '../../redux/selectors';
import { GENDER } from '../../resources/enums';
import { UserModel } from '../../resources/models';

export type PatientFormData = {
  hasInsurance: boolean;
  name: string;
  email: string;
  phoneNumber: string;
  address: string;
  birth: string;
  gender: GENDER;
};

export interface PatientFormProps {
  className?: string;
  patient?: UserModel;
  defaultHasInsurance?: boolean;
  onSubmit(patient: Partial<UserModel>): Promise<void>;
}

export const PatientForm: FC<PatientFormProps> = ({ className, patient, defaultHasInsurance, onSubmit }) => {
  const { t } = useTranslation();
  const account = useSelector(getAccount);

  const [submitting, setSubmitting] = useState(false);

  const maxYear = new Date().getFullYear();
  const minYear = maxYear - 120;
  const birthValidationError = t('validationErrors.invalidBirthYear', { min: minYear, max: maxYear });

  const tabs = useMemo<ITab[]>(
    () => [
      {
        value: false,
        label: <img className="h-8" src="/assets/images/logos/physioscan.png" alt="PhysioScan" />,
      },
      {
        value: true,
        label: <img className="h-8" src="/assets/images/logos/back-health.png" alt="BackHealth" />,
      },
    ],
    [],
  );

  const formSchema = Yup.object().shape({
    hasInsurance: Yup.boolean(),
    name: Yup.string().required(t('validationErrors.requiredField')),
    email: Yup.string().email(t('validationErrors.invalidEmail')).required(t('validationErrors.requiredField')),
    phoneNumber: Yup.string()
      .label(t('patientList.form.phone.label'))
      .required(t('validationErrors.requiredField'))
      .min(10),
    address: Yup.string().required(t('validationErrors.requiredField')),
    birth: Yup.number()
      .typeError(t('validationErrors.requiredField'))
      .integer(birthValidationError)
      .min(minYear, birthValidationError)
      .max(maxYear, birthValidationError)
      .required(t('validationErrors.requiredField')),
    gender: Yup.string().required(t('validationErrors.requiredField')),
  });

  const defaultValues = {
    hasInsurance: patient?.hasInsurance ?? defaultHasInsurance ?? false,
    name: patient?.name ?? '',
    email: patient?.email ?? '',
    phoneNumber: patient?.phoneNumber ?? '',
    address: patient?.address ?? '',
    birth: patient?.birth?.toString() ?? '',
    gender: patient?.gender ?? GENDER.MALE,
  };

  const methods = useForm<PatientFormData>({
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  const { watch, setValue, register, unregister, handleSubmit, reset } = methods;
  const formData = watch();

  useEffect(() => {
    reset({
      hasInsurance: patient?.hasInsurance ?? defaultHasInsurance ?? false,
      name: patient?.name ?? '',
      email: patient?.email ?? '',
      phoneNumber: patient?.phoneNumber ?? '',
      address: patient?.address ?? '',
      birth: patient?.birth?.toString() ?? '',
      gender: patient?.gender ?? GENDER.MALE,
    });
  }, [patient, reset]);

  useEffect(() => {
    if (formData.hasInsurance) {
      register('phoneNumber');
      register('address');
    } else {
      unregister('phoneNumber');
      unregister('address');
    }
  }, [formData.hasInsurance, register, unregister]);

  const onFormSubmit = (form: PatientFormData) => {
    setSubmitting(true);
    const formData = {
      ...form,
      birth: Number(form.birth),
      doctorName: account.name,
    };
    onSubmit(formData)
      .then(() => reset())
      .finally(() => setSubmitting(false));
  };

  const onSubmitValidationFailed = (validationErrors: Record<keyof PatientFormData, any>) => {
    if (
      !formData.hasInsurance &&
      Object.keys(validationErrors).every((field) => ['phoneNumber', 'address'].includes(field))
    ) {
      onFormSubmit(formData);
    }
  };

  return (
    <FormProvider
      className={classNames('flex flex-col gap-4', className)}
      methods={methods}
      onSubmit={handleSubmit(onFormSubmit, onSubmitValidationFailed)}
    >
      {!patient && (
        <Tabs
          className="mb-2"
          tabs={tabs}
          value={formData.hasInsurance}
          fullWidth
          onChange={(value) => setValue('hasInsurance', value as boolean)}
        />
      )}
      <RHFTextField name="name" label={t('common.name')} placeholder={t('patientList.form.name.placeholder')} />
      <RHFTextField name="email" label={t('common.email')} placeholder={t('patientList.form.email.placeholder')} />
      {formData.hasInsurance && (
        <>
          <RHFPhoneInput name="phoneNumber" label={t('patientList.form.phone.label')} />
          <RHFTextField
            name="address"
            label={t('patientList.form.address.label')}
            placeholder={t('patientList.form.address.placeholder')}
          />
        </>
      )}
      <RHFTextField
        name="birth"
        type="number"
        label={t('patientList.form.birthYear.label')}
        placeholder={t('patientList.form.birthYear.placeholder')}
      />
      <RHFGenderSelect name="gender" label={t('patientList.form.gender.label')} />
      <Button type="submit" className="mt-6" typoClass="typo-button-lg uppercase" fullWidth loading={submitting}>
        {patient
          ? t('common.save')
          : t('patientList.form.inviteButton', { app: formData.hasInsurance ? 'BackHealth' : 'PhysioScan' })}
      </Button>
    </FormProvider>
  );
};
