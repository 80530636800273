import { TimeslotModel } from './timeslot.model';

export class DoctorAvailabilityModel {
  dow: number;
  date?: string;
  timeslots: TimeslotModel[];
  disabled?: boolean;

  constructor(init?: Partial<DoctorAvailabilityModel>) {
    const data = {
      id: null,
      ...init,
    };

    this.dow = data.dow;
    this.date = data.date;
    this.timeslots = (data.timeslots || []).map((item) => new TimeslotModel(item));
    this.disabled = data.disabled;
  }
}
