import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MainLayout } from '../../../../../layouts';
import { useSelector } from '../../../../../redux/store';
import { STATUS } from '../../../../../resources/enums';
import { ExerciseModel } from '../../../../../resources/models';
import {
  ExerciseCard,
  ExerciseTutorialCard,
  FirstAidKits,
  PageBackButton,
  SendFeedbackPopup,
} from '../../../../../sections';
import { ScansService } from '../../../../../services';

export const CompletedExercises = () => {
  const { t } = useTranslation();

  const { profile, scan } = useSelector((root) => root.patient);
  const [exercises, setExercises] = useState<ExerciseModel[]>([]);
  const [selectedExercise, setSelectedExercise] = useState<ExerciseModel>();

  useEffect(() => {
    if (scan?.status !== STATUS.COMPLETED) {
      return;
    }

    ScansService.searchExercises(scan.id).then((data) => {
      setExercises(data.result.filter((exercise) => Boolean(exercise.scan.doneAt)));
    });
  }, [scan]);

  const sidebar = (
    <>
      <ExerciseTutorialCard />
      <FirstAidKits className="mt-10" />
    </>
  );

  return (
    <MainLayout sidebar={sidebar}>
      <PageBackButton />

      <h1 className="mt-8">{t('patientProfile.completedExercises')}</h1>

      {exercises.length ? (
        <div className="mt-8 grid gap-6 xl:grid-cols-2">
          {exercises.map((exercise) => (
            <ExerciseCard key={exercise.id} exercise={exercise} active onClick={() => setSelectedExercise(exercise)} />
          ))}
        </div>
      ) : (
        <div className="flex-center mt-8 flex-1 text-center text-gray-dark">
          {t('patientProfile.noCompletedExercises')}
        </div>
      )}

      {selectedExercise && (
        <SendFeedbackPopup
          patient={profile}
          exercise={selectedExercise}
          onClose={() => setSelectedExercise(undefined)}
        />
      )}
    </MainLayout>
  );
};
