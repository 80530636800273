import { CASE_STATUS } from '../enums';
import { UserModel } from './user.model';
import { DoctorModel } from './doctor.model';
import { FileModel } from './file.model';

export class CaseModel {
  id: string;
  channelId: string;
  diagnose?: string;
  trainingProgram?: string;
  status: CASE_STATUS;
  isPublic: boolean;
  notificationId?: string;
  user: UserModel;
  doctor: DoctorModel;
  files: FileModel[];
  createdAt: string;
  updatedAt: string;

  constructor(init?: any) {
    const data = {
      id: null,
      status: CASE_STATUS.DRAFT,
      files: [],
      ...init,
    };

    this.id = data.id;
    this.channelId = data.channelId ?? data.channel_id;
    this.diagnose = data.diagnose;
    this.trainingProgram = data.trainingProgram ?? data.tranningProgram;
    this.status = data.status;
    this.isPublic = data.isPublic ?? data.is_public;
    this.notificationId = data.notificationId;
    this.user = new UserModel(data.user);
    this.doctor = new DoctorModel(data.doctor);
    this.files = (data.files ?? []).map((item) => new FileModel(item));
    this.createdAt = data.createdAt ?? data.created_at;
    this.updatedAt = data.updatedAt ?? data.updated_at;
  }
}
