import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Badge, Button, ITab, LoadingSpinner, SearchBox, Tabs } from '../../../components';
import { AddIcon, MicrophoneIcon, SearchIcon } from '../../../icons';
import { MainLayout } from '../../../layouts';
import { useSelector } from '../../../redux/store';
import { UserModel } from '../../../resources/models';
import { PatientsService } from '../../../services';

export const PatientList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { profile } = useSelector((state) => state.patient);

  const [search, setSearch] = useState('');
  const [hasInsurance, setHasInsurance] = useState(false);
  const [patientCounts, setPatientCounts] = useState<number[]>([]);
  const [patients, setPatients] = useState<UserModel[]>([]);
  const [loading, setLoading] = useState(false);

  const tabs = useMemo<ITab[]>(
    () => [
      {
        value: false,
        label: <img className="h-8" src="/assets/images/logos/physioscan.png" alt="PhysioScan" />,
        endAdornment: patientCounts[0] === undefined ? undefined : <Badge>{patientCounts[0]}</Badge>,
      },
      {
        value: true,
        label: <img className="h-8" src="/assets/images/logos/back-health.png" alt="BackHealth" />,
        endAdornment: patientCounts[1] === undefined ? undefined : <Badge>{patientCounts[1]}</Badge>,
      },
    ],
    [patientCounts],
  );

  useEffect(() => {
    Promise.all([
      PatientsService.search({ hasInsurance: false }, false)
        .then((res) => res.total)
        .catch(() => 0),
      PatientsService.search({ hasInsurance: true }, false)
        .then((res) => res.total)
        .catch(() => 0),
    ]).then((counts) => {
      setPatientCounts(counts);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    PatientsService.search({ search, hasInsurance, page: 1, take: 10 }, false)
      .then((res) => {
        setPatients(res.result);
      })
      .catch(() => setPatients([]))
      .finally(() => setLoading(false));
  }, [search, hasInsurance]);

  const onSelectPatient = (patient: UserModel) => {
    navigate(`/patients/${patient.id}`);
  };

  return (
    <MainLayout>
      <h1 className="font-medium">{t('patientList.title')}</h1>

      <Tabs className="mt-6" tabs={tabs} value={hasInsurance} onChange={setHasInsurance} />

      <SearchBox
        className="mt-4 max-w-135"
        inputClass="border-stroke1"
        fullWidth
        size="sm"
        placeholder={t('common.search')}
        endAdornment={<MicrophoneIcon className="text-label" size={12} />}
        value={search}
        onChange={setSearch}
      />

      {loading ? (
        <div className="flex-center flex-grow py-6">
          <LoadingSpinner />
        </div>
      ) : patients.length ? (
        <div className="my-6 max-w-135">
          {patients.map((patient, i) => (
            <div
              key={patient.id}
              className={classNames(
                'typo-button-lg mb-1 flex h-11 cursor-pointer items-center truncate rounded-10p border px-4 !font-normal',
                i % 2 ? 'bg-body1' : 'bg-body2',
                patient.id === profile?.id ? 'border-primary' : 'border-stroke3',
              )}
              onClick={() => onSelectPatient(patient)}
            >
              {patient.name}
            </div>
          ))}
        </div>
      ) : !search ? (
        <div className="flex-center flex-grow flex-col py-6 text-center">
          <SearchIcon className="text-primary/40" size={120} />
          <h2 className="mt-4 font-bold text-typo3">{t('patientList.findPatient')}</h2>
          <p className="typo-md mt-4 text-gray-dark">{t('patientList.useSearchField')}</p>
        </div>
      ) : (
        <div className="flex-center flex-grow flex-col py-6 text-center">
          <h2 className="font-bold">{t('patientList.notFound')}</h2>
          <p className="typo-md mt-4 text-gray-dark">{t('patientList.tryChangeRequest')}</p>
        </div>
      )}

      <div className="mt-auto">
        <Button className="ml-auto" link="/new-patient">
          {t('patientList.addNewPatient')}
          <AddIcon className="ml-1" />
        </Button>
      </div>
    </MainLayout>
  );
};
