export const PrivacyPolicy = () => (
  <div className="typo-sm container mx-auto max-w-222 p-8 sm:p-16">
    <h1 className="font-bold">Datenschutzrichtlinie</h1>
    <br />
    <p>Letztes Update: 14.05.2024</p>
    <br />
    <br />
    <h2 className="font-semibold">Einführung</h2>
    <br />
    <p>
      Physioscan ("wir", "uns" oder "unser") ist bestrebt, Ihre ("Sie", "Ihre" oder "Ihr Selbst") Privatsphäre und
      Sicherheit zu schützen. Daher möchten wir, dass Sie vertraut sind mit der Art und Weise, wie wir Informationen
      sammeln, verwenden und offenlegen, um Ihnen die bestmögliche Erfahrung mit unserer Anwendung Physioscan (die
      "App") zu bieten.
    </p>
    <br />
    <p>
      Diese Datenschutzrichtlinie gilt für die App und damit verbundene Dienstleistungen, Verkäufe,
      Marketingaktivitäten, Veranstaltungen und andere Interaktionen, die Sie mit Physioscan haben können. Diese
      Datenschutzrichtlinie gilt nicht für Anwendungen oder Software von Drittanbietern, die mit der App integriert sind
      ("Drittanbieterdienste"), oder für andere Produkte, Dienstleistungen oder Unternehmen von Drittanbietern.
    </p>
    <br />
    <p>
      Wenn Sie nicht mit den Bedingungen einverstanden sind, greifen Sie nicht auf die App zu und nutzen Sie nicht
      irgendeinen anderen Aspekt der Dienstleistungen von Physioscan.
    </p>
    <br />
    <br />
    <h2 className="font-semibold">Daten, die wir sammeln</h2>
    <br />
    <p>Die App fragt nicht nach persönlichen Gesundheitsinformationen, und sie sammelt diese auch nicht.</p>
    <br />
    <p>Physioscan kann Informationen und Daten ("Informationen") auf verschiedene Weisen sammeln und erhalten:</p>
    <p>Allgemeine Informationen. Physioscan sammelt, generiert und/oder erhält Informationen:</p>
    <br />
    <ol className="list-lower-latin">
      <li>
        Kontoinformationen. Um ein Konto in unserer App zu erstellen oder zu aktualisieren, geben Sie Physioscan
        Telefonnummer, Spitznamen, Passwort und/oder ähnliche Kontodetails (z. B. Geschlecht und Geburtsjahr) sowie
        Abrechnungsdetails wie Kreditkarteninformationen, Bankinformationen und/oder eine Rechnungsadresse an.
      </li>
      <li>
        Nutzungsinformationen.
        <ul className="list-disc">
          <li>
            Protokolldaten. Wie bei den meisten Websites und technologischen Diensten, die über das Internet
            bereitgestellt werden, sammelt unsere App Informationen, wenn Sie auf die App zugreifen oder sie verwenden,
            und zeichnet sie in Protokolldateien auf. Diese Protokolldaten können die IP-Adresse, den Browsertyp und die
            Einstellungen, das Datum und die Uhrzeit der Verwendung der App, Informationen zur Browsereinstellung und zu
            Plug-ins, Spracheinstellungen und Cookie-Daten enthalten.
          </li>
          <li>
            Geräteinformationen. Physioscan sammelt Informationen über Geräte, die auf die App zugreifen, einschließlich
            Typ des Geräts, verwendetes Betriebssystem, Geräteeinstellungen, Anwendungs-IDs, eindeutige
            Geräteidentifikatoren und Absturzdaten. Ob wir einige oder alle dieser allgemeinen Informationen sammeln,
            hängt oft vom Typ des verwendeten Geräts und dessen Einstellungen ab.
          </li>
          <li>
            Standortinformationen. Wir können Informationen von Ihnen und anderen Dritten erhalten, die uns bei der
            Annäherung an Ihren Standort helfen könnten. Wir können beispielsweise eine von Ihnen eingereichte Adresse
            sammeln oder eine IP-Adresse von Ihrem Browser oder Gerät erhalten, die zur Bestimmung des ungefähren
            Standorts führt.
          </li>
        </ul>
      </li>
    </ol>
    <br />
    <p>Benutzerdaten. Benutzer übermitteln Nachrichten, Fotos, Dateien oder anderen Inhalt bei Verwendung der App.</p>
    <br />
    <p>
      Cookie-Informationen. Physioscan verwendet in unserer App Cookies und ähnliche Technologien, die uns bei der
      Sammlung von Informationen helfen. Sie können die Einstellungen ändern, um Cookies zu blockieren oder
      benachrichtigt zu werden, wenn Cookies an Ihr Gerät gesendet werden. Bitte beachten Sie, dass das Deaktivieren von
      Cookies Sie daran hindern kann, bestimmte Teile der App zu verwenden. Die auf unserer App verwendeten Cookies sind
      unerlässlich, um das Navigieren in unserer App zu ermöglichen und ihre Funktionen zu nutzen. Sie verbessern die
      Leistung der App und ermöglichen es uns, uns an die von Ihnen beim Browsen getroffenen Entscheidungen zu erinnern.
      Die Informationen, die diese Cookies sammeln, identifizieren Sie nicht persönlich, und sie können Ihre
      Browseraktivitäten auf nicht zu Physioscan gehörenden Apps oder Websites nicht verfolgen.
    </p>
    <br />
    <p>
      Drittanbieterdienste. Benutzer können wählen, ob sie Drittanbieterdienste zulassen oder beschränken möchten.
      Typischerweise handelt es sich bei Drittanbieterdiensten um Software, die mit unserer App integriert ist. Sobald
      ein Drittanbieterdienst aktiviert ist, darf Physioscan in Übereinstimmung mit unserer Vereinbarung mit dem
      Drittanbieter auf die vom Drittanbieter bereitgestellten Informationen zugreifen und sie abrufen. Wir erhalten
      oder speichern jedoch keine Passwörter für diese Drittanbieterdienste, wenn Sie sie mit der App verbinden.
    </p>
    <br />
    <p>
      Niemand ist gesetzlich oder vertraglich verpflichtet, Informationen bereitzustellen. Einige Teile der
      Informationen werden jedoch automatisch gesammelt, und wenn bestimmte Informationen, wie beispielsweise
      Einrichtungsdetails des Käuferkontos, nicht bereitgestellt werden, können wir möglicherweise keinen Zugang und
      keine Dienstleistungen über die App bereitstellen.
    </p>
    <br />
    <br />
    <h2 className="font-semibold">Wie wir die gesammelten Daten verwenden</h2>
    <br />
    <p>
      Physioscan verwendet Informationen zur Förderung unserer legitimen Interessen bei der Betreibung unserer App oder
      anderer Geschäftsaktivitäten. Wir verwenden Informationen:
    </p>
    <ul className="list-disc">
      <li>
        Um mit Ihnen zu kommunizieren, indem wir auf Ihre Anfragen, Kommentare und Fragen antworten. Wenn Sie uns
        kontaktieren, können wir Ihre Informationen verwenden, um zu antworten.
      </li>
      <li>
        Um unsere App und andere Dienstleistungen bereitzustellen, zu aktualisieren, zu verbessern, aufrechtzuerhalten
        und zu schützen.
      </li>
      <li>Wie von anwendbarem Recht, rechtlichen Verfahren oder Vorschriften gefordert.</li>
      <li>
        Um E-Mails und andere Mitteilungen zu senden. Wir können Ihnen Service-, technische und andere administrative
        E-Mails, Nachrichten und andere Arten von Mitteilungen senden. Wir können Sie auch kontaktieren, um Sie über
        Änderungen in unserer App oder anderen Dienstleistungen zu informieren sowie wichtige servicebezogene Hinweise
        wie Sicherheits- und Betrugshinweise. Diese Mitteilungen gelten als wesentlicher Bestandteil unseres Geschäfts,
        und Sie können sich nicht davon abmelden, wenn Sie unsere App weiterhin nutzen. Darüber hinaus können wir
        E-Mails zu neuen Produktmerkmalen, Werbeaktionen oder anderen Neuigkeiten über Physioscan senden. Dies sind
        Marketingmitteilungen, sodass Sie steuern können, ob Sie sie erhalten möchten.
      </li>
      <li>Um Sicherheitsprobleme und Missbrauch zu untersuchen und zu verhindern.</li>
      <li>
        Für Abrechnung, Kontoverwaltung und andere administrative Angelegenheiten. Physioscan muss Sie möglicherweise
        für Rechnungsstellung, Kontoverwaltung und ähnliche Gründe kontaktieren, und wir verwenden Kontodaten zur
        Verwaltung von Konten und zur Verfolgung von Abrechnungen und Zahlungen.
      </li>
    </ul>
    <br />
    <p>
      Wenn Informationen aggregiert oder anonymisiert werden, sodass sie nicht mehr vernünftigerweise mit einer
      identifizierten oder identifizierbaren natürlichen Person in Verbindung gebracht werden können, kann Physioscan
      sie für jeden Geschäftszweck verwenden. Soweit Informationen mit einer identifizierten oder identifizierbaren
      natürlichen Person in Verbindung stehen und gemäß den geltenden Datenschutzgesetzen als personenbezogene Daten
      geschützt sind, wird in dieser Datenschutzrichtlinie von "personenbezogenen Daten" gesprochen.
    </p>
    <br />
    <br />
    <h2 className="font-semibold">Wie wir Informationen teilen und offenlegen</h2>
    <br />
    <p>
      Physioscan respektiert Ihre Privatsphäre und verpflichtet sich, sie gemäß dieser Datenschutzrichtlinie zu
      schützen. Mit Ausnahme von Bestimmungen in dieser Datenschutzrichtlinie oder den Nutzungsbedingungen wird
      Physioscan Ihre persönlichen Informationen privat halten und sie ohne Ihre vorherige Zustimmung nicht an Dritte
      weitergeben, es sei denn, wir glauben in gutem Glauben, dass eine Offenlegung vernünftigerweise notwendig oder
      angemessen ist:
    </p>
    <ol className="list-lower-latin">
      <li>um einer gerichtlichen Anordnung oder einem anderen rechtlichen Verfahren nachzukommen;</li>
      <li>
        um die Rechte von Physioscan oder anderer Personen zu begründen, auszuüben, zu schützen oder durchzusetzen
        (einschließlich, aber nicht beschränkt auf Zwecke der Betrugsprävention);
      </li>
      <li>um die Nutzungsbedingungen von Physioscan durchzusetzen;</li>
      <li>
        oder um gegen unmittelbar bevorstehende Gefahren für das Eigentum oder die Sicherheit von Physioscan, unseren
        Kunden oder der Öffentlichkeit zu schützen.
      </li>
    </ol>
    <br />
    <p>
      Ungeachtet anderslautender Bestimmungen in dieser Datenschutzrichtlinie können wir Traffic-Daten und demografische
      Daten mit Werbetreibenden und anderen Dritten teilen und Ihre Kontakt- und Finanzdaten mit Dritten teilen, um
      finanzielle Transaktionen zwischen Ihnen und Physioscan auszuführen, die von Ihnen autorisiert wurden. Jegliche
      Traffic-Daten und demografischen Daten, die wir mit Werbetreibenden und anderen Dritten teilen, sind nicht mit
      persönlichen Informationen verknüpft und können keine einzelne Person identifizieren.
    </p>
    <br />
    <p>Darüber hinaus, ungeachtet anderslautender Bestimmungen in dieser Datenschutzrichtlinie:</p>
    <ol className="list-lower-latin">
      <li>
        können wir Ihre persönlichen Informationen (einschließlich, aber nicht beschränkt auf Ihre Telefonnummer und
        Finanzdaten) mit unseren Mitarbeitern oder Dritten teilen, die ein berechtigtes Interesse an Ihren persönlichen
        Informationen (einschließlich, aber nicht beschränkt auf Ihre Telefonnummer und Finanzdaten) im Zusammenhang mit
        der Bereitstellung der von Ihnen bei uns erworbenen, lizenzierten oder genutzten Produkte und Dienstleistungen
        haben, vorausgesetzt, dass solche Mitarbeiter oder Dritten zugestimmt haben, sich an die in dieser
        Datenschutzrichtlinie enthaltenen Beschränkungen zur Verwendung und Offenlegung solcher persönlicher
        Informationen zu halten; und
      </li>
      <li>
        im Falle, dass Physioscan sein gesamtes Geschäft oder Vermögen in irgendeiner Weise verkauft oder den Verkauf
        erwägt, behält sich Physioscan das Recht vor, Ihre persönlichen Informationen (einschließlich, aber nicht
        beschränkt auf Ihre Kontakt- und Finanzdaten) im Zusammenhang mit einem solchen Erwerb an den Käufer oder
        potenziellen Käufer offenzulegen und/oder zu übertragen, vorausgesetzt, dass ein solcher Käufer oder
        potenzieller Käufer zugestimmt hat, sich an die in dieser Datenschutzrichtlinie enthaltenen Beschränkungen zur
        Verwendung und Offenlegung solcher persönlicher Informationen zu halten. <br />
        Aufbewahrung und Rechte der betroffenen Person <br />
        Wir bewahren Ihre personenbezogenen Daten im Allgemeinen so lange auf, wie Ihr Konto geöffnet ist, oder so
        lange, wie wir Ihnen Dienstleistungen bereitstellen müssen. Dies umfasst Daten, die Sie oder andere uns zur
        Verfügung gestellt haben, sowie Daten, die durch Ihre Nutzung unserer App generiert oder abgeleitet wurden.
      </li>
    </ol>
    <br />
    <p>
      In einigen Fällen wählen wir ausgewählte Informationen (z. B. Erkenntnisse über die Nutzung von Dienstleistungen)
      in einer depersonalisierten oder aggregierten Form aufzubewahren.
    </p>
    <br />
    <p>
      Sie können eine spezifische Anfrage stellen und sich darauf verlassen, dass personenbezogene Daten nicht für etwas
      anderes als den legitimen Zweck verwendet werden, für den sie ursprünglich bereitgestellt wurden. Wir versichern,
      dass alle unsere Benutzer folgende Rechte haben:
    </p>
    <br />
    <p>
      Recht auf Berichtigung. Sie können von uns Berichtigungen Ihrer personenbezogenen Daten verlangen, falls Sie
      glauben, dass Ihre personenbezogenen Daten nicht auf dem neuesten Stand oder korrekt sind.
    </p>
    <br />
    <p>
      Recht auf Widerruf der Einwilligung. Sie können eine zuvor gegebene Einwilligung in die Verarbeitung Ihrer
      personenbezogenen Daten für einen bestimmten Zweck widerrufen.
    </p>
    <br />
    <p>
      Recht auf Vergessenwerden. Sie können die Löschung Ihrer Daten beantragen. Dies gilt für Situationen, in denen Sie
      die Nutzung unserer App beenden möchten.
    </p>
    <br />
    <p>
      Recht auf Datenübertragbarkeit. Sie können die Übertragung Ihrer personenbezogenen Daten beantragen. Die
      personenbezogenen Daten werden in einem maschinenlesbaren elektronischen Format bereitgestellt oder übertragen.
    </p>
    <br />
    <p>
      Recht im Zusammenhang mit automatisierter Entscheidungsfindung und Profilerstellung. Sie haben das Recht, nicht
      vollständig automatisierten Profilierungsalgorithmen unterworfen zu sein. Wenn solche Algorithmen verwendet
      werden, haben Sie das Recht, Details zu deren Betrieb zu erfahren.
    </p>
    <br />
    <p>
      Alle Anfragen im Zusammenhang mit den Rechten der betroffenen Person sollten in schriftlicher Form vom Benutzer
      oder seinem/ihrer gesetzlichen Vertreter per E-Mail an health@iplena.ai gesendet werden.
    </p>
    <br />
    <br />
    <h2 className="font-semibold">Datensicherheit</h2>
    <br />
    <p>
      Wir ergreifen alle notwendigen Maßnahmen, um Ihre Informationen vor unbefugtem oder versehentlichem Zugriff,
      Zerstörung, Änderung, Sperrung, Kopieren, Verbreitung sowie vor anderen rechtswidrigen Handlungen Dritter zu
      schützen. Wir sichern Ihre Informationen, die Sie auf Computerservern in einer kontrollierten, sicheren Umgebung
      bereitstellen, die vor unbefugtem Zugriff, Verwendung oder Offenlegung geschützt ist. Alle Informationen sind
      durch geeignete physische, technische und organisatorische Maßnahmen geschützt.
    </p>
    <br />
    <p>Wir haben die von der DSGVO empfohlenen Sicherheitsmaßnahmen angewendet.</p>
    <br />
    <p>
      Angesichts der Art der Kommunikations- und Informationsverarbeitungstechnologie kann Physioscan jedoch nicht
      garantieren, dass Informationen während der Übertragung über das Internet oder während der Speicherung auf unseren
      Systemen oder anderweitig in unserer Obhut absolut sicher vor Eingriffen durch Dritte sind. Die Übertragung von
      persönlichen Informationen zu und von unserer App erfolgt auf eigene Gefahr. Sie sollten nur auf die App in einer
      sicheren Umgebung zugreifen.
    </p>
    <br />
    <br />
    <h2 className="font-semibold">Datensammlung von Kindern</h2>
    <br />
    <p>
      Physioscan sammelt wissentlich keine Informationen von Personen, die das gesetzliche Mindestalter in ihrem Land,
      Staat, Provinz oder Wohnsitzland nicht erreicht haben. Wenn ein Elternteil oder Erziehungsberechtigter feststellt,
      dass sein Kind uns personenbezogene Informationen zur Verfügung gestellt hat, sollte er uns elektronisch über die
      Kontaktmöglichkeit per E-Mail unter health@iplena.ai kontaktieren. Wenn uns bekannt wird, dass ein Kind uns
      personenbezogene Informationen zur Verfügung gestellt hat, werden wir solche personenbezogenen Informationen auf
      eine Weise löschen oder vernichten, die angemessene und aktuelle technische und organisatorische
      Sicherheitsmethoden verwendet, um unbefugten Zugriff auf solche personenbezogenen Informationen zu verhindern.
    </p>
    <br />
    <br />
    <h2 className="font-semibold">Änderungen der Datenschutzrichtlinie</h2>
    <br />
    <p>
      Physioscan behält sich das Recht vor, jederzeit und ohne Ankündigung Teile dieser Datenschutzrichtlinie zu ändern,
      zu modifizieren, hinzuzufügen oder zu löschen, indem sie solche Änderungen einfach in dieser Datenschutzrichtlinie
      auf der App veröffentlicht. Eine solche Änderung tritt sofort nach der Veröffentlichung auf der App in Kraft. Es
      liegt in Ihrer Verantwortung, die App regelmäßig zu überprüfen, um festzustellen, ob Änderungen an dieser
      Datenschutzrichtlinie vorgenommen wurden, und solche Änderungen zu überprüfen. Wenn wir Änderungen an dieser
      Datenschutzrichtlinie vornehmen, geben wir oben in dieser Datenschutzrichtlinie das Datum an, an dem diese
      Datenschutzrichtlinie zuletzt überarbeitet wurde. Ihre fortgesetzte Nutzung der App oder Ihr Kauf, Ihre
      Lizenzierung oder Ihre fortgesetzte Nutzung von Produkten oder Dienstleistungen, die von Physioscan angeboten
      werden, nach solchen Änderungen stellt Ihre Zustimmung zu der neuen Datenschutzrichtlinie dar. Wenn Sie den
      Bedingungen und Bestimmungen dieser Datenschutzrichtlinie oder einer zukünftigen Datenschutzrichtlinie ohne
      Änderung nicht zustimmen oder diese nicht akzeptieren, verwenden Sie die App nicht oder greifen Sie nicht darauf
      zu (oder setzen Sie die Verwendung oder den Zugriff nicht fort) oder kaufen, lizenzieren oder verwenden Sie nicht
      (oder setzen Sie den Kauf, die Lizenzierung oder Verwendung nicht fort) Produkte oder Dienstleistungen, die von
      Physioscan angeboten werden.
    </p>
    <br />
    <br />
    <h2 className="font-semibold">Kontaktieren Sie uns</h2>
    <br />
    <p>
      Um eine Beschwerde bezüglich der App zu lösen oder weitere Informationen zur Nutzung/Sammlung Ihrer
      personenbezogenen Daten zu erhalten, kontaktieren Sie uns bitte unter:{' '}
      <a className="typo-link" href="mailto:health@iplena.ai">
        health@iplena.ai
      </a>
    </p>
  </div>
);
