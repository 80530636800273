import React, { FC } from 'react';
import { Icon } from './Icon';
import { IIconProps } from './types';

export const LogoutIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M23.7535 13.6005C24.0834 13.2844 24.082 12.7567 23.7504 12.4423L19.8919 8.78408C19.4911 8.40409 18.8581 8.42096 18.4781 8.82175C18.0982 9.22253 18.115 9.85548 18.5158 10.2355L20.4019 12.0237L7.84035 12.0237C7.28807 12.0237 6.84035 12.4714 6.84035 13.0237C6.84035 13.5759 7.28807 14.0237 7.84035 14.0237L20.4213 14.0237L18.5094 15.8558C18.1106 16.2379 18.0971 16.871 18.4793 17.2697C18.8614 17.6685 19.4944 17.6819 19.8931 17.2998L23.7535 13.6005ZM16.5 6.4558C16.5 5.40045 15.6451 4.5459 14.5897 4.5459L2.90688 4.5459C1.85248 4.5459 0.99653 5.40033 0.99653 6.4558L0.99653 19.6321C0.99653 20.6865 1.85236 21.542 2.90688 21.542L14.5897 21.542C15.6452 21.542 16.5 20.6864 16.5 19.6321L16.5 16.9109C16.5 16.3586 16.0478 15.9109 15.4956 15.9109V15.9109C14.9433 15.9109 14.4911 16.3586 14.4911 16.9109L14.4911 19.0289C14.4911 19.3076 14.2653 19.5335 13.9863 19.5335L3.51022 19.5335C3.23191 19.5335 3.0054 19.3074 3.0054 19.0289L3.0054 7.05897C3.0054 6.78052 3.23191 6.55439 3.51022 6.55439L13.9863 6.55439C14.2653 6.55439 14.4911 6.78029 14.4911 7.05897L14.4911 9.16797C14.4911 9.72026 14.9433 10.168 15.4956 10.168V10.168C16.0478 10.168 16.5 9.72026 16.5 9.16797L16.5 6.4558Z"
        />
      </svg>
    )}
  </Icon>
);
