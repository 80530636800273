import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, Checkbox } from '../../components';
import { CheckCircleIcon } from '../../icons';
import { IntakeFormContentPopup } from '../PolicyAgreementContentPopup';
import { POLICY_AGREEMENT } from '../../resources/enums';
import { UserModel } from '../../resources/models';
import { PatientsService, ToastService } from '../../services';

export interface IntakeFormsProps {
  className?: string;
  patient: UserModel;
  onComplete: VoidFunction;
}

const forms = Object.values(POLICY_AGREEMENT);

export const IntakeForms: FC<IntakeFormsProps> = ({ className, patient, onComplete }) => {
  const { t } = useTranslation();

  const [policyAndAgreements, setPolicyAndAgreements] = useState(patient.policyAndAgreements);
  const [openedForm, setOpenedForm] = useState<POLICY_AGREEMENT>();
  const [sendEmail, setSendEmail] = useState(false);

  const allConfirmed = useMemo(() => forms.every((form) => policyAndAgreements.includes(form)), [policyAndAgreements]);

  const onCloseForm = (accept: boolean) => {
    if (accept) {
      const policyAgreements = [...policyAndAgreements, openedForm];
      PatientsService.update(patient.id, { policyAndAgreements: policyAgreements })
        .then(() => {
          setPolicyAndAgreements(policyAgreements);
        })
        .catch((err) => ToastService.showHttpError(err, 'toast.updatePatientProfileFailed'));
    }
    setOpenedForm(undefined);
  };

  return (
    <div className={classNames('flex flex-col gap-6', className)}>
      <h2 className="text-center font-medium">{t('patientProfile.intakeForms.title')}</h2>

      <div className="flex flex-col gap-3">
        {forms.map((form) => (
          <div
            key={form}
            className="card3 flex cursor-pointer items-center justify-between gap-4 px-4 py-3"
            onClick={() => setOpenedForm(form)}
          >
            <span className="flex-1 font-medium">{t(`patientProfile.intakeForms.${form}.title`)}</span>
            <div className="flex-center min-w-18 shrink-0">
              {policyAndAgreements.includes(form) ? (
                <CheckCircleIcon className="text-primary" />
              ) : (
                <span className="typo-sm rounded bg-danger px-2 py-0.5 text-white">{t('common.required')}</span>
              )}
            </div>
          </div>
        ))}
      </div>

      {allConfirmed && (
        <div className="flex cursor-pointer items-center gap-3" onClick={() => setSendEmail(!sendEmail)}>
          <Checkbox className="bg-white" checked={sendEmail} />
          <span className="font-medium">{t('patientProfile.intakeForms.sendIntakeFormsToPatientEmail')}</span>
        </div>
      )}

      <Button fullWidth disabled={!allConfirmed} onClick={onComplete}>
        {t('common.confirm')}
      </Button>

      {openedForm && <IntakeFormContentPopup form={openedForm} onClose={onCloseForm} />}
    </div>
  );
};
