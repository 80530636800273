import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../components';
import { CheckCircleIcon } from '../../../../../icons';
import { MainLayout } from '../../../../../layouts';
import {
  PatientLevelCard,
  PhotoTipsCard,
  ScanCompletedPopup,
  ScanningLoader,
  ScanPhotosView,
} from '../../../../../sections';
import { fetchPatientProfile } from '../../../../../redux/apis';
import { useDispatch, useSelector } from '../../../../../redux/store';
import { setScan } from '../../../../../redux/reducers/patient.reducer';
import { SCAN_TYPE, STATUS } from '../../../../../resources/enums';
import { ScanModel } from '../../../../../resources/models';
import { ScansService, ToastService } from '../../../../../services';

const tips = [0, 1, 2];

export const Scanner = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { profile } = useSelector((root) => root.patient);

  const [loading, setLoading] = useState(true);
  const [lastScan, setLastScan] = useState<ScanModel>();
  const [newScan, setNewScan] = useState<ScanModel>();
  const [totalScans, setTotalScans] = useState(0);
  const [scanning, setScanning] = useState(false);
  const [showCompletedPopup, setCompletedPopup] = useState(false);

  const onCreateScan = useCallback(async () => {
    const scan = await ScansService.createPatientScan({ id: profile.id, type: SCAN_TYPE.POSTURE }).catch((err) => {
      ToastService.showHttpError(err, 'toast.creatingScanFailed');
      return undefined;
    });
    setLastScan(scan);
  }, [profile.id]);

  useEffect(() => {
    setLoading(true);
    ScansService.searchPatientScans(profile.id, {
      type: SCAN_TYPE.POSTURE,
      desc: 'createdAt',
    })
      .then(async (res) => {
        setTotalScans(res.result.filter((item) => item.status === STATUS.COMPLETED).length);

        const scan = res.result[0];
        if (scan?.status === STATUS.PENDING) {
          setLastScan(scan);
        } else {
          await onCreateScan();
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [profile.id, onCreateScan]);

  const onCreatePhoto = async () => {
    if (!lastScan) {
      return;
    }
    navigate(`/patients/${profile.id}/scanner/${lastScan.id}/photos/${lastScan.getEmptyPhotos()[0]}`);
  };

  const onStartScan = async () => {
    setScanning(true);
    ScansService.analyze(lastScan.id, false)
      .then(async (newScan) => {
        setNewScan(newScan);
        setTotalScans((prev) => prev + 1);
        setCompletedPopup(true);
      })
      .catch((err) => {
        ToastService.showHttpError(err, 'toast.aiAnalyzeFailed');
      })
      .finally(() => {
        setScanning(false);
      });
  };

  const onViewResult = () => {
    dispatch(setScan(newScan));
    dispatch(fetchPatientProfile({ patientId: profile.id, force: true, showSpinner: false, showError: false }));
    setCompletedPopup(false);
    navigate(`/patients/${profile.id}`, { replace: true });
  };

  const sidebar = (
    <>
      <PhotoTipsCard />
      <div className="mt-6">
        <h6>{t('common.photos')}</h6>
        <ScanPhotosView className="mt-4" scan={lastScan} link showText />
      </div>
      <div className="card1 mt-6 flex flex-col justify-center gap-4 px-6 py-10">
        {tips.map((tip) => (
          <div key={tip} className="typo-sm flex items-center text-gray-dark">
            <CheckCircleIcon className="mr-2 text-primary" size={24} />
            {t(`patient.takingPhotoSteps.${tip}`)}
          </div>
        ))}
      </div>
      {lastScan && (
        <Button className="mt-6" theme="primary" fullWidth disabled={!lastScan.isPhotosValid()} onClick={onStartScan}>
          {t('patient.startAiScan')}
        </Button>
      )}
    </>
  );

  return (
    <MainLayout sidebar={sidebar}>
      <PatientLevelCard showPostureScore linkToHome />

      <div className="mt-10 grid gap-6 xl:mt-20 xl:grid-cols-2">
        <div className="flex-center max-xl:max-h-100">
          <img className="max-h-full max-w-full" src="/assets/images/patient/scanner-poster.png" alt="" />
        </div>
        <div className="flex flex-col justify-center text-gray-dark max-xl:text-center xl:pr-15">
          <h2>{t('patient.aiScanTitle')}.</h2>
          <p className="mt-6">{t('patient.aiScanDescription')}</p>
          <p className="mt-6 text-center text-xs leading-loose">{t('patient.aiScanHelperText')}</p>
          {lastScan && !lastScan.isPhotosValid() && (
            <Button className="mt-6" theme="primary" fullWidth disabled={loading} onClick={onCreatePhoto}>
              {!lastScan?.front?.src ? t('patient.createPhoto') : t('common.continue')}
            </Button>
          )}
        </div>
      </div>

      {scanning && <ScanningLoader className="fixed left-0 top-0 z-max h-screen w-screen p-13" />}

      {showCompletedPopup && <ScanCompletedPopup scanNumber={totalScans} onClose={onViewResult} />}
    </MainLayout>
  );
};
