import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { WebcamIcon } from '../../icons';
import { AppointmentCard } from '../AppointmentCard';
import { SCHEDULE_STATUS } from '../../resources/enums';
import { ScheduleModel } from '../../resources/models';
import { ConsultationsService } from '../../services';

export interface ScheduledAppointmentsViewProps {
  className?: string;
  onBack: VoidFunction;
}

export const ScheduledAppointmentsView: FC<ScheduledAppointmentsViewProps> = ({ className, onBack }) => {
  const { t } = useTranslation();

  const [consultations, setConsultations] = useState<ScheduleModel[]>([]);

  const loadSchedules = useCallback((showSpinner = false) => {
    const consultationPriorities: Record<SCHEDULE_STATUS, number> = {
      [SCHEDULE_STATUS.ACTIVE]: 1,
      [SCHEDULE_STATUS.RE_OPEN]: 1,
      [SCHEDULE_STATUS.IN_PROGRESS]: 2,
      [SCHEDULE_STATUS.COMPLETED]: 3,
      [SCHEDULE_STATUS.USER_CANCELLED]: 4,
    };
    ConsultationsService.getScheduleList('upcomming', showSpinner)
      .then((data) => {
        setConsultations(
          data.sort(
            (a, b) =>
              consultationPriorities[a.status] - consultationPriorities[b.status] ||
              a.startTime.localeCompare(b.startTime),
          ),
        );
      })
      .catch(() => {
        setConsultations([]);
      });
  }, []);

  useEffect(() => {
    loadSchedules(true);
  }, [loadSchedules]);

  return (
    <div className={classNames('flex h-full flex-col gap-6', className)}>
      <div className="flex items-center justify-between">
        <div className="typo-link shink-0 whitespace-nowrap p-2 font-semibold text-primary" onClick={onBack}>
          <i className="fa fa-angle-left" />
          <span className="ml-1.5">{t('common.back')}</span>
        </div>
        <h2 className="text-center leading-none">{t('appointments.scheduledAppointments.title')}</h2>
        <WebcamIcon className="shink-0 text-label" size={24} />
      </div>

      {consultations.length ? (
        <div className="overflw-auto flex flex-1 flex-col gap-2">
          {consultations.map((consultation) => (
            <AppointmentCard key={consultation.id} consultation={consultation} onRefresh={loadSchedules} />
          ))}
        </div>
      ) : (
        <div className="flex-center typo-sm flex-1 text-center font-bold text-typo1">
          {t('appointments.scheduledAppointments.noAppointments')}
        </div>
      )}
    </div>
  );
};
