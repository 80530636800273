import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

export interface ILogoProps {
  className?: string;
  link?: string;
  linkClass?: string;
}

export const Logo: FC<ILogoProps> = ({ className, link, linkClass }) => {
  const logo = (
    <img className={classNames('max-w-full', className)} src="/assets/images/physioscan-logo.svg" alt="Physioscan" />
  );

  if (link) {
    return (
      <Link className={linkClass} to={link}>
        {logo}
      </Link>
    );
  }

  return logo;
};
