import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviationCard } from '../DeviationCard';
import { PHOTO_TYPE } from '../../resources/enums';
import { ScanModel } from '../../resources/models';

export interface CompareScanResultsProps {
  className?: string;
  prevScan: ScanModel;
  currentScan: ScanModel;
}

export const CompareScanResults: FC<CompareScanResultsProps> = ({ className, prevScan, currentScan }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <h3 className="mb-6 py-1.5 text-center">{t('patientProfile.compareYourResults')}</h3>

      <div className="typo-sm mb-6 font-medium text-typo3">1. {t('patientProfile.frontPosition')}</div>
      <div className="grid grid-cols-2 gap-3.5">
        <DeviationCard scan={prevScan} position={PHOTO_TYPE.FRONT} title={t('common.before')} />
        <DeviationCard scan={currentScan} position={PHOTO_TYPE.FRONT} title={t('common.after')} />
      </div>

      <div className="typo-sm my-6 font-medium text-typo3">2. {t('patientProfile.sidePosition')}</div>
      <div className="grid grid-cols-2 gap-3.5">
        <DeviationCard scan={prevScan} position={PHOTO_TYPE.SIDE} title={t('common.before')} />
        <DeviationCard scan={currentScan} position={PHOTO_TYPE.SIDE} title={t('common.after')} />
      </div>

      <div className="typo-sm my-6 font-medium text-typo3">3. {t('patientProfile.sitPosition')}</div>
      <div className="grid grid-cols-2 gap-3.5">
        <DeviationCard scan={prevScan} position={PHOTO_TYPE.SIT} title={t('common.before')} />
        <DeviationCard scan={currentScan} position={PHOTO_TYPE.SIT} title={t('common.after')} />
      </div>
    </div>
  );
};
