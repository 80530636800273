import { FC, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Badge } from '../../components';
import { ChevronRightIcon } from '../../icons';
import { ScheduleModel } from '../../resources/models';
import { SCHEDULE_STATUS } from '../../resources/enums';
import { CancelConsultationConfirmPopup } from '../CancelConsultationConfirmPopup';
import { ConsultationsService, ToastService } from '../../services';
import { formatDatetime } from '../../utils/helpers';

export interface AppointmentCardProps {
  className?: string;
  consultation: ScheduleModel;
  readonly?: boolean;
  onRefresh?: VoidFunction;
}

export const AppointmentCard: FC<AppointmentCardProps> = ({ className, consultation, readonly, onRefresh }) => {
  const { t } = useTranslation();

  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const isCancelable = useMemo(() => {
    if (consultation.status !== SCHEDULE_STATUS.ACTIVE) {
      return false;
    }
    return new Date(consultation.startTime).getTime() > Date.now() + 24 * 60 * 60 * 1000;
  }, [consultation]);

  const onCloseConfirmPopup = (result: boolean) => {
    if (result) {
      ConsultationsService.cancelConsultation(consultation.id)
        .then(() => {
          ToastService.success('toast.consultationCanceled');
          onRefresh && onRefresh();
        })
        .catch((err) => ToastService.showHttpError(err, 'toast.cancelingConsultationFailed'))
        .finally(() => setShowConfirmPopup(false));
    } else {
      setShowConfirmPopup(false);
    }
  };

  return (
    <>
      <Link
        className={classNames(
          'relative flex cursor-pointer items-center justify-between gap-6 rounded-10p bg-white px-2 py-1',
          readonly && 'pointer-events-none',
          className,
        )}
        to={`/appointments/${consultation.id}`}
      >
        <div className="typo-sm grid flex-1 grid-cols-2 gap-x-2 gap-y-1">
          <div className="text-stroke1">{t('appointments.date')}</div>
          <div>{formatDatetime(consultation.startTime, 'L')}</div>

          <div className="text-stroke1">{t('appointments.time')}</div>
          <div>{formatDatetime(consultation.startTime, 'HH:mm')}</div>

          <div className="text-stroke1">{t('common.patient')}</div>
          <div>{consultation.case?.user?.name}</div>

          <div className="text-stroke1">{t('common.expert')}</div>
          <div>{consultation.case?.doctor?.name}</div>

          <div className="text-stroke1">{t('appointments.consultationId')}</div>
          <div>{consultation.id.substr(-12)}</div>
        </div>

        {!readonly && <ChevronRightIcon className="text-stroke1" />}

        {isCancelable && (
          <span
            className="typo-link absolute right-2 top-1 font-medium uppercase !text-danger"
            onClick={(e) => {
              setShowConfirmPopup(true);
              e.preventDefault();
            }}
          >
            {t('common.cancel')}
          </span>
        )}

        {consultation.status === SCHEDULE_STATUS.USER_CANCELLED && (
          <Badge className="absolute right-1 top-1">{t('appointments.status.canceled')}</Badge>
        )}
        {consultation.status === SCHEDULE_STATUS.COMPLETED && (
          <Badge className="absolute right-1 top-1">{t('appointments.status.completed')}</Badge>
        )}
        {consultation.status === SCHEDULE_STATUS.IN_PROGRESS && (
          <Badge className="absolute right-1 top-1">{t('appointments.status.inProgress')}</Badge>
        )}
      </Link>

      {showConfirmPopup && <CancelConsultationConfirmPopup onClose={onCloseConfirmPopup} />}
    </>
  );
};
