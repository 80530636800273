import React, { FC, useEffect, useState } from 'react';
import { formatDuration } from '../../utils/helpers';

export interface ITimerProps {
  className?: string;
  time: number;
  format?: string;
  playing?: boolean;
  showZeroValue?: boolean;
  onTick?: (time: number) => void;
  onFinished?: () => void;
}

export const Timer: FC<ITimerProps> = ({
  className,
  time,
  format = 'mm:ss',
  playing,
  showZeroValue,
  onTick,
  onFinished,
}) => {
  const [remainTime, setRemainTime] = useState(time);

  useEffect(() => {
    setRemainTime(time);
  }, [time]);

  useEffect(() => {
    if (!time || !playing) {
      return;
    }

    const onTimeout = () => {
      if (onTick) {
        onTick(remainTime - 1);
      }
      if (onFinished && remainTime <= 1) {
        onFinished();
      }
      setRemainTime(remainTime - 1);
    };
    const timer = setTimeout(onTimeout, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [onFinished, onTick, playing, remainTime, time]);

  return <div className={className}>{Boolean(remainTime || playing) && formatDuration(remainTime, format)}</div>;
};
