import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ChevronRightIcon, ScanBodyIcon } from '../../icons';
import { ScanPhotosView } from '../ScanPhotosView';
import { ScanModel } from '../../resources/models';
import { formatDatetime } from '../../utils/helpers';

export interface ScanResultSummaryProps {
  className?: string;
  scan: ScanModel;
  onViewDetail: VoidFunction;
}

export const ScanResultSummary: FC<ScanResultSummaryProps> = ({ className, scan, onViewDetail }) => {
  const { t } = useTranslation();

  const postureScore = Math.round(scan.healthScore * 100);

  return (
    <div className={classNames('card1 !bg-transparent p-4', className)}>
      <div className="card2 flex items-center gap-2 !border-0 px-4 py-2">
        <ScanBodyIcon className="text-primary" />
        <div>
          <h3 className="font-semibold text-typo3">{t('patientProfile.postureScannerResult')}</h3>
          <div className="typo-sm text-input-placeholder">{formatDatetime(scan.scannedAt, 'MMMM DD, yyyy')}</div>
        </div>
        <ChevronRightIcon className="ml-auto cursor-pointer text-typo1" onClick={onViewDetail} />
      </div>

      <div className="typo-h3 mt-3 flex items-center justify-between gap-3 font-semibold">
        <span className="text-typo3">{t('patient.yourPostureScore')}</span>
        <span className="px-1.5 text-primary">{postureScore}%</span>
      </div>

      <ScanPhotosView className="mt-3" scan={scan} />
    </div>
  );
};
