import React, { FC, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { TextField, TextFieldProps } from '../TextField';
import { SearchIcon } from '../../../icons';

export type SearchBoxProps = Omit<TextFieldProps, 'value' | 'onChange'> & {
  value?: string;
  onChange(value: string): void;
};

export const SearchBox: FC<SearchBoxProps> = ({
  startAdornment = <SearchIcon className="text-label" size={16} />,
  value,
  onChange,
  ...textFieldProps
}) => {
  const [search, setSearch] = useState('');

  const debouncedChangeHandler = useMemo(() => debounce(onChange, 500), [onChange]);

  useEffect(() => {
    setSearch(value || '');
  }, [value]);

  const onInputChange = (value: string) => {
    setSearch(value);
    debouncedChangeHandler(value);
  };

  return (
    <TextField
      startAdornment={startAdornment}
      value={search}
      onChange={(e) => onInputChange(e.target.value)}
      {...textFieldProps}
    />
  );
};
