import React, { ButtonHTMLAttributes, FC, MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { LoadingSpinner } from '../LoadingSpinner';

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: 'primary' | 'secondary' | 'danger' | 'none';
  variant?: 'contained' | 'outline' | 'text';
  typoClass?: string;
  fullWidth?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  defaultShadow?: boolean;
  link?: string;
  loading?: boolean;
}

export const Button: FC<IButtonProps> = ({
  className,
  type = 'button',
  theme = 'primary',
  variant = 'contained',
  typoClass = 'typo-button',
  fullWidth,
  size = 'md',
  defaultShadow,
  link,
  disabled,
  loading,
  onClick,
  children,
  ...buttonProps
}) => {
  const navigate = useNavigate();
  const isDisabled = disabled || loading;

  const buttonClass = classNames(
    'max-w-full flex-center transition-all',
    variant === 'contained' && [
      size === 'md' && 'min-h-12.5 rounded-lg px-4 py-3',
      !fullWidth && 'w-67.5',
      !isDisabled && defaultShadow && 'shadow-button',
      isDisabled ? 'bg-gray text-gray-dark' : 'hover:shadow-button',
      theme === 'primary' && [
        'shadow-button1-hover/40',
        !isDisabled && 'bg-button1 text-button1-text hover:bg-button1-hover hover:shadow-button1-hover/64',
      ],
      theme === 'secondary' && [
        'shadow-button2-hover/40',
        !isDisabled && 'bg-button2 text-button2-text hover:bg-button2-hover hover:shadow-button2-hover/64',
      ],
      theme === 'danger' && [
        'shadow-button1-hover/40',
        !isDisabled && 'bg-danger text-white hover:bg-danger-dark hover:shadow-danger-dark/64',
      ],
    ],
    variant === 'outline' && [
      'border',
      size === 'md' && 'min-h-12.5 rounded-lg px-4 py-3',
      !fullWidth && 'w-67.5',
      isDisabled ? 'bg-white/16 text-white/48' : 'hover:shadow-button',
      theme === 'primary' && [
        'border-button-outline1-border shadow-button-outline1-border/40',
        !isDisabled &&
          'bg-button-outline1 text-button-outline1-text hover:bg-button-outline1-border hover:text-button-outline1-hover hover:shadow-button-outline1-border/64',
      ],
      theme === 'danger' && [
        'border-danger shadow-danger/40',
        !isDisabled && 'bg-transparent text-danger hover:bg-danger hover:text-white hover:shadow-danger/64',
      ],
    ],
    variant === 'text' && [
      size === 'md' && 'px-4 py-3',
      isDisabled && 'text-button-text-disabled',
      theme === 'primary' && [!isDisabled && 'text-button-text hover:text-button-text-hover'],
      theme === 'danger' && [!isDisabled && 'text-danger hover:brightness-75'],
    ],
    isDisabled ? 'cursor-default' : 'cursor-pointer',
    fullWidth && 'w-full',
    typoClass,
    className,
  );

  const onButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (isDisabled) {
      return;
    }
    if (link) {
      navigate(link);
    }
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <button type={type} className={buttonClass} {...buttonProps} disabled={isDisabled} onClick={onButtonClick}>
      {loading ? <LoadingSpinner size="xs" /> : children}
    </button>
  );
};
