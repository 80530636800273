import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components';
import { ChevronRightIcon } from '../../../icons';
import { BlankLayout } from '../../../layouts';

export const CheckLicense = () => {
  const { t } = useTranslation();

  return (
    <BlankLayout className="flex-center flex-col p-4 sm:px-32 sm:py-28">
      <div className="max-w-110 text-center text-typo2">
        <div className="text-34p font-bold">{t('checkLicense.text')}</div>
        <div className="mt-6">
          <a className="text-xl font-semibold underline" href="mailto:support@iplena.us">
            support@iplena.us
          </a>
        </div>
      </div>

      <div className="bottom-28 right-32 mt-16 sm:absolute">
        <Link to="/login">
          <Button typoClass="typo-button-lg">
            {t('checkLicense.buttonText')}
            <ChevronRightIcon className="ml-1" />
          </Button>
        </Link>
      </div>
    </BlankLayout>
  );
};
