import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormProvider, RHFTextField } from '../../../components';
import { VisibilityIcon, VisibilityOffIcon } from '../../../icons';
import { BlankLayout } from '../../../layouts';
import { setToken } from '../../../redux/reducers/auth.reducer';
import { ForgotPasswordPopup } from '../../../sections';
import { AuthService, ToastService } from '../../../services';

type FormData = {
  email: string;
  password: string;
};

export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPasswordPopup, setShowForgotPasswordPopup] = useState(false);

  const formSchema = Yup.object().shape({
    email: Yup.string().email(t('validationErrors.invalidEmail')).required(t('validationErrors.requiredField')),
    password: Yup.string().required(t('validationErrors.requiredField')),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm<FormData>({
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const onSubmit = (form: FormData) => {
    setLoading(true);
    AuthService.login(form.email, form.password, false)
      .then((res) => {
        dispatch(setToken(res.data.token));
        navigate('/');
      })
      .catch((err) => ToastService.showHttpError(err, 'toast.loginFailed'))
      .finally(() => setLoading(false));
  };

  return (
    <BlankLayout className="flex-center flex-col p-4 sm:p-8 lg:p-18">
      <div className="w-full max-w-86">
        <h1 className="mb-10 text-center text-34p font-bold text-typo1">{t('common.welcome')}</h1>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <RHFTextField name="email" label={t('common.email')} placeholder={t('auth.emailPlaceholder')} />
          <RHFTextField
            className="mt-4"
            name="password"
            type={showPassword ? 'text' : 'password'}
            label={t('common.password')}
            placeholder={t('common.password')}
            endAdornment={
              <div className="cursor-pointer text-black/54" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </div>
            }
          />
          <div className="mt-2 text-right">
            <span
              className="cursor-pointer text-sm text-typo1 underline hover:text-primary"
              onClick={() => setShowForgotPasswordPopup(true)}
            >
              {t('auth.forgotPassword')}
            </span>
          </div>
          <Button
            type="submit"
            className="mt-10"
            typoClass="typo-button-lg"
            fullWidth
            disabled={!isValid}
            loading={loading}
          >
            {t('common.login')}
          </Button>
        </FormProvider>
        <p className="typo-helper mt-10 px-6 text-center">
          <Trans
            i18nKey="auth.agreeText"
            components={[
              <Link className="underline" to="/terms-of-use" />,
              <Link className="underline" to="/privacy-policy" />,
            ]}
          />
        </p>
      </div>

      <div className="mt-15 w-full max-w-120 text-center">
        <div className="typo-md font-semibold">{t('disclaimer.title')}</div>
        <p className="typo-helper mt-1">{t('auth.disclaimerText')}</p>
      </div>

      {showForgotPasswordPopup && <ForgotPasswordPopup onClose={() => setShowForgotPasswordPopup(false)} />}
    </BlankLayout>
  );
};
