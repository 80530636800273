import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDropzone from 'react-dropzone';
import { Button, Image, PhotoScanner } from '../../components';
import { AddPhotoIcon, TakePhotoIcon } from '../../icons';
import { fetchAccountProfile } from '../../redux/apis';
import { getAccount } from '../../redux/selectors';
import { useDispatch, useSelector } from '../../redux/store';
import { IPhoto } from '../../resources/interfaces';
import { AuthService, FilesService, ToastService } from '../../services';

type AvatarUploadProps = {};

export const AvatarUpload: FC<AvatarUploadProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector(getAccount);

  const [showTakePhoto, setShowTakePhoto] = useState(false);

  const onSelectFile = (files: File[]) => {
    if (files.length) {
      FilesService.uploadPhoto(files[0])
        .then((response) => {
          AuthService.updateDoctorProfile({ imageId: response.id }, false)
            .then(() => {
              ToastService.success('toast.avatarUpdated');
              dispatch(fetchAccountProfile({ force: true, showSpinner: false, showError: false }));
              setShowTakePhoto(false);
            })
            .catch((err) => ToastService.showHttpError(err, 'toast.changeAvatarFailed'));
        })
        .catch((err) => {
          ToastService.showHttpError(err, 'toast.uploadingPhotoFailed');
        });
    }
  };

  const onUpload = (photo: IPhoto) => {
    FilesService.uploadPhoto(photo.blob)
      .then((response) => {
        AuthService.updateDoctorProfile({ imageId: response.id }, false)
          .then(() => {
            ToastService.success('toast.avatarUpdated');
            dispatch(fetchAccountProfile({ force: true, showSpinner: false, showError: false }));
            setShowTakePhoto(false);
          })
          .catch((err) => ToastService.showHttpError(err, 'toast.changeAvatarFailed'));
      })
      .catch((err) => {
        ToastService.showHttpError(err, 'toast.uploadingPhotoFailed');
      });
  };

  return (
    <div className="card1 flex w-full flex-col items-center gap-4 px-4 py-6 text-primary">
      <div className="flex items-end gap-4">
        <ReactDropzone
          accept={{
            'image/jpeg': ['.jpeg', '.png'],
          }}
          onDrop={onSelectFile}
        >
          {(state) => (
            <div {...state.getRootProps()}>
              <input {...state.getInputProps()} />
              <AddPhotoIcon className="cursor-pointer" />
            </div>
          )}
        </ReactDropzone>

        <Image
          wrapperClass="h-40 w-40 rounded-full object-cover object-center"
          src={user?.image?.src}
          placeholder="/assets/images/doctor-avatar.png"
        />

        <TakePhotoIcon className="cursor-pointer" onClick={() => setShowTakePhoto(true)} />

        {showTakePhoto && (
          <div className="fixed bottom-0 left-0 right-0 top-0 z-max bg-popup">
            <PhotoScanner
              className="h-full w-full"
              header={
                <>
                  <Button
                    className="self-start text-inherit text-primary"
                    theme="none"
                    variant="text"
                    onClick={() => setShowTakePhoto(false)}
                  >
                    <i className="fa fa-arrow-left" />
                    <span className="ml-3">{t('common.back')}</span>
                  </Button>
                </>
              }
              aspectRatio={1}
              showWireframe={false}
              onTakePhoto={onUpload}
            />
          </div>
        )}
      </div>
    </div>
  );
};
