import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import Calendar from 'react-calendar';
import { Button, Checkbox, TimeSelect } from '../../components';
import { getAccount } from '../../redux/selectors';
import { useSelector } from '../../redux/store';
import { SetDateAvailabilitiesDto } from '../../resources/dtos';
import { DoctorAvailabilityModel, DoctorUnavailabilityModel, TimeslotModel } from '../../resources/models';
import { ConsultationsService, ToastService } from '../../services';
import { checkTimeValidity, formatHoursToTimeString, getHoursFromTimeString } from '../../utils/helpers';

export interface DateOverridesFormProps {
  className?: string;
}

const getLocalDateString = (date: Date) => {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
};

export const DateOverridesForm: FC<DateOverridesFormProps> = ({ className }) => {
  const { t, i18n } = useTranslation();

  const user = useSelector(getAccount);

  const [date, setDate] = useState(new Date());
  const [availabilities, setAvailabilities] = useState<DoctorAvailabilityModel[]>([]);
  const [unavailabilities, setUnavailabilities] = useState<DoctorUnavailabilityModel[]>([]);
  const [currentAvailability, setCurrentAvailability] = useState<DoctorAvailabilityModel>();
  const [currentUnavailability, setCurrentUnavailability] = useState<DoctorUnavailabilityModel>();
  const [markAsUnavailable, setMarkAsUnavailable] = useState(false);
  const [timeslots, setTimeslots] = useState<TimeslotModel[]>([]);
  const [submitting, setSubmitting] = useState(false);

  const unavailableDates = useMemo(() => unavailabilities.map((item) => item.date), [unavailabilities]);

  const isValid = useMemo(() => {
    if (!markAsUnavailable && !timeslots.length) {
      return false;
    }

    return timeslots.every((slot, i) => checkTimeValidity(slot, timeslots.slice(0, i)));
  }, [markAsUnavailable, timeslots]);

  useEffect(() => {
    ConsultationsService.getMyAvailabilities(false)
      .then((data) => {
        setAvailabilities(data);
      })
      .catch(() => {
        setAvailabilities([]);
      });

    ConsultationsService.getMyUnavailabilities(user.id)
      .then((data) => {
        setUnavailabilities(data);
      })
      .catch(() => {
        setUnavailabilities([]);
      });
  }, [user.id]);

  useEffect(() => {
    const selectedDate = getLocalDateString(date);
    const currentAvailability = availabilities.find((item) => item.date === selectedDate);
    const currentUnavailability = unavailabilities.find((item) => item.date === selectedDate);
    setCurrentAvailability(currentAvailability);
    setCurrentUnavailability(currentUnavailability);
    setMarkAsUnavailable(Boolean(currentUnavailability));
    setTimeslots(currentAvailability?.timeslots ?? []);
  }, [date, availabilities, unavailabilities]);

  const onAddSlot = (index: number) => {
    const newSlot = new TimeslotModel({
      id: uuidv4(),
    });
    const newSlots = [...timeslots];
    if (index === -1) {
      newSlots.push(newSlot);
    } else {
      newSlots.splice(index + 1, 0, newSlot);
    }
    setTimeslots(newSlots);
  };

  const onChangeSlot = (id: string, field: keyof TimeslotModel, value: string) => {
    const newSlots = timeslots.map((slot) => {
      if (slot.id !== id) {
        return slot;
      }
      const newSlot = { ...slot, [field]: value };
      if (field === 'from' && value.localeCompare(newSlot.to) >= 0) {
        newSlot.to = formatHoursToTimeString(getHoursFromTimeString(value) + 0.5);
      } else if (field === 'to' && value.localeCompare(newSlot.from) <= 0) {
        newSlot.from = formatHoursToTimeString(getHoursFromTimeString(value) - 0.5);
      }
      return newSlot;
    });
    setTimeslots(newSlots);
  };

  const onRemoveSlot = (id: string) => {
    const newSlots = timeslots.filter((slot) => slot.id !== id);
    setTimeslots(newSlots);
  };

  const onSubmitAvailability = () => {
    if (!isValid) {
      return;
    }

    setSubmitting(true);
    const availableTimes: SetDateAvailabilitiesDto = {
      date: `${getLocalDateString(date)}T00:00:00.000Z`,
      timeslots: timeslots.map((slot) => ({
        id: slot.id,
        from_time: slot.from,
        to_time: slot.to,
      })),
    };
    ConsultationsService.setMyAvailabilityForSpecificDay(availableTimes, false)
      .then(() => {
        const selectedDate = getLocalDateString(date);
        const newAvailability = new DoctorAvailabilityModel({
          date: selectedDate,
          timeslots,
        });
        if (currentAvailability) {
          setAvailabilities((prev) => prev.map((item) => (item.date === selectedDate ? newAvailability : item)));
        } else {
          setAvailabilities((prev) => [...prev, newAvailability]);
        }
        setUnavailabilities((prev) => prev.filter((item) => item.date !== selectedDate));
        ToastService.success('toast.availabilityUpdated');
      })
      .catch((err) => ToastService.showHttpError(err, 'toast.updatingAvailabilityFailed'))
      .finally(() => setSubmitting(false));

    if (currentUnavailability) {
      ConsultationsService.deleteMyUnavailability(currentUnavailability.id, false);
    }
  };

  const onSubmitUnavailability = () => {
    if (currentUnavailability) {
      return;
    }

    setSubmitting(true);
    ConsultationsService.createMyUnavailability(
      {
        date: `${getLocalDateString(date)}T00:00:00.000Z`,
        from_time: '00:00:00',
        to_time: '23:30:00',
      },
      false,
    )
      .then((data) => {
        const selectedDate = getLocalDateString(date);
        setUnavailabilities((prev) => [...prev, data]);
        setAvailabilities((prev) => prev.filter((item) => item.date !== selectedDate));
        ToastService.success('toast.availabilityUpdated');
      })
      .catch((err) => ToastService.showHttpError(err, 'toast.updatingAvailabilityFailed'))
      .finally(() => setSubmitting(false));

    if (currentAvailability?.timeslots?.length) {
      currentAvailability.timeslots.forEach((slot) => {
        ConsultationsService.deleteMyAvailability(slot.id, false);
      });
    }
  };

  const onRemoveUnavailability = () => {
    if (!currentUnavailability) {
      return;
    }

    setSubmitting(true);
    ConsultationsService.deleteMyUnavailability(currentUnavailability.id, false)
      .then(() => {
        setUnavailabilities((prev) => prev.filter((item) => item.id !== currentUnavailability.id));
        setCurrentAvailability(undefined);
        ToastService.success('toast.availabilityUpdated');
      })
      .catch((err) => ToastService.showHttpError(err, 'toast.updatingAvailabilityFailed'))
      .finally(() => setSubmitting(false));
  };

  return (
    <div className={classNames('flex h-full flex-col gap-4', className)}>
      <Calendar
        className="overflow-hidden rounded-lg border-none"
        locale={i18n.language}
        value={date}
        minDate={new Date()}
        tileClassName={({ date }) => (unavailableDates.includes(getLocalDateString(date)) ? 'unavailable' : null)}
        onChange={(value) => setDate(value as Date)}
      />

      <div className="flex items-center justify-between gap-3">
        <div className="typo-sm py-1">{t('appointments.availability.editor.yourAvailabilityForSpecificDays')}</div>
        {!markAsUnavailable && !timeslots.length && (
          <i className="fa fa-plus cursor-pointer text-xl" onClick={() => onAddSlot(-1)} />
        )}
      </div>

      <Checkbox
        label={<label className="typo-sm">{t('appointments.availability.editor.markMeUnavailableThisDay')}</label>}
        checked={markAsUnavailable}
        onChange={setMarkAsUnavailable}
      />

      {!markAsUnavailable && timeslots.length > 0 && (
        <div className="flex items-start gap-3 bg-white p-2">
          <div>{moment(date).format('ll')}</div>
          <div className="ml-auto flex flex-col gap-2 bg-white">
            {timeslots.map((slot, i) => (
              <div key={slot.id} className="flex items-center">
                <div
                  className="flex-center mr-3 h-6 w-6 shrink-0 cursor-pointer rounded-full bg-body2"
                  onClick={() => onRemoveSlot(slot.id)}
                >
                  <i className="fa fa-times text-xl" />
                </div>
                <TimeSelect
                  className="w-21"
                  value={slot.from}
                  min={6}
                  max={22}
                  skipLastTime
                  error={!checkTimeValidity(slot, timeslots.slice(0, i))}
                  onChange={(value) => onChangeSlot(slot.id, 'from', value)}
                />
                <span className="mx-1">-</span>
                <TimeSelect
                  className="w-21"
                  value={slot.to}
                  min={slot.from ?? 6}
                  max={22}
                  skipFirstTime
                  error={!checkTimeValidity(slot, timeslots.slice(0, i))}
                  onChange={(value) => onChangeSlot(slot.id, 'to', value)}
                />
                <i className="fa fa-plus ml-2 cursor-pointer text-xl" onClick={() => onAddSlot(i)} />
              </div>
            ))}
          </div>
        </div>
      )}

      {markAsUnavailable ? (
        <Button className="mt-auto" fullWidth loading={submitting} onClick={onSubmitUnavailability}>
          {t('appointments.availability.editor.setAsUnavailable')}
        </Button>
      ) : currentUnavailability && !timeslots.length ? (
        <Button className="mt-auto" fullWidth loading={submitting} onClick={onRemoveUnavailability}>
          {t('appointments.availability.editor.setAsAvailable')}
        </Button>
      ) : (
        <Button className="mt-auto" fullWidth loading={submitting} disabled={!isValid} onClick={onSubmitAvailability}>
          {t('appointments.availability.editor.saveAvailability')}
        </Button>
      )}
    </div>
  );
};
