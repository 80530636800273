import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Exercises } from './Home';
import { ExerciseTraining } from './Training';
import { CompletedExercises } from './CompletedExercises';
import { FirstAid } from './FirstAid';
import { LearnExercise } from './LearnExercise';
import { Tutorial } from './Tutorial';
import { useSelector } from '../../../../redux/store';

export const ExercisesRouting = () => {
  const { profile, scan } = useSelector((root) => root.patient);

  if (!scan?.isSubscribed) {
    return <Navigate to={`/patients/${profile.id}`} replace />;
  }

  return (
    <Routes>
      <Route index element={<Exercises />} />
      <Route path="/training" element={<ExerciseTraining />} />
      <Route path="/completed" element={<CompletedExercises />} />
      <Route path="/first-aids/:type" element={<FirstAid />} />
      <Route path="/:id/learn" element={<LearnExercise />} />
      <Route path="/tutorial" element={<Tutorial />} />
    </Routes>
  );
};
