import React, { FC } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Popup } from '../../components';

export interface IDeletePatientConfirmPopupProps {
  className?: string;
  onDelete(): void;
  onCancel(): void;
}

export const DeletePatientConfirmPopup: FC<IDeletePatientConfirmPopupProps> = ({ className, onDelete, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Popup contentClass={classNames('text-typo1', className)} width="25rem" disableBackdrop>
      <h1>{t('patientProfile.deletePatient.title')}</h1>
      <p className="mt-1 font-bold">
        <Trans i18nKey="patientProfile.deletePatient.message" />
      </p>
      <p className="mt-1">
        <Trans i18nKey="patientProfile.deletePatient.helperText" />
      </p>
      <Button className="mt-4" theme="danger" fullWidth onClick={onDelete}>
        {t('patientProfile.deletePatient.yesButtonText')}
      </Button>
      <Button className="mt-4" variant="outline" fullWidth onClick={onCancel}>
        {t('common.cancel')}
      </Button>
    </Popup>
  );
};
