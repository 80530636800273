import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from '../../components';
import { PatientForm } from '../PatientForm';
import { DeletePatientConfirmPopup } from '../DeletePatientConfirmPopup';
import { setPatientProfile } from '../../redux/reducers/patient.reducer';
import { useDispatch, useSelector } from '../../redux/store';
import { UpdatePatientDto } from '../../resources/dtos';
import { UserModel } from '../../resources/models';
import { PatientsService, ToastService } from '../../services';

export interface PatientProfileEditProps {
  className?: string;
  onUpdate?: (profile: UserModel) => void;
}

export const PatientProfileEdit: FC<PatientProfileEditProps> = ({ className, onUpdate }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.patient);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  if (!profile) {
    return null;
  }

  const onSubmitForm = async (formData: Partial<UserModel>) => {
    return PatientsService.update(profile.id, formData as UpdatePatientDto, false)
      .then((res) => {
        dispatch(setPatientProfile(res));
        ToastService.success('toast.patientProfileUpdated');
        onUpdate && onUpdate(res);
      })
      .catch((err) => ToastService.showHttpError(err, 'toast.updatePatientProfileFailed'));
  };

  const onDelete = () => {
    PatientsService.delete(profile.id)
      .then(() => {
        setShowDeleteConfirm(false);
        navigate('/patients');
        dispatch(setPatientProfile(undefined));
        ToastService.success('toast.patientDeleted');
      })
      .catch((err) => ToastService.showHttpError(err, 'toast.deletePatientFailed'));
  };

  return (
    <div className={classNames('flex h-full flex-col gap-11', className)}>
      <PatientForm patient={profile} onSubmit={onSubmitForm} />
      <Button className="mt-auto" theme="danger" variant="text" onClick={() => setShowDeleteConfirm(true)}>
        {t('patientProfile.deletePatient.title')}
      </Button>

      {showDeleteConfirm && (
        <DeletePatientConfirmPopup onDelete={onDelete} onCancel={() => setShowDeleteConfirm(false)} />
      )}
    </div>
  );
};
