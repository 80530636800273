import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { ClickAwayListener } from '../../ClickAwayListener';
import { formatHoursToTimeString, getHoursFromTimeString } from '../../../utils/helpers';

export interface TimeSelectProps {
  className?: string;
  value?: string;
  min?: number | string;
  max?: number | string;
  skipFirstTime?: boolean;
  skipLastTime?: boolean;
  dropdownClass?: string;
  error?: boolean;
  onChange?: (option: string) => void;
}

export function TimeSelect({
  className,
  value,
  min,
  max,
  skipFirstTime,
  skipLastTime,
  dropdownClass,
  error,
  onChange,
}: TimeSelectProps) {
  const [opened, setOpened] = useState(false);

  const options = useMemo(() => {
    let from = Math.max(typeof min === 'string' ? getHoursFromTimeString(min) : min ?? 0, 0);
    let to = Math.min(typeof max === 'string' ? getHoursFromTimeString(max) : max ?? 24, 24);
    from = Math.ceil(from * 2) / 2;
    to = Math.floor(to * 2) / 2;
    if (skipFirstTime) {
      from += 0.5;
    }
    if (skipLastTime) {
      to -= 0.5;
    }
    const timeOptions: string[] = [];
    for (let i = from; i <= to; i += 0.5) {
      timeOptions.push(formatHoursToTimeString(i));
    }
    return timeOptions;
  }, [min, max, skipFirstTime, skipLastTime]);

  const selectBoxClass = classNames(
    'flex-center h-12.5 rounded border cursor-pointer transition-all',
    error ? 'border-danger' : opened ? 'border-primary' : 'border-stroke1',
    className,
  );

  const dropdownMenuClass = classNames(
    'absolute left-0 z-1 w-full border-stroke1 bg-menu-bg text-menu-text shadow overflow-auto max-h-60',
    dropdownClass,
  );

  const getMenuItemClass = (option: string) => {
    return classNames(
      'transition-all cursor-pointer p-3 text-center',
      'hover:bg-menu-bg-hover',
      option === value && 'text-menu-active !bg-menu-bg-hover',
    );
  };

  const onCloseDropdown = useCallback(() => {
    setOpened(false);
  }, []);

  const onSelectOption = (option: string) => {
    onChange && onChange(option);
    onCloseDropdown();
  };

  return (
    <ClickAwayListener className="relative" onClick={onCloseDropdown}>
      <div className={selectBoxClass} onClick={() => setOpened(!opened)}>
        {value?.slice(0, 5)}
      </div>
      {opened && (
        <div className={dropdownMenuClass}>
          {options.map((option, i) => (
            <div key={i} className={getMenuItemClass(option)} onClick={() => onSelectOption(option)}>
              {option.slice(0, 5)}
            </div>
          ))}
        </div>
      )}
    </ClickAwayListener>
  );
}
