import React, { FC } from 'react';
import { Icon } from './Icon';
import { IIconProps } from './types';

export const TakePhotoIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <mask id="mask0_6316_35055" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_6316_35055)">
          <path
            d="M3 21C2.45 21 1.97917 20.8042 1.5875 20.4125C1.19583 20.0208 1 19.55 1 19V7C1 6.45 1.19583 5.97917 1.5875 5.5875C1.97917 5.19583 2.45 5 3 5H6.15L8 3H14V5H8.875L7.05 7H3V19H19V10H21V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H3ZM19 7V5H17V3H19V1H21V3H23V5H21V7H19ZM11 17.5C12.25 17.5 13.3125 17.0625 14.1875 16.1875C15.0625 15.3125 15.5 14.25 15.5 13C15.5 11.75 15.0625 10.6875 14.1875 9.8125C13.3125 8.9375 12.25 8.5 11 8.5C9.75 8.5 8.6875 8.9375 7.8125 9.8125C6.9375 10.6875 6.5 11.75 6.5 13C6.5 14.25 6.9375 15.3125 7.8125 16.1875C8.6875 17.0625 9.75 17.5 11 17.5ZM11 15.5C10.3 15.5 9.70833 15.2583 9.225 14.775C8.74167 14.2917 8.5 13.7 8.5 13C8.5 12.3 8.74167 11.7083 9.225 11.225C9.70833 10.7417 10.3 10.5 11 10.5C11.7 10.5 12.2917 10.7417 12.775 11.225C13.2583 11.7083 13.5 12.3 13.5 13C13.5 13.7 13.2583 14.2917 12.775 14.775C12.2917 15.2583 11.7 15.5 11 15.5Z"
            fill={color}
          />
        </g>
      </svg>
    )}
  </Icon>
);
