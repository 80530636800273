import { SCHEDULE_STATUS } from '../enums';
import { CaseModel } from './case.model';

export class ScheduleModel {
  id: string;
  title: string;
  description: string;
  status: SCHEDULE_STATUS;
  date: string;
  startTime: string;
  endTime: string;
  screenRecordingFlag: boolean;
  case?: CaseModel;
  createdAt: string;
  updatedAt: string;

  constructor(init?: any) {
    const data = {
      id: '',
      status: SCHEDULE_STATUS.ACTIVE,
      ...init,
    };

    this.id = data.id;
    this.title = data.title;
    this.description = data.description;
    this.status = data.status;
    this.date = data.date;
    this.startTime = data.startTime ?? data.start_time;
    this.endTime = data.endTime ?? data.end_time;
    this.screenRecordingFlag = data.screenRecordingFlag ?? data.screen_recording_flag;
    this.createdAt = data.createdAt ?? data.created_at;
    this.updatedAt = data.updatedAt ?? data.updated_at;

    if (data.case) {
      this.case = new CaseModel(data.case);
    }
  }
}
