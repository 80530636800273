import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CONFIG } from '../../../../../constants';
import { MainLayout } from '../../../../../layouts';
import { PageBackButton, FirstAidKits } from '../../../../../sections';

export const Tutorial = () => {
  const { t } = useTranslation();

  const videoUrl = useMemo(() => {
    switch (CONFIG.THEME) {
      case 'imed':
        return 'https://player.vimeo.com/video/884287251?autoplay=1&controls=1&badge=0&byline=0&portrait=0&title=0&loop=1';

      default:
        return 'https://player.vimeo.com/video/855274526?autoplay=1&controls=1&badge=0&byline=0&portrait=0&title=0&loop=1';
    }
  }, []);

  const sidebar = (
    <>
      <FirstAidKits />
    </>
  );

  return (
    <MainLayout sidebar={sidebar}>
      <PageBackButton />

      <h1 className="mt-8">{t('patient.howWillMyProgramLookLike')}</h1>

      <div className="flex-center mt-6 max-w-160">
        <iframe
          title={t('patient.howWillMyProgramLookLike')}
          src={videoUrl}
          width="640"
          height="360"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      </div>
    </MainLayout>
  );
};
