import React, { FC, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Logo } from '../../../components';
import { AddCircleIcon, IIconProps, LogoutIcon, SettingsIcon, UsersIcon, WebcamIcon } from '../../../icons';
import { setToken } from '../../../redux/reducers/auth.reducer';
import { getAccount } from '../../../redux/selectors';
import { useDispatch, useSelector } from '../../../redux/store';
import { DOCTOR_ROLE, MENU } from '../../../resources/enums';

export interface SidebarProps {
  className?: string;
}

const menus: {
  text: string;
  key: MENU;
  icon: FC<IIconProps>;
  path: string;
  exact?: boolean;
  disabled?: boolean;
  notification?: boolean;
  patientPage?: boolean;
  role?: DOCTOR_ROLE;
}[] = [
  { text: 'common.patientList', key: MENU.PATIENT_LIST, icon: UsersIcon, path: '/patients' },
  { text: 'common.newPatient', key: MENU.NEW_PATIENT, icon: AddCircleIcon, path: '/new-patient' },
  {
    text: 'appointments.title',
    key: MENU.APPOINTMENTS,
    icon: WebcamIcon,
    path: '/appointments',
    role: DOCTOR_ROLE.DOCTOR,
  },
  {
    text: 'settings.title',
    key: MENU.SETTINGS,
    icon: SettingsIcon,
    path: '/settings',
  },
];

export const Sidebar: FC<SidebarProps> = ({ className }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(getAccount);
  const { profile } = useSelector((state) => state.patient);
  const [opened, setOpened] = useState(false);

  const activeMenu = useMemo(() => {
    return menus.find((item) =>
      item.exact ? location.pathname === item.path : location.pathname.startsWith(item.path),
    );
  }, [location]);

  const onLogout = () => {
    dispatch(setToken(null));
  };

  return (
    <>
      <div
        className={classNames(
          'fixed top-0 z-[5] flex h-screen w-59 flex-col justify-between sm:left-0',
          'overflow-auto bg-body2 p-6 transition-all',
          'max-sm:left-unset max-sm:shadow-right-sidebar',
          opened ? 'max-sm:right-0' : 'max-sm:-right-64',
          className,
        )}
      >
        <Logo link="/" />

        <Button className="mt-10" variant="outline">
          {user.name}
        </Button>

        <div className="my-auto">
          {menus.map((item) => {
            if (item.role && user.doctorRole !== item.role) {
              return null;
            }

            const disabled = item.disabled || (item.patientPage && !profile);
            return (
              <Link
                key={item.key}
                className={classNames(
                  'typo-button relative my-4 flex items-center py-1 transition-all',
                  item === activeMenu ? 'text-primary' : 'text-gray-dark',
                  disabled ? 'cursor-default opacity-60' : 'cursor-pointer hover:text-primary',
                )}
                to={disabled ? '' : item.path}
              >
                <item.icon size={24} />
                <span className="ml-4">{t(item.text)}</span>
                {item.notification && <span className="absolute left-5 top-3 h-2 w-2 rounded-full bg-danger" />}
              </Link>
            );
          })}
        </div>

        <div
          className="typo-button mt-8 flex cursor-pointer items-center py-1 text-danger transition-all"
          onClick={onLogout}
        >
          <LogoutIcon size={24} />
          <span className="ml-4">{t('common.logout')}</span>
        </div>
      </div>

      <div
        className={classNames(
          'pointer-events-none fixed left-0 top-0 z-[4] h-screen w-screen bg-overlay1 opacity-0 transition-all',
          opened && 'max-sm:pointer-events-auto max-sm:opacity-100',
        )}
        onClick={() => setOpened(false)}
      />

      <div
        className="fixed right-2 top-2 z-[4] cursor-pointer p-1 text-xl transition-all sm:hidden"
        onClick={() => setOpened(!opened)}
      >
        <i className="fa fa-bars" />
      </div>
    </>
  );
};
