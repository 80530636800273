import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { CanceledError } from 'axios';
import { Button, IButtonProps } from '../../components';
import { useSelector } from '../../redux/store';
import { ScanModel } from '../../resources/models';
import { ScansService, ToastService } from '../../services';

export interface IExportScanReportPdfButtonProps extends IButtonProps {
  scan: ScanModel;
}

export const ExportScanReportPdfButton: FC<IExportScanReportPdfButtonProps> = ({ scan, ...buttonProps }) => {
  const { t } = useTranslation();

  const { profile } = useSelector((state) => state.patient);

  const onExport = () => {
    ScansService.exportReportAsPdf(scan.id)
      .then((res) => {
        const fileName = `scan-report_${profile.name}_${moment().format('YYYY-MM-DD HH:mm')}.pdf`;
        saveAs(res, fileName);
        ToastService.success('toast.reportPdfExported');
      })
      .catch((err) => {
        if (!(err instanceof CanceledError)) {
          ToastService.showHttpError(err, 'toast.reportPdfExportingFailed');
        }
      });
  };

  return (
    <Button theme="primary" {...buttonProps} onClick={onExport}>
      {t('patientProfile.saveAsPdf')}
    </Button>
  );
};
