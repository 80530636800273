import React, { FC, ReactNode } from 'react';
import { cn } from '../../utils/helpers';

export interface IBlankLayoutProps {
  className?: string;
  children: ReactNode;
}

export const BlankLayout: FC<IBlankLayoutProps> = ({ className, children }) => {
  return (
    <div className={cn('relative min-h-screen bg-body2 text-typo1', className)}>
      <div className="pointer-events-none fixed left-0 top-0 h-full w-full overflow-hidden">
        <img className="absolute right-0 top-0 w-130 max-w-[36%]" src="/assets/images/layout/bg-pattern1.png" alt="" />
        <img
          className="absolute bottom-0 left-0 w-157 max-w-[44%]"
          src="/assets/images/layout/bg-pattern2.png"
          alt=""
        />
      </div>
      {children}
    </div>
  );
};
