import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Popup } from '../../components';

export interface ICancelConsultationConfirmPopupProps {
  className?: string;
  onClose(result: boolean): void;
}

export const CancelConsultationConfirmPopup: FC<ICancelConsultationConfirmPopupProps> = ({ className, onClose }) => {
  const { t } = useTranslation();

  return (
    <Popup contentClass={classNames('text-typo1', className)} width="25rem" disableBackdrop>
      <p className="typo-h2 text-center font-semibold">{t('appointments.cancelConfirmPopup')}</p>
      <Button className="mt-8" fullWidth onClick={() => onClose(true)}>
        {t('common.yes')}
      </Button>
      <Button className="mt-4" variant="outline" fullWidth onClick={() => onClose(false)}>
        {t('common.no')}
      </Button>
    </Popup>
  );
};
