import React, { FC, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ChevronRightIcon, ClickIcon, IIconProps, InventoryIcon, WebcamIcon } from '../../../../icons';
import { MainLayout } from '../../../../layouts';
import { AvailabilityEditor, PreviousAppointmentsView, ScheduledAppointmentsView } from '../../../../sections';

interface AppointmentMenu {
  id: string;
  text: string;
  icon: FC<IIconProps>;
  sidebar: ReactNode;
}

export const Appointments = () => {
  const { t } = useTranslation();
  const [selectedMenu, setSelectedMenu] = useState<AppointmentMenu>();

  const menus: AppointmentMenu[] = [
    {
      id: 'scheduledAppointments',
      text: 'appointments.scheduledAppointments.title',
      icon: WebcamIcon,
      sidebar: <ScheduledAppointmentsView onBack={() => setSelectedMenu(undefined)} />,
    },
    {
      id: 'previousAppointments',
      text: 'appointments.previousAppointments.title',
      icon: InventoryIcon,
      sidebar: <PreviousAppointmentsView onBack={() => setSelectedMenu(undefined)} />,
    },
    {
      id: 'availability',
      text: 'appointments.availability.title',
      icon: ClickIcon,
      sidebar: <AvailabilityEditor onBack={() => setSelectedMenu(undefined)} />,
    },
  ];

  const sidebar = useMemo(() => {
    if (!selectedMenu) {
      return null;
    }

    return <div className="relative h-full">{selectedMenu.sidebar}</div>;
  }, [selectedMenu]);

  return (
    <MainLayout sidebar={sidebar} sidebarOverlap>
      <h1 className="font-medium">{t('appointments.title')}</h1>
      <div className="mt-8 flex max-w-140 flex-col gap-4">
        {menus.map((item, i) => (
          <div
            key={i}
            className={classNames(
              'flex w-full cursor-pointer items-center gap-4 rounded-10p border px-4 py-6 transition-all',
              item.id === selectedMenu?.id ? 'border-primary text-primary' : 'border-gray hover:border-primary',
            )}
            onClick={() => setSelectedMenu(item)}
          >
            <item.icon size={24} />
            <div>{t(item.text)}</div>
            <ChevronRightIcon className="ml-auto" />
          </div>
        ))}
      </div>
    </MainLayout>
  );
};
