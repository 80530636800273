import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PatientList } from './PatientList';
import { NewPatient } from './NewPatient';
import { PatientProfileRouting } from './PatientProfile/routing';
import { PublicRouting } from '../Public/routing';
import { AppointmentsRouting } from './Appointments/routing';
import { SettingsRouting } from './Settings/routing';

export const DoctorRouting = () => {
  return (
    <Routes>
      {PublicRouting}
      <Route path="/patients" element={<PatientList />} />
      <Route path="/new-patient" element={<NewPatient />} />
      <Route path="/patients/:patientId/*" element={<PatientProfileRouting />} />
      <Route path="/appointments/*" element={<AppointmentsRouting />} />
      <Route path="/settings/*" element={<SettingsRouting />} />
      <Route path="*" element={<Navigate to="/patients" />} />
    </Routes>
  );
};
