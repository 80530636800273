import { FileModel } from './file.model';
import { GENDER, LANGUAGE } from '../enums';
import { store } from '../../redux/store';
import { getAccount } from '../../redux/selectors';

export class PhotoTipModel {
  id: string;
  order: number;
  language: LANGUAGE;
  coverMale: Partial<FileModel>;
  coverFemale: Partial<FileModel>;
  createdAt: string;
  updatedAt: string;

  constructor(init?: Partial<PhotoTipModel>) {
    const data = {
      id: null,
      ...init,
    };

    this.id = data.id;
    this.order = data.order;
    this.language = data.language;
    if (data.coverMale) {
      this.coverMale = new FileModel(data.coverMale);
    }
    if (data.coverFemale) {
      this.coverFemale = new FileModel(data.coverFemale);
    }
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

  getCover(): Partial<FileModel> {
    const account = getAccount(store.getState());
    // TODO: check for doctor
    // if (account?.gender === GENDER.FEMALE) {
    //   return this.coverFemale;
    // }
    return this.coverMale;
  }
}
