import { HttpService } from './http.service';
import { FinishTrainingRequestDto, GetFirstAidKitRequestDto } from '../resources/dtos';
import { ExerciseModel } from '../resources/models';
import { formatPaginatedResult } from '../utils/helpers';

export class ExercisesService {
  static getFirstAidKit(query: GetFirstAidKitRequestDto, hasSpinner = true): Promise<ExerciseModel> {
    return HttpService.get('/exercises/first-aid-kit', query, hasSpinner).then(
      (result) => new ExerciseModel(result.data),
    );
  }

  static finishTraining(patientId: string, exerciseId: string, data: FinishTrainingRequestDto, hasSpinner = true) {
    return HttpService.patch(`/scans/patient/${patientId}/exercises/${exerciseId}`, data, hasSpinner).then((result) =>
      formatPaginatedResult<ExerciseModel>(ExerciseModel, result.data),
    );
  }

  static findByKey(key: string, hasSpinner = true): Promise<ExerciseModel> {
    return HttpService.get('/exercises/detail', { key }, hasSpinner).then((result) => new ExerciseModel(result.data));
  }

  static sendFeedback(patientId: string, exerciseId: string, content: string, hasSpinner = true): Promise<void> {
    return HttpService.post(`/scan-feedback/${patientId}/exercises/${exerciseId}`, { content }, hasSpinner);
  }
}
