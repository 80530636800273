export class DoctorUnavailabilityModel {
  id: string;
  date: string;

  constructor(init?: any) {
    const data = {
      id: null,
      ...init,
    };

    this.id = data.id;
    this.date = data.date ?? data.unavailable_date;
  }
}
