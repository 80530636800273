import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from '../../components';
import { BarChartOutlineIcon, ScanFocusIcon } from '../../icons';
import { useSelector } from '../../redux/store';
import { STATUS } from '../../resources/enums';

export interface IExerciseDailyProgramProps {
  className?: string;
  onViewDetails(): void;
}

export const ExerciseDailyProgram: FC<IExerciseDailyProgramProps> = ({ className, onViewDetails }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { profile, scan, trainStatus } = useSelector((state) => state.patient);
  const todayLevel = trainStatus?.todayLevel || 1;
  const noScan = scan?.status !== STATUS.COMPLETED;

  const onScan = () => {
    navigate(`/patients/${profile.id}/scanner`, { replace: true });
  };

  return (
    <div className={className}>
      <h2>{t('patient.yourDailyProgram')}</h2>
      <div
        className={classNames('card2 mt-4 flex min-h-28 items-center', noScan ? 'shadow' : 'cursor-pointer')}
        onClick={noScan ? undefined : onViewDetails}
      >
        {noScan ? (
          <div className="flex-1 items-center justify-between px-6 py-3 md:flex">
            <div className="shrink-0">
              <div className="flex items-center text-typo1">
                <ScanFocusIcon size={24} />
                <h6 className="ml-6">{t('patient.goScan')}</h6>
              </div>
              <p className="mt-2 text-gray-dark md:mt-3 md:max-w-64">{t('patient.needToScanToGetExercises')}!</p>
            </div>
            <Button className="max-md:mt-2 md:ml-3" onClick={onScan}>
              Scan
            </Button>
          </div>
        ) : (
          <>
            <div className="flex-center h-28 w-28 shrink-0">
              <img className="max-h-full max-w-full" src="/assets/images/patient/user-avatar.png" alt="" />
            </div>
            <div className="flex-1 items-center justify-between px-6 py-3 md:flex">
              <div className="shrink-0 items-center max-md:flex">
                <div className="flex items-center text-primary">
                  <BarChartOutlineIcon size={16} />
                  <span className="typo-xs ml-1.5">{t('common.level#', { level: todayLevel })}</span>
                </div>
                <h6 className="max-md:ml-6 md:mt-2">{t('patient.todayTraining')}</h6>
              </div>

              <i className="fa fa-angle-right text-primary" />
            </div>
          </>
        )}
      </div>
      <p className="mt-4 text-gray-dark">{t('patient.solveYourDailyProgram')}</p>
    </div>
  );
};
