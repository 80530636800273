import React, { FC } from 'react';
import { Icon } from './Icon';
import { IIconProps } from './types';

export const ShootIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={70} baseHeight={70} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 70 70">
        <path
          fill={color}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70ZM62 35C62 49.9117 49.9117 62 35 62C20.0883 62 8 49.9117 8 35C8 20.0883 20.0883 8 35 8C49.9117 8 62 20.0883 62 35ZM64 35C64 51.0163 51.0163 64 35 64C18.9837 64 6 51.0163 6 35C6 18.9837 18.9837 6 35 6C51.0163 6 64 18.9837 64 35Z"
        />
      </svg>
    )}
  </Icon>
);
