import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Popup } from '../../components';
import { ExerciseModel } from '../../resources/models';

export interface SkipExercisePopupProps {
  className?: string;
  exercise: ExerciseModel;
  onSkip(): void;
  onClose(): void;
}

export const SkipExercisePopup: FC<SkipExercisePopupProps> = ({ className, onSkip, onClose }) => {
  const { t } = useTranslation();

  return (
    <Popup contentClass={classNames('text-typo1', className)} width="21rem" disableBackdrop onClose={onClose}>
      <h1>{t('patient.skipExercise')}</h1>
      <p className="mt-1">{t('patient.skipExerciseConfirmText')}</p>
      <Button className="mt-4" fullWidth onClick={onSkip}>
        {t('common.skip')}
      </Button>
      <Button className="mt-4" variant="outline" fullWidth onClick={onClose}>
        {t('common.cancel')}
      </Button>
    </Popup>
  );
};
