import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from '../../components';
import { ScanPhotosView } from '../ScanPhotosView';
import { SharePhotosButton } from '../SharePhotosButton';
import { PostureScoreCard } from '../PostureScoreCard';
import { ExportScanReportPdfButton } from '../ExportScanReportPdfButton';
import { ScanIssues } from '../ScanIssues';
import { ScheduleConsultationButton } from '../ScheduleConsultationButton';
import { DeleteScanConfirmPopup } from '../DeleteScanConfirmPopup';
import { fetchPatientProfile } from '../../redux/apis';
import { useDispatch, useSelector } from '../../redux/store';
import { LANGUAGE } from '../../resources/enums';
import { IssueModel, ScanModel } from '../../resources/models';
import { ScansService, ToastService } from '../../services';

export interface ScanResultOverviewProps {
  className?: string;
  scan: ScanModel;
  onViewIssue?: (issue: IssueModel) => void;
  onUnlock: VoidFunction;
  onDelete: VoidFunction;
}

export const ScanResultOverview: FC<ScanResultOverviewProps> = ({
  className,
  scan,
  onViewIssue,
  onUnlock,
  onDelete,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const language = (i18n.language || 'en') as LANGUAGE;
  const { profile } = useSelector((root) => root.patient);

  const [issues, setIssues] = useState<IssueModel[]>([]);
  const [showDeleteConfirm, setDeleteConfirm] = useState(false);

  useEffect(() => {
    ScansService.searchFeatures(scan.id, { lang: language, page: 1, take: 3 }).then((res) => {
      setIssues(res.result);
    });
  }, [scan.id, language]);

  const onDeleteScan = () => {
    ScansService.delete(scan.id)
      .then(() => {
        dispatch(fetchPatientProfile({ patientId: profile.id, force: true, showSpinner: false, showError: false }));
        onDelete();
        ToastService.success('toast.scanDeleted');
      })
      .catch((err) => {
        ToastService.showHttpError(err, 'toast.deletingScanFailed');
      });
  };

  return (
    <div className={classNames('flex flex-col gap-6', className)}>
      <h2 className="text-center font-medium">{t('patientProfile.scanResult')}</h2>

      <div>
        <div className="flex items-center justify-between">
          <h3 className="text-dark">{t('patient.yourAnalyzedPhotos')}</h3>
          <SharePhotosButton scan={scan} />
        </div>
        <ScanPhotosView className="mt-4" scan={scan} />
      </div>

      <PostureScoreCard scan={scan} />

      <ExportScanReportPdfButton scan={scan} fullWidth />

      <div>
        <h3 className="mb-4 text-typo3">{t('patient.signsOfImbalances')}</h3>
        <ScanIssues scan={scan} issues={issues} onViewIssue={onViewIssue} onUnlock={onUnlock} />
      </div>

      <ScheduleConsultationButton />

      <Button theme="danger" fullWidth onClick={() => setDeleteConfirm(true)}>
        {t('patientProfile.deleteScan')}
      </Button>

      {showDeleteConfirm && <DeleteScanConfirmPopup onDelete={onDeleteScan} onCancel={() => setDeleteConfirm(false)} />}
    </div>
  );
};
