import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { getAccount } from '../../redux/selectors';
import { useSelector } from '../../redux/store';

export interface CreditsCardProps {
  className?: string;
}

export const CreditsCard: FC<CreditsCardProps> = ({ className }) => {
  const { t } = useTranslation();
  const account = useSelector(getAccount);

  return (
    <div className={classNames('card1 px-6 py-3.5 text-center', className)}>
      <h6>{t('patientProfile.availableScans')}</h6>
      <div className="typo-h1 mt-2 font-bold !text-primary">{account.credits}</div>
    </div>
  );
};
