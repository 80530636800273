export enum EXERCISE_TYPE {
  POSTURE_BOOSTER = 'POSTURE_BOOSTER',
  LOW_BACK_RELEASE = 'LOW_BACK_RELEASE',
  SHOULDER_PAIN_RELEASE = 'SHOULDER_PAIN_RELEASE',
  NECK_TENSION_RELEASE = 'NECK_TENSION_RELEASE',
}

export const EXERCISE_TYPE_VALUES = Object.values(EXERCISE_TYPE);

export const EXERCISE_TYPES = [
  {
    text: 'Posture booster',
    value: EXERCISE_TYPE.POSTURE_BOOSTER,
    image: '/assets/images/patient/aid_posture_booster.png',
  },
  {
    text: 'Lower back pain release',
    value: EXERCISE_TYPE.LOW_BACK_RELEASE,
    image: '/assets/images/patient/aid_lower_back.png',
  },
  {
    text: 'Shoulder pain release',
    value: EXERCISE_TYPE.SHOULDER_PAIN_RELEASE,
    image: '/assets/images/patient/aid_shoulder_pain.png',
  },
  {
    text: 'Neck tension release',
    value: EXERCISE_TYPE.NECK_TENSION_RELEASE,
    image: '/assets/images/patient/aid_neck_tension.png',
  },
];
