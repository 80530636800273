import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CheckLicense } from './CheckLicense';
import { Login } from './Login';

export const AuthRouting = () => (
  <Routes>
    <Route index element={<CheckLicense />} />
    <Route path="/login" element={<Login />} />
    <Route path="*" element={<Navigate to="/login" />} />
  </Routes>
);
