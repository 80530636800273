import React, { FC } from 'react';
import { Icon } from './Icon';
import { IIconProps } from './types';

export const TimerIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M21 24H3V21C3.0598 19.2706 3.4868 17.5738 4.25265 16.0221C5.0185 14.4704 6.10567 13.0993 7.442 12C6.10567 10.9007 5.0185 9.52964 4.25265 7.97794C3.4868 6.42624 3.0598 4.72937 3 3C3 2.20435 3.31607 1.44129 3.87868 0.87868C4.44129 0.31607 5.20435 0 6 0L18 0C18.7956 0 19.5587 0.31607 20.1213 0.87868C20.6839 1.44129 21 2.20435 21 3C20.941 4.72779 20.516 6.4234 19.7532 7.97482C18.9905 9.52624 17.9072 10.8982 16.575 12C17.9072 13.1018 18.9905 14.4738 19.7532 16.0252C20.516 17.5766 20.941 19.2722 21 21V24ZM5 22H19V21C19 17.226 16.5 14.448 14.408 12.783L13.424 12L14.408 11.217C16.5 9.552 19 6.774 19 3C19 2.73478 18.8946 2.48043 18.7071 2.29289C18.5196 2.10536 18.2652 2 18 2H6C5.73478 2 5.48043 2.10536 5.29289 2.29289C5.10536 2.48043 5 2.73478 5 3C5 6.774 7.508 9.551 9.612 11.216L10.6 12L9.609 12.784C7.508 14.449 5 17.226 5 21V22ZM12.018 15.982C12.7566 16.5608 13.4106 17.24 13.961 18H10.068L12.018 15.982ZM12.014 13.513C10.377 14.586 7.585 16.843 7.08 20H16.922C16.422 16.816 13.634 14.57 12.014 13.513Z"
        />
      </svg>
    )}
  </Icon>
);
