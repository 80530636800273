import React, { forwardRef, InputHTMLAttributes, ReactNode, useState } from 'react';
import classNames from 'classnames';

export interface TextFieldProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  fullWidth?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  value?: string | number;
  label?: ReactNode;
  labelClass?: string;
  inputClass?: string;
  helperText?: ReactNode;
  helperClass?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  error?: boolean;
}

export const TextField = forwardRef<any, TextFieldProps>(
  (
    {
      className,
      fullWidth,
      size = 'md',
      value,
      label,
      labelClass,
      inputClass,
      helperText,
      helperClass,
      startAdornment,
      endAdornment,
      error,
      disabled,
      onFocus,
      onBlur,
      ...inputProps
    },
    ref,
  ) => {
    const [focused, setFocused] = useState(false);

    const wrapperClassName = classNames('relative', fullWidth && 'w-full', className);

    const inputWrapperClassName = classNames(
      'w-full flex items-center border outline-none transition-all',
      disabled
        ? 'bg-white/16 text-white/48 border-white/32'
        : [
            'bg-input-bg text-input-text',
            error ? 'border-danger' : focused ? 'border-input-border-focus' : 'border-input-border',
          ],
      size === 'sm' && ['min-h-9 text-17p rounded-10p px-2 py-1.5 gap-1.5'],
      size === 'md' && ['min-h-11 text-17p rounded-10p px-4 py-2.5 gap-2'],
      inputClass,
    );

    const labelClassName = classNames(
      'block font-semibold mb-1',
      size === 'md' && 'text-15p',
      error ? 'text-danger' : 'text-typo1',
      labelClass,
    );

    const helperClassName = classNames(
      'mt-1',
      size === 'md' && 'text-sm',
      error ? 'text-danger' : 'text-typo1',
      helperClass,
    );

    return (
      <div ref={ref} className={wrapperClassName}>
        {label && <label className={labelClassName}>{label}</label>}
        <div className={inputWrapperClassName}>
          {startAdornment}
          <input
            className="flex-grow outline-none"
            value={value}
            disabled={disabled}
            onFocus={(e) => {
              setFocused(true);
              onFocus && onFocus(e);
            }}
            onBlur={(e) => {
              setFocused(false);
              onBlur && onBlur(e);
            }}
            {...inputProps}
          />
          {endAdornment}
        </div>
        {helperText && <div className={helperClassName}>{helperText}</div>}
      </div>
    );
  },
);
