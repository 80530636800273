import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Popup } from '../../components';
import { POLICY_AGREEMENT } from '../../resources/enums';

export interface IntakeFormContentPopupProps {
  className?: string;
  form: POLICY_AGREEMENT;
  onClose(accept: boolean): void;
}

const agreementContents: Record<POLICY_AGREEMENT, string> = {
  [POLICY_AGREEMENT.GENERAL_TERMS]: '/assets/agreements/AGB_zur_Nutzung_der_BackHealth_Experts_Plattform.pdf',
  [POLICY_AGREEMENT.PRIVACY_POLICY_BACKHEALTH]: '/assets/agreements/Datenschutzerklärung.pdf',
  [POLICY_AGREEMENT.RELEASE_MEDICAL_CONFIDENCY]: '/assets/agreements/Befreiung_von_der_ärztlichen_Schweigepflicht.pdf',
  [POLICY_AGREEMENT.TREATMENT_AGREEMENT]: '/assets/agreements/Behandlungsvertrag_für_privatärztliche_Leistungen.pdf',
  [POLICY_AGREEMENT.TREATMENT_AGREEMENT_PRIVATE]: '/assets/agreements/Honorarvereinbarung_für_physiotherapeutische_Behandlung.pdf',
  [POLICY_AGREEMENT.CONSENT_PARTICIPATE]: '/assets/agreements/Zustimmung_zur_Teilnahme_an_der_Studie.pdf',
};

export const IntakeFormContentPopup: FC<IntakeFormContentPopupProps> = ({ className, form, onClose }) => {
  const { t } = useTranslation();

  return (
    <Popup contentClass={classNames('text-typo1 w-screen h-screen flex flex-col gap-4', className)} disableBackdrop>
      <h1>{t(`patientProfile.policyAgreements.${form}`)}</h1>
      <iframe
        className="w-full flex-1"
        src={agreementContents[form]}
        title={t(`patientProfile.policyAgreements.${form}`)}
      />
      <div className="flex justify-between gap-4">
        <Button className="max-sm:w-full" onClick={() => onClose(true)}>
          {t('common.accept')}
        </Button>
        <Button className="max-sm:w-full" variant="outline" onClick={() => onClose(false)}>
          {t('common.close')}
        </Button>
      </div>
    </Popup>
  );
};
