import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, Checkbox, Popup } from '../../components';
import { CheckCircleIcon } from '../../icons';
import { IntakeFormContentPopup } from '../PolicyAgreementContentPopup';
import { POLICY_AGREEMENT } from '../../resources/enums';
import { UserModel } from '../../resources/models';
import { PatientsService, ToastService } from '../../services';

export interface PolicyAgreementsPopupProps {
  className?: string;
  patient: UserModel;
  onClose(completed: boolean): void;
}

const forms = Object.values(POLICY_AGREEMENT);

export const PolicyAgreementsPopup: FC<PolicyAgreementsPopupProps> = ({ className, patient, onClose }) => {
  const { t } = useTranslation();

  const [policyAndAgreements, setPolicyAndAgreements] = useState(patient.policyAndAgreements);
  const [openedForm, setOpenedForm] = useState<POLICY_AGREEMENT>();
  const [sendEmail, setSendEmail] = useState(false);

  const allConfirmed = useMemo(() => forms.every((form) => policyAndAgreements.includes(form)), [policyAndAgreements]);

  const onCloseForm = (accept: boolean) => {
    if (accept) {
      const policyAgreements = [...policyAndAgreements, openedForm];
      PatientsService.update(patient.id, { policyAndAgreements: policyAgreements })
        .then(() => {
          setPolicyAndAgreements(policyAgreements);
        })
        .catch((err) => ToastService.showHttpError(err, 'toast.updatePatientProfileFailed'));
    }
    setOpenedForm(undefined);
  };

  const onComplete = () => {
    if (sendEmail) {
      PatientsService.update(patient.id, { isSendEmailPolicy: sendEmail });
      PatientsService.sendAgreementsEmailToPatient(patient.id);
    }
    onClose(true);
  };

  return (
    <Popup contentClass={classNames('text-typo1', className)} width="40rem" disableBackdrop>
      <div className="flex flex-col gap-6">
        <div className="flex items-center justify-between gap-4">
          <h1>{t('patientProfile.policyAgreements.title')}</h1>
          <i className="fa fa-times cursor-pointer text-2xl" onClick={() => onClose(false)} />
        </div>

        <div className="flex flex-col gap-3">
          {forms.map((form) => (
            <div
              key={form}
              className="card3 flex cursor-pointer items-center justify-between gap-4 px-4 py-3"
              onClick={() => setOpenedForm(form)}
            >
              <span className="flex-1 font-medium">{t(`patientProfile.policyAgreements.${form}`)}</span>
              <div className="flex-center min-w-18 shrink-0">
                {policyAndAgreements.includes(form) ? (
                  <CheckCircleIcon className="text-primary" />
                ) : (
                  <span className="typo-sm rounded bg-danger px-2 py-0.5 text-white">{t('common.required')}</span>
                )}
              </div>
            </div>
          ))}
        </div>

        {allConfirmed && (
          <div className="flex cursor-pointer items-center gap-3" onClick={() => setSendEmail(!sendEmail)}>
            <Checkbox checked={sendEmail} />
            <span className="font-medium">{t('patientProfile.policyAgreements.sendIntakeFormsToPatientEmail')}</span>
          </div>
        )}

        <Button fullWidth disabled={!allConfirmed} onClick={onComplete}>
          {t('common.confirm')}
        </Button>

        {openedForm && <IntakeFormContentPopup form={openedForm} onClose={onCloseForm} />}
      </div>
    </Popup>
  );
};
