import React, { FC } from 'react';
import { Icon } from './Icon';
import { IIconProps } from './types';

export const ArrowRightLongIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M23.124 9.30778L19.245 5.3024C19.0575 5.10877 18.8032 5 18.538 5C18.2728 5 18.0185 5.10877 17.831 5.3024C17.6435 5.49609 17.5382 5.75875 17.5382 6.03263C17.5382 6.3065 17.6435 6.56916 17.831 6.76285L21.386 10.4367H1C0.734784 10.4367 0.48043 10.5455 0.292893 10.7392C0.105357 10.9329 0 11.1956 0 11.4695H0C0 11.7435 0.105357 12.0062 0.292893 12.1999C0.48043 12.3936 0.734784 12.5024 1 12.5024H21.447L17.831 16.2361C17.738 16.332 17.6643 16.446 17.6139 16.5713C17.5636 16.6967 17.5377 16.8311 17.5377 16.9669C17.5377 17.1026 17.5636 17.237 17.6139 17.3624C17.6643 17.4878 17.738 17.6017 17.831 17.6976C18.0185 17.8912 18.2728 18 18.538 18C18.8032 18 19.0575 17.8912 19.245 17.6976L23.125 13.6912C23.6859 13.1088 24.0007 12.3207 24.0006 11.4993C24.0004 10.6778 23.6852 9.88986 23.124 9.30778Z"
        />
      </svg>
    )}
  </Icon>
);
