import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Badge, Button } from '../../../../components';
import { MainLayout } from '../../../../layouts';
import { ExerciseCard, IssueOverview, PageBackButton, ScanIssues } from '../../../../sections';
import { LANGUAGE, SCHEDULE_STATUS } from '../../../../resources/enums';
import { ExerciseModel, IssueModel, ScheduleModel } from '../../../../resources/models';
import { ConsultationsService, ScansService } from '../../../../services';
import { formatDatetime } from '../../../../utils/helpers';

export const AppointmentDetail = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const language = (i18n.language || 'en') as LANGUAGE;

  const [schedule, setSchedule] = useState<ScheduleModel | null>();
  const [issues, setIssues] = useState<IssueModel[]>([]);
  const [exercises, setExercises] = useState<ExerciseModel[]>([]);
  const [sidebarView, setSidebarView] = useState<string>();
  const [selectedIssue, setSelectedIssue] = useState<IssueModel>();
  const [selectedExercise, setSelectedExercise] = useState<ExerciseModel>();

  const scan = useMemo(() => schedule?.case?.user?.scan, [schedule]);

  const isActiveAppointment = useMemo(
    () =>
      [SCHEDULE_STATUS.ACTIVE, SCHEDULE_STATUS.RE_OPEN, SCHEDULE_STATUS.IN_PROGRESS].includes(schedule?.status) &&
      new Date(schedule.endTime).getTime() > Date.now(),
    [schedule],
  );

  useEffect(() => {
    ConsultationsService.getScheduleById(id)
      .then((data) => {
        setSchedule(data);
      })
      .catch(() => {
        setSchedule(null);
      });
  }, [id]);

  useEffect(() => {
    if (!scan) {
      setIssues([]);
      setExercises([]);
      return;
    }

    ScansService.searchFeatures(scan.id, { lang: language, page: 1, take: 3 })
      .then((res) => setIssues(res.result))
      .catch(() => setIssues([]));

    ScansService.searchExercises(scan.id, { lang: language })
      .then((res) => setExercises(res.result))
      .catch(() => setExercises([]));
  }, [scan, language]);

  const onSelectIssue = (issue: IssueModel) => {
    setSelectedIssue(issue);
    setSidebarView('issue');
  };

  const onSelectExercise = (exercise: ExerciseModel) => {
    setSelectedExercise(exercise);
    setSidebarView('exercise');
  };

  const sidebar = useMemo(() => {
    if (!schedule) {
      return null;
    }

    if (sidebarView === 'issue' && selectedIssue) {
      return (
        <>
          <div
            className="typo-link inline-block p-2 font-semibold text-primary"
            onClick={() => setSidebarView(undefined)}
          >
            <i className="fa fa-angle-left" />
            <span className="ml-1.5">{t('common.back')}</span>
          </div>
          <IssueOverview className="mt-4" issue={selectedIssue} hideButton />
        </>
      );
    }

    if (sidebarView === 'exercise' && selectedExercise) {
      const content = selectedExercise.getLocalizedContent();
      const videoUrl = `${content?.video || selectedExercise.videoVimeo}?autoplay=1&controls=1&badge=0&byline=0&portrait=0&title=0&loop=1`;

      return (
        <>
          <div
            className="typo-link inline-block p-2 font-semibold text-primary"
            onClick={() => setSidebarView(undefined)}
          >
            <i className="fa fa-angle-left" />
            <span className="ml-1.5">{t('common.back')}</span>
          </div>

          <h2 className="mt-4">{content?.title}</h2>
          <div className="flex-center mt-6">
            <iframe
              className="aspect-video w-full"
              title={content?.title}
              src={videoUrl}
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
          </div>
          <p className="mt-6 text-typo1">{content?.description}</p>
        </>
      );
    }

    return (
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <h3>{t('patient.signsOfImbalances')}</h3>
          <ScanIssues issues={issues} onViewIssue={onSelectIssue} />
        </div>

        <div className="flex flex-col gap-4">
          <h3>{t('patientProfile.listOfExercises')}</h3>
          <div className="flex flex-col gap-4">
            {exercises.map((exercise) => (
              <ExerciseCard
                key={exercise.id}
                exercise={exercise}
                active
                hideIcon
                onClick={() => onSelectExercise(exercise)}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }, [schedule, sidebarView, issues, exercises, selectedIssue, selectedExercise, t]);

  return (
    <MainLayout sidebar={sidebar} sidebarOverlap={Boolean(sidebarView)}>
      <PageBackButton className="mb-8" />
      <h1 className="mb-8">{t('appointments.detail.title')}</h1>
      {schedule === null && (
        <div className="flex-center flex-1 p-8 text-center text-gray-dark">{t('toast.appointmentNotFound')}</div>
      )}

      {schedule && (
        <>
          <div className="card2 relative grid max-w-162 grid-cols-auto-1fr gap-x-6 gap-y-4 rounded-10p p-6 font-medium">
            <div className="text-stroke1">{t('appointments.date')}</div>
            <div>{formatDatetime(schedule.startTime, 'L')}</div>

            <div className="text-stroke1">{t('appointments.time')}</div>
            <div>{formatDatetime(schedule.startTime, 'HH:mm')}</div>

            <div className="text-stroke1">{t('common.patient')}</div>
            <div>{schedule.case?.user?.name}</div>

            <div className="text-stroke1">{t('common.expert')}</div>
            <div>{schedule.case?.doctor?.name}</div>

            <div className="text-stroke1">{t('appointments.consultationId')}</div>
            <div>{schedule.id.substr(-12)}</div>

            {schedule.status === SCHEDULE_STATUS.USER_CANCELLED && (
              <Badge className="absolute right-2 top-2 !px-2 !py-1">{t('appointments.status.canceled')}</Badge>
            )}
            {schedule.status === SCHEDULE_STATUS.COMPLETED && (
              <Badge className="absolute right-2 top-2 !px-2 !py-1">{t('appointments.status.completed')}</Badge>
            )}
            {schedule.status === SCHEDULE_STATUS.IN_PROGRESS && (
              <Badge className="absolute right-2 top-2 !px-2 !py-1">{t('appointments.status.inProgress')}</Badge>
            )}
          </div>

          {isActiveAppointment && (
            <Button className="mt-6" link={`/appointments/${schedule.id}/call`}>
              {t('appointments.detail.gotoWaitingRoom')}
            </Button>
          )}
        </>
      )}
    </MainLayout>
  );
};
