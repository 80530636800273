import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../components';
import { MainLayout } from '../../../../../layouts';
import {
  PageBackButton,
  ExerciseCard,
  TrainingModal,
  ExerciseTutorialCard,
  FirstAidKits,
} from '../../../../../sections';
import { useSelector } from '../../../../../redux/store';
import { EXERCISE_TYPE, LOADING_STATUS } from '../../../../../resources/enums';

export const FirstAid = () => {
  const { t } = useTranslation();
  const { type } = useParams<{ type: EXERCISE_TYPE }>();
  const { firstAidKits, firstAidKitsLoadingStatus } = useSelector((root) => root.patient);
  const [showTrainModal, setShowTrainModal] = useState(false);

  const exercise = useMemo(() => {
    const aidKit = firstAidKits.find((item) => item.type === type);
    if (aidKit) {
      return aidKit;
    }
    return firstAidKitsLoadingStatus === LOADING_STATUS.SUCCESS ? null : undefined;
  }, [firstAidKits, firstAidKitsLoadingStatus, type]);

  const sidebar = (
    <>
      <ExerciseTutorialCard />
      <FirstAidKits className="mt-10" />
    </>
  );

  return (
    <MainLayout sidebar={sidebar}>
      <PageBackButton />

      <h1 className="mt-8">{t(`patient.firstAids.${type}`)}</h1>

      <p className="mt-6 text-typo1">{t('patient.yourPersonalRecommendation')}</p>

      {exercise && (
        <>
          <ExerciseCard className="mt-6 max-w-92" exercise={exercise} active onClick={() => setShowTrainModal(true)} />

          <Button className="mt-6" onClick={() => setShowTrainModal(true)}>
            {t('patient.startTraining')}
          </Button>
        </>
      )}

      {exercise === null && <div className="card1 flex-center mt-6 h-22 max-w-92">{t('common.notAvailable')}</div>}

      {showTrainModal && (
        <TrainingModal exercise={exercise} reportWhenFinished={false} onClose={() => setShowTrainModal(false)} />
      )}
    </MainLayout>
  );
};
