import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export interface IWebcamRef {
  video: HTMLVideoElement;
  stream: MediaStream;
  mediaSettings: MediaTrackSettings;
  capabilities: MediaTrackCapabilities;
  zoomSettings: IMediaZoomSettings;
}

export interface IMediaZoomSettings {
  isSupported: boolean;
  min: number;
  max: number;
  step: number;
  value: number;
  onChange: (value: number) => void;
}

export interface WebcamProps {
  className?: string;
  constraints?: MediaStreamConstraints;
  onInit?: (ref: IWebcamRef) => void;
  onError?: (error: string) => void;
}

export const Webcam: FC<WebcamProps> = ({ className, onInit, onError }) => {
  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();
  const videoRef = useRef<HTMLVideoElement>();
  const streamRef = useRef<MediaStream>();

  useEffect(() => {
    const timer = setTimeout(() => {
      setWidth(videoRef.current.clientWidth);
      setHeight(videoRef.current.clientHeight);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  });

  const initCamera = useCallback(() => {
    if (!width || !height || !videoRef.current) {
      return;
    }

    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      if (onError) {
        onError('Webcam is not supported in this browser');
      }
      return;
    }

    navigator.mediaDevices
      .getUserMedia({
        video: {
          width: { ideal: width },
          height: { ideal: height },
          aspectRatio: { ideal: width / height },
          facingMode: 'environment',
          zoom: true,
        } as any,
        audio: false,
      })
      .then((stream) => {
        streamRef.current = stream;
        const [track] = stream.getVideoTracks();
        const settings = track.getSettings() as any;
        const capabilities = track.getCapabilities() as any;
        const video = videoRef.current;
        video.srcObject = stream;
        // play background video
        video.playsInline = true;
        video.muted = true;
        video.play();

        if (onInit) {
          let isSupported = true;
          if (!('zoom' in settings) || !('zoom' in capabilities)) {
            isSupported = false;
          }

          const zoomSettings = {
            isSupported,
            min: capabilities.zoom?.min || 0.5,
            max: capabilities.zoom?.max || 2,
            step: capabilities.zoom?.step || 0.1,
            value: settings.zoom || 1,
            onChange: (value) => {
              track.applyConstraints({
                advanced: [{ zoom: value }],
              } as any);
            },
            zoom: capabilities.zoom,
          };

          onInit({
            video: videoRef.current,
            stream,
            mediaSettings: settings,
            capabilities,
            zoomSettings,
          });
        }
        if (onError) {
          onError(undefined);
        }
      })
      .catch((err) => {
        console.log('webcam init error', err);
        if (onError) {
          onError(err?.message || 'Initializing webcam failed');
        }
      });
  }, [width, height, onInit, onError]);

  useEffect(() => {
    const closeCurrentStream = () => {
      if (streamRef.current) {
        streamRef.current.getVideoTracks().forEach((track) => {
          streamRef.current.removeTrack(track);
          track.stop();
        });
      }
    };

    initCamera();

    return closeCurrentStream;
  }, [initCamera, onError, onInit]);

  return (
    <>
      <video className={twMerge(['object-cover', 'w-full', 'h-full', className])} ref={videoRef} />
    </>
  );
};
