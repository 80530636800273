import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { Button, Checkbox, TimeSelect } from '../../components';
import { SetWeeklyAvailabilityDto } from '../../resources/dtos';
import { TimeslotModel } from '../../resources/models';
import { ConsultationsService, ToastService } from '../../services';
import { checkTimeValidity, formatHoursToTimeString, getHoursFromTimeString } from '../../utils/helpers';

export interface WeeklyHoursFormProps {
  className?: string;
}

export const WeeklyHoursForm: FC<WeeklyHoursFormProps> = ({ className }) => {
  const { t } = useTranslation();

  const [weekdays, setWeekdays] = useState<boolean[]>(new Array(7).fill(false));
  const [timeslots, setTimeslots] = useState<TimeslotModel[][]>([]);
  const [submitting, setSubmitting] = useState(false);

  const isValid = useMemo(() => {
    if (weekdays.every((enabled) => !enabled)) {
      return false;
    }
    if (timeslots.every((slots) => !slots.length)) {
      return false;
    }
    for (const slots of timeslots) {
      for (let i = 0; i < slots.length; i++) {
        const slot = slots[i];
        if (!checkTimeValidity(slot, slots.slice(0, i))) {
          return false;
        }
      }
    }
    return true;
  }, [weekdays, timeslots]);

  useEffect(() => {
    ConsultationsService.getMyAvailabilities(true)
      .then((data) => {
        const slots: TimeslotModel[][] = new Array(7).fill('').map(() => []);
        const weekdays: boolean[] = new Array(7).fill(false);
        data.forEach((item) => {
          slots[item.dow - 1] = item.timeslots;
          weekdays[item.dow - 1] = !item.disabled;
        });
        setTimeslots(slots);
        setWeekdays(weekdays);
      })
      .catch(() => {
        setTimeslots([]);
      });
  }, []);

  const onEnableWeekday = (weekday: number, enabled: boolean) => {
    setWeekdays((weekdays) => weekdays.map((item, i) => (i === weekday ? enabled : item)));
  };

  const onChangeWeekdaySlots = (weekday: number, slots: TimeslotModel[]) => {
    setTimeslots((timeslots) => timeslots.map((item, i) => (i === weekday ? slots : item)));
  };

  const onAddSlot = (weekday: number, index: number) => {
    const newSlots = [...(timeslots[weekday] ?? [])];
    const newSlot = new TimeslotModel({
      id: uuidv4(),
    });
    if (index === -1) {
      newSlots.push(newSlot);
    } else {
      newSlots.splice(index + 1, 0, newSlot);
    }
    if (newSlots.length === 1) {
      onEnableWeekday(weekday, true);
    }
    onChangeWeekdaySlots(weekday, newSlots);
  };

  const onChangeSlot = (weekday: number, id: string, field: keyof TimeslotModel, value: string) => {
    const newSlots = timeslots[weekday].map((slot) => {
      if (slot.id !== id) {
        return slot;
      }
      const newSlot = { ...slot, [field]: value };
      if (field === 'from' && value.localeCompare(newSlot.to) >= 0) {
        newSlot.to = formatHoursToTimeString(getHoursFromTimeString(value) + 0.5);
      } else if (field === 'to' && value.localeCompare(newSlot.from) <= 0) {
        newSlot.from = formatHoursToTimeString(getHoursFromTimeString(value) - 0.5);
      }
      return newSlot;
    });
    onChangeWeekdaySlots(weekday, newSlots);
  };

  const onRemoveSlot = (weekday: number, id: string) => {
    const newSlots = timeslots[weekday].filter((slot) => slot.id !== id);
    if (!newSlots.length) {
      onEnableWeekday(weekday, false);
    }
    onChangeWeekdaySlots(weekday, newSlots);
  };

  const onSubmit = () => {
    if (!isValid) {
      return;
    }

    setSubmitting(true);
    const availableTimes: SetWeeklyAvailabilityDto[] = timeslots.map((slots, i) => ({
      day_of_week: i + 1,
      disabled: !weekdays[i],
      timeslots: slots.map((slot) => ({
        id: slot.id,
        from_time: slot.from,
        to_time: slot.to,
      })),
    }));
    ConsultationsService.setMyWeeklyAvailabilities(availableTimes, false)
      .then(() => {
        ToastService.success('toast.availabilityUpdated');
      })
      .catch((err) => ToastService.showHttpError(err, 'toast.updatingAvailabilityFailed'))
      .finally(() => setSubmitting(false));
  };

  return (
    <div className={classNames('flex h-full flex-col gap-4', className)}>
      <div className="bg-white">
        {weekdays.map((enabled, weekday) => (
          <Fragment key={weekday}>
            {weekday > 0 && <hr className="ml-4 border-stroke1" />}

            <div className="flex items-start gap-3 p-2">
              <div className="mr-auto flex items-center gap-2 py-3">
                <Checkbox
                  label={t(`date.shortWeekdays.${weekday}`)}
                  checked={enabled}
                  disabled={!timeslots[weekday]?.length}
                  onChange={(value) => onEnableWeekday(weekday, value)}
                />
              </div>

              <div className="flex flex-col gap-2">
                {timeslots[weekday]?.map((slot, i) => (
                  <div key={slot.id} className="flex items-center">
                    <div
                      className="flex-center mr-3 h-6 w-6 shrink-0 cursor-pointer rounded-full bg-body2"
                      onClick={() => onRemoveSlot(weekday, slot.id)}
                    >
                      <i className="fa fa-times text-xl" />
                    </div>
                    <TimeSelect
                      className="w-21"
                      value={slot.from}
                      min={6}
                      max={22}
                      skipLastTime
                      error={!checkTimeValidity(slot, timeslots[weekday].slice(0, i))}
                      onChange={(value) => onChangeSlot(weekday, slot.id, 'from', value)}
                    />
                    <span className="mx-1">-</span>
                    <TimeSelect
                      className="w-21"
                      value={slot.to}
                      min={slot.from ?? 6}
                      max={22}
                      skipFirstTime
                      error={!checkTimeValidity(slot, timeslots[weekday].slice(0, i))}
                      onChange={(value) => onChangeSlot(weekday, slot.id, 'to', value)}
                    />
                    <i className="fa fa-plus ml-2 cursor-pointer text-xl" onClick={() => onAddSlot(weekday, i)} />
                  </div>
                ))}
                {!timeslots[weekday]?.length && (
                  <div className="flex items-center gap-2">
                    <div className="flex-center h-12.5 w-46 rounded border border-stroke1 text-label">
                      {t('appointments.availability.editor.unavailable')}
                    </div>
                    <i className="fa fa-plus cursor-pointer text-xl" onClick={() => onAddSlot(weekday, -1)} />
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        ))}
      </div>

      <Button className="mt-auto" fullWidth loading={submitting} disabled={!isValid} onClick={onSubmit}>
        {t('appointments.availability.editor.saveAvailability')}
      </Button>
    </div>
  );
};
