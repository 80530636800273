import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components';
import { PatientIntakeForms } from '../PatientIntakeForms';
import { ScheduleConsultationPopup } from '../ScheduleConsultationPopup';
import { DOCTOR_ROLE, POLICY_AGREEMENT, STATUS } from '../../resources/enums';
import { getAccount } from '../../redux/selectors';
import { useDispatch, useSelector } from '../../redux/store';
import { setPatientProfile } from '../../redux/reducers/patient.reducer';
import { UserModel } from '../../resources/models';

export interface IScheduleConsultationButtonProps {
  className?: string;
}

export const ScheduleConsultationButton: FC<IScheduleConsultationButtonProps> = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const account = useSelector(getAccount);
  const { profile, scan } = useSelector((state) => state.patient);

  const [showSharePopup, setShowSharePopup] = useState(false);

  const onScheduleConsultation = () => {
    setShowSharePopup(true);
  };

  const onCloseIntakeForms = (completed: boolean) => {
    if (completed) {
      dispatch(
        setPatientProfile(
          new UserModel({
            ...profile,
            isSubmittedAnamnesis: true,
            policyAndAgreements: Object.values(POLICY_AGREEMENT),
          }),
        ),
      );
    } else {
      setShowSharePopup(false);
    }
  };

  if (account.doctorRole !== DOCTOR_ROLE.TRAINER || !profile.hasInsurance || scan?.status !== STATUS.COMPLETED) {
    return null;
  }

  return (
    <>
      <Button className={className} theme="primary" fullWidth onClick={onScheduleConsultation}>
        {t('appointments.schedule.scheduleConsultation')}
      </Button>

      {showSharePopup &&
        (profile.isSubmittedAnamnesis && profile.isCompletedAgreements ? (
          <ScheduleConsultationPopup user={profile} onClose={() => setShowSharePopup(false)} />
        ) : (
          <PatientIntakeForms patient={profile} onClose={onCloseIntakeForms} />
        ))}
    </>
  );
};
