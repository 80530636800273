import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../components';
import { MainLayout } from '../../../../../layouts';
import { useSelector } from '../../../../../redux/store';
import { ExerciseModel } from '../../../../../resources/models';
import {
  ExerciseCard,
  ExerciseTutorialCard,
  FirstAidKits,
  PageBackButton,
  TrainingModal,
  TrainLevel,
} from '../../../../../sections';

export const ExerciseTraining = () => {
  const { t } = useTranslation();

  const { dailyExercises, trainStatus } = useSelector((root) => root.patient);
  const remainTrainCount = trainStatus?.remainTrainCount || 0;
  const [trainingExercise, setTrainingExercise] = useState<ExerciseModel>();

  const remainExercises = useMemo(() => {
    const remainIds = dailyExercises
      .map((item, index) => ({
        id: item.id,
        index,
        doneAt: item.scan?.doneAt ? new Date(item.scan.doneAt).getTime() : 0,
      }))
      .sort((a, b) => a.doneAt - b.doneAt || b.index - a.index)
      .slice(0, remainTrainCount)
      .map((item) => item.id);
    return dailyExercises.filter((item) => remainIds.includes(item.id));
  }, [dailyExercises, remainTrainCount]);

  const onStartTraining = () => {
    if (remainExercises.length) {
      setTrainingExercise(remainExercises[0]);
    }
  };

  const onTrainingFinished = (result: 'finished' | 'skipped' | 'cancelled') => {
    if (result === 'finished' || result === 'skipped') {
      const id = remainExercises.findIndex((item) => item.id === trainingExercise.id);
      const nextId = (id + 1) % remainExercises.length;
      setTrainingExercise(id === nextId ? undefined : remainExercises[nextId]);
    } else {
      setTrainingExercise(undefined);
    }
  };

  const sidebar = (
    <>
      <ExerciseTutorialCard />
      <FirstAidKits className="mt-10" />
    </>
  );

  return (
    <MainLayout sidebar={sidebar}>
      <PageBackButton />

      <h1 className="mt-8">{t('patient.todayTraining')}</h1>

      <TrainLevel className="mt-8" />

      {trainStatus && (
        <>
          <h2 className="mt-6 font-bold">{t('patientProfile.listOfExercises')}</h2>
          <div className="mt-4 grid gap-6 xl:grid-cols-2">
            {dailyExercises.map((exercise) => (
              <ExerciseCard
                key={exercise.id}
                exercise={exercise}
                active={remainExercises.some((item) => item.id === exercise.id)}
                onClick={() => setTrainingExercise(exercise)}
              />
            ))}
          </div>
          <Button className="mt-6" disabled={!remainTrainCount} onClick={onStartTraining}>
            {t('patient.startTraining')}
          </Button>
        </>
      )}

      {trainingExercise && (
        <TrainingModal key={trainingExercise.id} exercise={trainingExercise} onClose={onTrainingFinished} />
      )}
    </MainLayout>
  );
};
