import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { ScanModel } from '../../resources/models';

export interface PostureScoreCardProps {
  className?: string;
  scan: ScanModel;
}

export const PostureScoreCard: FC<PostureScoreCardProps> = ({ className, scan }) => {
  const { t } = useTranslation();

  const postureScore = Math.round(scan.healthScore * 100);

  return (
    <div className={classNames('card3 flex items-center justify-between p-4', className)}>
      <div>
        <h3 className="mb-3.5">{t('patient.yourPostureScore')}</h3>
        <span className="typo-link uppercase">{t('common.learnMore')}</span>
      </div>
      <CircularProgressbar
        className="w-18"
        value={postureScore}
        text={`${postureScore}%`}
        strokeWidth={8}
        styles={buildStyles({
          strokeLinecap: 'round',
        })}
      />
    </div>
  );
};
