import React, { FC, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FillIntakeFormPopup, PaperworkCompletedPopup, PatientForm, PatientIntakeForms } from '../../sections';
import { CreatePatientDto } from '../../resources/dtos';
import { UserModel } from '../../resources/models';
import { PatientsService, ToastService } from '../../services';

export interface CreatePatientProps {
  className?: string;
  defaultHasInsurance?: boolean;
  onCreate(patient: UserModel): void;
  onClose: VoidFunction;
}

export const CreatePatient: FC<CreatePatientProps> = ({ className, defaultHasInsurance, onCreate, onClose }) => {
  const [step, setStep] = useState(1);
  const [patient, setPatient] = useState<UserModel>();
  const [showIntakeFormPopup, setShowIntakeFormPopup] = useState(false);
  const [showCompletedPopup, setShowCompletedPopup] = useState(false);

  const onCreatePatient = async (formData: Partial<UserModel>) => {
    if (formData.hasInsurance) {
      formData.typeFormRefId = uuidv4();
    }
    return PatientsService.create(formData as CreatePatientDto, false)
      .then((newPatient) => {
        setPatient(newPatient);
        onCreate(newPatient);
        if (newPatient.hasInsurance) {
          setShowIntakeFormPopup(true);
        } else {
          ToastService.success('toast.invitationSent');
          onClose();
        }
      })
      .catch((err) => ToastService.showHttpError(err, 'toast.invitePatientFailed'));
  };

  const onShowIntakeForms = () => {
    setStep(2);
    setShowIntakeFormPopup(false);
  };

  const onCloseIntakeForms = (completed: boolean) => {
    if (completed) {
      setStep(3);
      setShowCompletedPopup(true);
    } else {
      onClose();
    }
  };

  const onCloseCompletedPopup = () => {
    setShowCompletedPopup(false);
    onClose();
  };

  return (
    <>
      <PatientForm className={className} defaultHasInsurance={defaultHasInsurance} onSubmit={onCreatePatient} />

      {step === 2 && <PatientIntakeForms className={className} patient={patient} onClose={onCloseIntakeForms} />}

      {showIntakeFormPopup && <FillIntakeFormPopup onContinue={onShowIntakeForms} />}

      {showCompletedPopup && <PaperworkCompletedPopup onClose={onCloseCompletedPopup} />}
    </>
  );
};
