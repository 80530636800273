import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InfoCircleIcon } from '../../../../../icons';
import { MainLayout } from '../../../../../layouts';
import { PageBackButton, FirstAidKits, ExerciseTutorialCard } from '../../../../../sections';
import { useSelector } from '../../../../../redux/store';

export const LearnExercise = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { dailyExercises, firstAidKits } = useSelector((root) => root.patient);

  let exercise = dailyExercises.find((item) => item.id === id);
  if (!exercise) {
    exercise = firstAidKits.find((item) => item.id === id);
  }

  const content = useMemo(() => exercise?.getLocalizedContent(), [exercise]);

  const videoUrl = useMemo(() => {
    const url = content?.video || exercise?.videoVimeo;
    return `${url}?autoplay=1&controls=1&badge=0&byline=0&portrait=0&title=0&loop=1`;
  }, [content, exercise]);

  const sidebar = (
    <>
      <ExerciseTutorialCard />
      <FirstAidKits className="mt-10" />
    </>
  );

  return (
    <MainLayout sidebar={sidebar}>
      <PageBackButton />

      {exercise && (
        <>
          <h1 className="mt-8">{content?.title}</h1>

          <div className="flex-center mt-6">
            <iframe
              className="aspect-video w-full"
              title={content?.title}
              src={videoUrl}
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
          </div>

          <p className="mt-6 text-typo1">{content?.description}</p>

          <div className="typo-xs mt-6 flex items-center text-gray-dark">
            <InfoCircleIcon className="mr-2" size={16} />
            {t('patient.personalTrainingPlanHelperText')}.
          </div>
        </>
      )}
    </MainLayout>
  );
};
