import { Route, Routes } from 'react-router-dom';
import { Settings } from '.';

export const SettingsRouting = () => {
  return (
    <Routes>
      <Route index element={<Settings />} />
    </Routes>
  );
};
