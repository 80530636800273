import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

export interface BadgeProps extends PropsWithChildren {
  className?: string;
  color?: 'default';
}

export const Badge: FC<BadgeProps> = ({ className, color = 'default', children }) => {
  return (
    <span
      className={classNames(
        'min-w-5 rounded-full border px-1 text-center text-xs font-bold',
        color === 'default' && 'border-stroke1 bg-white text-gray-dark',
        className,
      )}
    >
      {children}
    </span>
  );
};
