import { DOCTOR_ROLE } from '../enums';

export class DoctorModel {
  id: string;
  doctorName?: string;
  name: string;
  email: string;
  deviceToken?: string;
  language: string;
  appVersion?: string;
  quote: string;
  overview: string;
  credits: number;
  doctorRole: DOCTOR_ROLE;
  image?: any;
  createdAt: string;
  updatedAt: string;

  constructor(init?: Partial<DoctorModel>) {
    const data = {
      id: null,
      ...init,
    };

    this.id = data.id;
    this.name = data.name;
    this.email = data.email;
    this.deviceToken = data.deviceToken;
    this.language = data.language;
    this.appVersion = data.appVersion;
    this.quote = data.quote;
    this.overview = data.overview;
    this.credits = data.credits;
    this.doctorRole = data.doctorRole;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.image = data.image;
  }
}
