import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormProvider, Popup, RHFTextField } from '../../components';
import { AuthService, ToastService } from '../../services';

type FormData = {
  email: string;
};

export interface IForgotPasswordPopupProps {
  className?: string;
  onClose(): void;
}

export const ForgotPasswordPopup: FC<IForgotPasswordPopupProps> = ({ className, onClose }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const formSchema = Yup.object().shape({
    email: Yup.string().email(t('validationErrors.invalidEmail')).required(t('validationErrors.requiredField')),
  });

  const defaultValues = {
    email: '',
  };

  const methods = useForm<FormData>({
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const onSubmit = (form: FormData) => {
    setLoading(true);
    AuthService.forgotPassword(form.email, false)
      .then(() => {
        ToastService.success('toast.forgotPasswordRequestSent');
        onClose();
      })
      .catch((err) => ToastService.showHttpError(err, 'toast.forgotPasswordFailed'))
      .finally(() => setLoading(false));
  };

  return (
    <Popup contentClass={classNames('text-typo1', className)} width="24rem" disableBackdrop onClose={onClose}>
      <h1 className="mb-8">{t('auth.forgotPassword')}</h1>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <RHFTextField
          name="email"
          label={t('auth.forgotPasswordHelperText')}
          placeholder={t('auth.emailPlaceholder')}
        />
        <Button className="mt-8" type="submit" fullWidth loading={loading} disabled={!isValid}>
          {t('common.submit')}
        </Button>
        <Button className="mt-4" type="button" variant="outline" fullWidth onClick={onClose}>
          {t('common.cancel')}
        </Button>
      </FormProvider>
    </Popup>
  );
};
