import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import { useWindowSize } from '../../hooks';
import { TimeslotModel } from '../../resources/models';
import { ConsultationsService } from '../../services';

export interface ScheduleCalendarProps {
  className?: string;
  selectedDate?: Date;
  onChange(date: Date): void;
  onLoadTimeslots(timeslots: Record<string, TimeslotModel[]>): void;
}

export const ScheduleCalendar: FC<ScheduleCalendarProps> = ({ className, selectedDate, onChange, onLoadTimeslots }) => {
  const { windowWidth } = useWindowSize();

  const today = moment();
  const [viewDate, setViewDate] = useState(moment(selectedDate).startOf('day'));
  const [timeslots, setTimeslots] = useState<Record<string, TimeslotModel[]>>({});

  const viewCount = useMemo(() => {
    if (windowWidth >= 1000) {
      return 7;
    }
    if (windowWidth >= 876) {
      return 6;
    }
    if (windowWidth >= 786) {
      return 5;
    }
    if (windowWidth >= 400) {
      return 4;
    }
    return Math.max(1, Math.floor((windowWidth - 100) / 80));
  }, [windowWidth]);

  const dates = useMemo(() => {
    let startDate: Moment;
    if (viewCount === 7) {
      startDate = moment(viewDate).startOf('week');
    } else {
      startDate = moment(viewDate).subtract(Math.floor((viewCount - 1) / 2), 'days');
    }
    const dates: Moment[] = [];
    for (let i = 0; i < viewCount; i++) {
      dates.push(moment(startDate));
      startDate.add(1, 'day');
    }
    return dates;
  }, [viewDate, viewCount]);

  const loadTimeslots = useCallback((year: number, month: number) => {
    ConsultationsService.getScheduleTimeslots(year, month, false)
      .then((data) => {
        if (!data.length) {
          return;
        }
        setTimeslots((prev) => {
          data.forEach((item) => {
            prev[item.date.substring(0, 10)] = item.timeslots;
          });
          return { ...prev };
        });
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    const date = viewDate.format('YYYY-MM-DD');
    if (!timeslots[date]) {
      loadTimeslots(viewDate.year(), viewDate.month() + 1);
    }
  }, [viewDate, loadTimeslots]);

  const getTimeslots = useCallback(
    (date: Moment) => {
      return timeslots[date.format('YYYY-MM-DD')] ?? [];
    },
    [timeslots],
  );

  useEffect(() => {
    onLoadTimeslots(timeslots);
  }, [timeslots]);

  const onViewNextMonth = (diff: number) => {
    setViewDate(moment(viewDate).add(diff, 'months'));
  };

  const onViewNextPage = (diff: number) => {
    setViewDate(moment(viewDate).add(diff * viewCount, 'days'));
  };

  const onSelectDate = (date: Moment) => {
    if (!selectedDate || !moment(selectedDate).isSame(date, 'date')) {
      onChange(date.toDate());
    }
  };

  return (
    <div className={classNames('flex-center select-none flex-col gap-6', className)}>
      <div className="flex-center gap-2 text-center text-typo1">
        <i
          className={classNames(
            'fa fa-angle-left shrink-0 cursor-pointer px-2 text-xl hover:opacity-80',
            viewDate.isSameOrBefore(today, 'month') && 'pointer-events-none opacity-60',
          )}
          onClick={() => onViewNextMonth(-1)}
        />
        <span className="typo-h3 min-w-45 font-medium">{viewDate.format('MMMM YYYY')}</span>
        <i
          className="fa fa-angle-right shrink-0 cursor-pointer px-2 text-xl hover:opacity-80"
          onClick={() => onViewNextMonth(1)}
        />
      </div>

      <div className="flex-center w-full gap-2">
        <i
          className={classNames(
            'fa fa-angle-left shrink-0 cursor-pointer px-2 text-xl hover:opacity-80',
            viewDate.isSameOrBefore(today, 'date') && 'pointer-events-none opacity-60',
          )}
          onClick={() => onViewNextPage(-1)}
        />
        {dates.map((date, i) => {
          const active = date.isSame(selectedDate, 'date');
          const disabled = date.isBefore(today, 'date');
          return (
            <div
              key={i}
              className={classNames(
                'flex-center flex-1 cursor-pointer flex-col gap-1 rounded-lg p-4 text-center lg:max-w-27',
                active
                  ? 'card-primary shadow-button shadow-button1-hover/40'
                  : 'card1 hover:shadow-button hover:shadow-button1-hover/25',
                disabled && 'pointer-events-none !border-gray-dark !text-gray-dark opacity-60',
              )}
              onClick={() => onSelectDate(date)}
            >
              <span className="typo-h3 font-medium uppercase !text-inherit">{date.format('ddd')}</span>
              <span className="typo-h2 font-semibold !text-inherit">{date.format('D')}</span>

              <span
                className={classNames(
                  'flex-center typo-h4 mt-2 h-8 min-w-8 rounded-full border p-1 font-bold',
                  active ? 'border-card-primary-text' : 'border-inherit',
                )}
              >
                {getTimeslots(date).length}
              </span>
            </div>
          );
        })}
        <i
          className="fa fa-angle-right shrink-0 cursor-pointer px-2 text-xl hover:opacity-80"
          onClick={() => onViewNextPage(1)}
        />
      </div>
    </div>
  );
};
