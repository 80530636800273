import i18next, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { CONFIG } from '../constants';
import enTranslation from './translations/en.json';
import deTranslation from './translations/de.json';

export const allLangs = [
  { name: 'English', value: 'en' },
  { name: 'Deutsch', value: 'de' },
];

export const resources = {
  en: {
    translation: enTranslation,
  },
  de: {
    translation: deTranslation,
  },
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // Standard language used
    fallbackLng: 'en',
    debug: CONFIG.DEBUG,
    resources,
    // Detects and caches a cookie from the language provided
    detection: {
      order: ['localStorage'],
      cache: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
  } as InitOptions);

export default i18next;
