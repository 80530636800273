import React, { FC } from 'react';
import { Icon } from './Icon';
import { IIconProps } from './types';

export const ChevronRightIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <path fill={color} d="M8.59 16.59 13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
      </svg>
    )}
  </Icon>
);
