import { HttpService } from './http.service';
import { DoctorModel, UserModel } from '../resources/models';
import { JwtResponse } from '../resources/interfaces';
import { ApiResponseDto } from '../resources/dtos';

export class AuthService {
  static login(email: string, password: string, hasSpinner = true): Promise<ApiResponseDto<JwtResponse>> {
    return HttpService.post('/auth/doctor/verify', { email, password }, hasSpinner);
  }

  static forgotPassword(email: string, hasSpinner = true): Promise<void> {
    return HttpService.post('/auth/doctor/forgot-password', { email }, hasSpinner);
  }

  static getProfile(hasSpinner = true): Promise<DoctorModel> {
    return HttpService.get('/auth/doctor/profile', {}, hasSpinner).then(
      (result) => new DoctorModel({ ...result.data, language: result.data.language || 'en' }),
    );
  }

  static updateDoctorProfile(data: Partial<DoctorModel & { imageId: string }>, hasSpinner = true) {
    return HttpService.patch('/auth/doctor/profile', data, hasSpinner).then((result) => new DoctorModel(result.data));
  }

  static updateProfile(data: Partial<UserModel>, hasSpinner = true) {
    return HttpService.patch('/auth/profile', data, hasSpinner).then((result) => new UserModel(result.data));
  }

  static deleteProfile(hasSpinner = true): Promise<void> {
    return HttpService.delete('/auth/profile', {}, hasSpinner);
  }
}
