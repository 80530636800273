import React from 'react';
import { useTranslation } from 'react-i18next';
import { MainLayout } from '../../../../../layouts';
import { PageBackButton } from '../../../../../sections';
import { Image } from '../../../../../components';
import { useSelector } from '../../../../../redux/store';

export const PhotoTips = () => {
  const { t } = useTranslation();
  const { photoTips } = useSelector((root) => root.patient);

  return (
    <MainLayout>
      <PageBackButton />
      <h1 className="mt-8">{t('patient.photoTips')}</h1>
      <div className="tips-section mt-8 grid gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:gap-15">
        {photoTips.map((item) => (
          <Image
            key={item.id}
            wrapperClass="max-lg:max-w-86 card2 m-auto"
            aspectRatio={2 / 3}
            spinner
            src={item.getCover()?.src}
          />
        ))}
      </div>
    </MainLayout>
  );
};
