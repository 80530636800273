import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Popup } from '../../components';
import { LockIcon } from '../../icons';
import { getAccount } from '../../redux/selectors';
import { useSelector } from '../../redux/store';
import { ScanModel } from '../../resources/models';
import { ScansService, ToastService } from '../../services';

export interface IUnlockScanButtonProps {
  className?: string;
  scan: ScanModel;
  onUnlock: VoidFunction;
}

export const UnlockScanButton: FC<IUnlockScanButtonProps> = ({ className, scan, onUnlock }) => {
  const { t } = useTranslation();

  const account = useSelector(getAccount);

  const [unlocking, setUnlocking] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const onUnlockScan = () => {
    setShowConfirmPopup(false);
    setUnlocking(true);
    ScansService.unlockAccess(scan.id)
      .then(() => {
        ToastService.success('toast.scanUnlocked');
        onUnlock();
      })
      .catch(() => {
        ToastService.error('toast.scanUnlockingFailed');
      })
      .finally(() => {
        setUnlocking(false);
      });
  };

  if (!scan || scan.isSubscribed) {
    return null;
  }

  return (
    <>
      <Button
        className={classNames('gap-3', className)}
        loading={unlocking}
        disabled={account.credits < 1}
        onClick={() => setShowConfirmPopup(true)}
      >
        <LockIcon /> {t('patientProfile.unlockAccessWithCredit')}
      </Button>

      {showConfirmPopup && (
        <Popup contentClass={classNames('text-typo1', className)} width="25rem" disableBackdrop>
          <h1>{t('patientProfile.unlockAccessConfirm.title')}</h1>
          <p className="mt-1">
            <Trans i18nKey="patientProfile.unlockAccessConfirm.message" />
          </p>
          <Button className="mt-4" fullWidth onClick={onUnlockScan}>
            {t('patientProfile.unlockAccessConfirm.title')}
          </Button>
          <Button className="mt-4" variant="outline" fullWidth onClick={() => setShowConfirmPopup(false)}>
            {t('common.cancel')}
          </Button>
        </Popup>
      )}
    </>
  );
};
