import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Scanner } from './Home';
import { PhotoScan } from './PhotoScan';
import { PhotoTips } from './PhotoTips';

export const ScannerRouting = () => {
  return (
    <Routes>
      <Route index element={<Scanner />} />
      <Route path="/:id/photos/:type" element={<PhotoScan />} />
      <Route path="/photo-tips" element={<PhotoTips />} />
    </Routes>
  );
};
