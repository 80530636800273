import { FC } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { TimeslotModel } from '../../resources/models';

export interface TimeslotSelectProps {
  className?: string;
  selectedDate: Date;
  selectedTimeslot?: TimeslotModel;
  timeslots: TimeslotModel[];
  onChange(timeslot: TimeslotModel): void;
}

export const TimeslotSelect: FC<TimeslotSelectProps> = ({
  className,
  selectedDate,
  selectedTimeslot,
  timeslots,
  onChange,
}) => {
  const now = moment();
  const date = moment(selectedDate).format('YYYY-MM-DD');

  const formatTime = (time: string) => {
    return moment(`${date} ${time}`).format('hh:mm A');
  };

  return (
    <div className={classNames('grid gap-6 md:grid-cols-2', className)}>
      {timeslots.map((slot) => {
        const active = slot === selectedTimeslot;
        const disabled = slot.disabled || moment(`${date} ${slot.to}`).isBefore(now);
        return (
          <div
            key={slot.from}
            className={classNames(
              'flex-center md:typo-sm flex-1 cursor-pointer select-none gap-2 rounded-md px-6 py-4 text-center uppercase',
              active
                ? 'card-primary shadow-button shadow-button1-hover/40'
                : 'card1 hover:shadow-button hover:shadow-button1-hover/25',
              disabled && 'pointer-events-none !border-gray-dark !text-gray-dark opacity-60',
            )}
            onClick={() => onChange(slot)}
          >
            <span>{formatTime(slot.from)}</span>
            <div className={classNames('flex-center flex-1 gap-1', active ? 'border-white' : 'border-inherit')}>
              <span className="h-1.5 w-1.5 shrink-0 rounded-full border border-inherit" />
              <div className="flex-1 border-t border-dashed border-inherit" />
              <span className="h-1.5 w-1.5 shrink-0 rounded-full border border-inherit" />
            </div>
            <span>{formatTime(slot.to)}</span>
          </div>
        );
      })}
    </div>
  );
};
