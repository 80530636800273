import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Image, ProgressBar } from '../../components';
import { IssueModel } from '../../resources/models';

export interface IIssueCardProps {
  className?: string;
  issue: IssueModel;
  onClick?: VoidFunction;
}

export const IssueCard: FC<IIssueCardProps> = ({ className, issue, onClick }) => {
  const { t } = useTranslation();
  const progress = issue.getProbability();

  return (
    <div className={classNames('card3 flex h-22 cursor-pointer items-center', className)} onClick={onClick}>
      <Image wrapperClass="!w-22 h-full shrink-0 bg-black" src={issue.getCover()?.src} spinner spinnerSize="sm" />
      <div className="flex-1 px-4 py-3">
        <div className="line-clamp-2 text-sm">{issue.getLocalizedContent()?.title || ''}</div>
        <div className="mt-1 flex items-center justify-between text-xs text-primary">
          <span>{t('patientProfile.imbalanceExpression')}</span>
          <span>{Math.floor(progress)}%</span>
        </div>
        <ProgressBar className="mt-1" theme="primary" percent={progress} size="sm" />
      </div>
      <i className="fa fa-angle-right pl-2 pr-3 text-typo1" />
    </div>
  );
};
