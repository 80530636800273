import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { SpinnerContainer } from './components';
import { AuthProvider } from './providers/AuthProvider';
import { DataLoader } from './providers/DataLoader';
import { AuthRouting } from './pages/Auth/routing';
import { DoctorRouting } from './pages/Doctor/routing';
import { getAccount } from './redux/selectors';
import { PublicRouting } from './pages/Public/routing';

function App() {
  const location = useLocation();
  const user = useSelector(getAccount);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, [location.pathname]);

  return (
    <div>
      <AuthProvider>
        {!user ? (
          <Routes>
            {PublicRouting}
            <Route path="/*" element={<AuthRouting />} />
          </Routes>
        ) : (
          <DataLoader>
            <DoctorRouting />
          </DataLoader>
        )}
      </AuthProvider>

      <SpinnerContainer />
      <ToastContainer />
    </div>
  );
}

export default App;
