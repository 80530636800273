import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { RunIcon } from '../../icons';
import { useSelector } from '../../redux/store';

export interface IExerciseTutorialCardProps {
  className?: string;
}

export const ExerciseTutorialCard: FC<IExerciseTutorialCardProps> = ({ className }) => {
  const { t } = useTranslation();
  const { profile } = useSelector((root) => root.patient);

  return (
    <Link
      className={classNames('card-primary block px-4 py-3', className)}
      to={`/patients/${profile.id}/exercises/tutorial`}
    >
      <div className="flex max-lg:items-center">
        <RunIcon className="!text-card4-title text-typo1 lg:mt-1" size={24} />
        <h6 className="!text-card4-title ml-3 mr-4 leading-normal">{t('patient.howWillMyProgramLookLike')}</h6>
        <i className="fa fa-angle-right !text-card4-icon ml-auto p-1" />
      </div>
      <p className="mt-2">{t('patient.watch30SecondVideo')}</p>
    </Link>
  );
};
