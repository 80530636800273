import React, { FC, ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import { Sidebar } from './Sidebar';

export interface MainLayoutProps {
  className?: string;
  layoutClass?: string;
  contentClass?: string;
  sidebarClass?: string;
  backgroundPattern?: boolean;
  sidebar?: ReactNode;
  sidebarOverlap?: boolean;
  children: ReactNode;
}

export const MainLayout: FC<MainLayoutProps> = ({
  className,
  layoutClass,
  contentClass,
  sidebarClass,
  backgroundPattern = true,
  sidebar,
  sidebarOverlap,
  children,
}) => {
  const bgPattern = useMemo(() => {
    if (!backgroundPattern) {
      return null;
    }
    return (
      <img
        className="pointer-events-none absolute right-0 top-0 opacity-50"
        src="/assets/images/layout/bg-pattern1.png"
        alt=""
      />
    );
  }, [backgroundPattern]);

  return (
    <div className={classNames('relative min-h-screen bg-body1 text-typo2', layoutClass)}>
      <Sidebar />
      <div
        className={classNames('flex min-h-screen items-stretch transition-all max-lg:flex-col sm:pl-59', contentClass)}
      >
        <div
          className={classNames(
            'bg-page-content relative bg-full bg-center px-6 py-8 max-lg:overflow-hidden lg:w-0 lg:flex-1',
            sidebarOverlap && sidebar && 'max-lg:!hidden',
            className,
          )}
        >
          {bgPattern}
          <div className="relative flex h-full w-full flex-col">{children}</div>
        </div>

        {sidebar && (
          <div
            className={classNames(
              'bg-right-sidebar overflow-auto bg-full bg-center p-6 max-lg:flex-1 lg:w-98 lg:bg-body2',
              !sidebarOverlap && 'bg-body2',
              sidebarClass,
            )}
          >
            {sidebar}
          </div>
        )}
      </div>
    </div>
  );
};
