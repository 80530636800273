import React, { FC } from 'react';
import { Icon } from './Icon';
import { IIconProps } from './types';

export const WebcamIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M4 20.5C3.45 20.5 2.97917 20.3042 2.5875 19.9125C2.19583 19.5208 2 19.05 2 18.5V6.5C2 5.95 2.19583 5.47917 2.5875 5.0875C2.97917 4.69583 3.45 4.5 4 4.5H16C16.55 4.5 17.0208 4.69583 17.4125 5.0875C17.8042 5.47917 18 5.95 18 6.5V11L22 7V18L18 14V18.5C18 19.05 17.8042 19.5208 17.4125 19.9125C17.0208 20.3042 16.55 20.5 16 20.5H4ZM4 18.5H16V6.5H4V18.5Z"
        />
      </svg>
    )}
  </Icon>
);
