import React, { forwardRef, ReactNode } from 'react';
import classNames from 'classnames';
import { Radio } from '../Radio';
import { Image } from '../../Image';
import { GENDER } from '../../../resources/enums';

export interface GenderSelectProps {
  className?: string;
  label?: ReactNode;
  value?: GENDER;
  onChange(value: GENDER): void;
}

const genderOptions = [
  {
    text: 'common.male',
    value: GENDER.MALE,
    image: '/assets/images/patient/ic_avatar_male.png',
    activeImage: '/assets/images/patient/ic_avatar_male_selected.png',
  },
  {
    text: 'common.female',
    value: GENDER.FEMALE,
    image: '/assets/images/patient/ic_avatar_female.png',
    activeImage: '/assets/images/patient/ic_avatar_female_selected.png',
  },
];

export const GenderSelect = forwardRef<any, GenderSelectProps>(({ className, label, value, onChange }, ref) => {
  return (
    <div ref={ref} className={className}>
      {label && <label className="mb-1 block text-15p font-semibold text-typo1">{label}</label>}
      <div className="grid grid-cols-2 gap-4">
        {genderOptions.map((option) => {
          const isActive = option.value === value;
          return (
            <div
              key={option.value}
              className={classNames(
                'flex-center relative h-58 cursor-pointer overflow-hidden',
                'rounded-10p border border-input-border bg-input-bg',
              )}
              onClick={() => onChange(option.value)}
            >
              <Image src={isActive ? option.activeImage : option.image} />

              <Radio className="absolute bottom-3 left-3" checked={isActive} />
            </div>
          );
        })}
      </div>
    </div>
  );
});
