import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from '../../redux/store';

export interface PatientProfileViewProps {
  className?: string;
  editable?: boolean;
  onEdit?: VoidFunction;
}

export const PatientProfileView: FC<PatientProfileViewProps> = ({ className, editable, onEdit }) => {
  const { t } = useTranslation();
  const { profile } = useSelector((state) => state.patient);

  return (
    <div className={className}>
      <div className="flex items-center justify-between gap-4 pl-2">
        <h3 className="text-dark">{t('common.details')}</h3>
        {editable && (
          <span className="typo-link font-medium uppercase" onClick={onEdit}>
            {t('common.edit')}
          </span>
        )}
      </div>
      <div className="typo-sm mt-4 rounded-10p bg-white">
        <div className="flex items-center justify-between gap-2 px-4 py-3">
          <span>{t('common.name')}</span>
          <span className="text-gray-dark">{profile?.name ?? ''}</span>
        </div>
        <hr className="ml-4 border-gray" />
        <div className="flex items-center justify-between gap-2 px-4 py-3">
          <span>{t('common.email')}</span>
          <span className="text-gray-dark">{profile?.email ?? ''}</span>
        </div>
        <hr className="ml-4 border-gray" />
        <div className="flex items-center justify-between gap-2 px-4 py-3">
          <span>{t('patientList.form.birthYear.label')}</span>
          <span className="text-gray-dark">{profile?.birth ?? ''}</span>
        </div>
        <hr className="ml-4 border-gray" />
        <div className="flex items-center justify-between gap-2 px-4 py-3">
          <span>{t('common.sexOfBirth')}</span>
          {profile.gender && <span className="text-gray-dark">{t(`common.${profile.gender.toLowerCase()}`)}</span>}
        </div>
      </div>
    </div>
  );
};
