import { MouseEventHandler } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { getHoursFromTimeString } from './format.helpers';
import { TimeslotModel } from '../../resources/models';

export const cn = (...args: classNames.ArgumentArray) => {
  return twMerge(classNames(args));
};

export const ignoreEvent: MouseEventHandler = (e) => {
  // e.preventDefault();
  e.stopPropagation();
};

export const isToday = (date: string | number | Date) => {
  if (!date) {
    return false;
  }

  return moment(date).isSame(moment(), 'day');
};

export const isInDays = (date: string | number | Date, days: number) => {
  if (!date) {
    return false;
  }

  return moment(date).isSameOrAfter(moment().subtract(days, 'days'), 'day');
};

export const checkTimeValidity = (slot: TimeslotModel, slots: TimeslotModel[]) => {
  if (!slot.from || !slot.to) {
    return false;
  }
  const from = getHoursFromTimeString(slot.from);
  const to = getHoursFromTimeString(slot.to);
  if (from >= to) {
    return false;
  }

  return slots.every((item) => {
    if (!item.from || !item.to) {
      return true;
    }
    return from >= getHoursFromTimeString(item.to) || to <= getHoursFromTimeString(item.from);
  });
};
