import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Image } from '../../components';
import { LockIcon } from '../../icons';
import { useSelector } from '../../redux/store';
import { EXERCISE_TYPES } from '../../resources/enums';
import { ExerciseModel } from '../../resources/models';

export interface IFirstAidKitsProps {
  className?: string;
}

export const FirstAidKits: FC<IFirstAidKitsProps> = ({ className }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { profile, firstAidKits } = useSelector((root) => root.patient);

  const onViewDetail = (exercise: ExerciseModel) => {
    if (exercise) {
      navigate(`/patients/${profile.id}/exercises/first-aids/${exercise.type}`);
    }
  };

  return (
    <div className={classNames('max-w-100', className)}>
      <h2>{t('patient.yourPersonalFirstAidKit')}</h2>
      <div className="mt-4 grid grid-cols-2 gap-4">
        {EXERCISE_TYPES.map((item, i) => {
          const exercise = firstAidKits.find((e) => e.type === item.value);
          return (
            <div
              key={i}
              className={classNames(
                'relative overflow-hidden rounded-lg border border-primary-dark bg-primary text-card-primary-text',
                exercise && 'cursor-pointer',
              )}
              onClick={() => onViewDetail(exercise)}
            >
              <Image wrapperClass="bg-black" src={item.image} aspectRatio={1} spinner spinnerSize="sm" />
              <p className="px-4 py-2">{t(`patient.firstAids.${item.value}`)}</p>
              {!exercise && <LockIcon className="absolute right-3.5 top-3 text-secondary-light" size={16} />}
            </div>
          );
        })}
      </div>
    </div>
  );
};
