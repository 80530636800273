import React, { FC, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Popup } from '../../components';
import { ScheduleCalendar } from '../ScheduleCalendar';
import { TimeslotSelect } from '../TimeslotSelect';
import { CreateScheduleResponseDto } from '../../resources/dtos';
import { TimeslotModel, UserModel } from '../../resources/models';
import { ConsultationsService, ToastService } from '../../services';

export interface ScheduleConsultationPopupProps {
  className?: string;
  user: UserModel;
  onClose(result?: CreateScheduleResponseDto): void;
}

export const ScheduleConsultationPopup: FC<ScheduleConsultationPopupProps> = ({ className, user, onClose }) => {
  const { t } = useTranslation();

  const [date, setDate] = useState(new Date());
  const [timeslots, setTimeslots] = useState<Record<string, TimeslotModel[]>>({});
  const [selectedTimeslot, setSelectedTimeslot] = useState<TimeslotModel>();

  const availableTimeslots = useMemo(() => timeslots[moment(date).format('YYYY-MM-DD')] ?? [], [timeslots, date]);

  useEffect(() => {
    setSelectedTimeslot(undefined);
  }, [date]);

  const onSubmit = () => {
    if (!selectedTimeslot) {
      return;
    }

    const scheduleDate = moment(date).format('YYYY-MM-DD');
    ConsultationsService.createSchedule({
      userId: user.id,
      title: 'New Consultant',
      description: `A consultant schedule on ${scheduleDate} from ${selectedTimeslot.from} to ${selectedTimeslot.to}`,
      date: `${scheduleDate}T00:00:00.000Z`,
      start_time: selectedTimeslot.from,
      end_time: selectedTimeslot.to,
      is_record: true,
      payment_required: false,
    })
      .then((result) => {
        ToastService.success('toast.consultationScheduleCreated');
        onClose(result);
      })
      .catch((err) => ToastService.showHttpError(err, 'toast.creatingConsultationSchedule'));
  };

  return (
    <Popup contentClass={classNames('flex flex-col text-typo1', className)} width="60rem" height="90vh" disableBackdrop>
      <div className="mb-4 flex items-center justify-between gap-4">
        <h1>{t('appointments.schedule.title')}</h1>
        <i className="fa fa-times cursor-pointer text-2xl" onClick={() => onClose()} />
      </div>

      <ScheduleCalendar className="mt-8" selectedDate={date} onChange={setDate} onLoadTimeslots={setTimeslots} />

      {date && availableTimeslots.length > 0 && (
        <>
          <div className="mb-4 mt-8 text-center uppercase text-gray-dark">{t('appointments.schedule.selectTime')}</div>
          <div className="mb-8 overflow-auto pb-2">
            <TimeslotSelect
              className="mx-auto w-full max-w-218 px-8"
              selectedDate={date}
              selectedTimeslot={selectedTimeslot}
              timeslots={availableTimeslots}
              onChange={setSelectedTimeslot}
            />
          </div>
        </>
      )}

      <div className="flex-center mt-auto px-8">
        <Button className="w-full md:w-86" disabled={!selectedTimeslot} onClick={onSubmit}>
          {t('common.confirm')}
        </Button>
      </div>
    </Popup>
  );
};
