import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from '../../components';

export interface MarketProps {
  className?: string;
  onBack: () => void;
}

export const Market: FC<MarketProps> = ({ className, onBack }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('flex h-full flex-col gap-6', className)}>
      <div className="flex-center relative">
        <div className="typo-link absolute left-0 p-2 font-semibold text-primary" onClick={onBack}>
          <i className="fa fa-angle-left" />
          <span className="ml-1.5">{t('common.back')}</span>
        </div>
        <h2 className="text-center">{t('common.market')}</h2>
      </div>

      <div className="card3 flex flex-col items-center px-4 py-6">
        <img className="max-w-55" src="/assets/images/market/book.png" alt="" />
        <div className="mt-6 flex w-full flex-col gap-3">
          <h2 className="line-clamp-2 font-bold">
            Das Schmerzfrei-Geheimnis: Schmerzen ganzheitlich verstehen und für immer loswerden | Das 3-Minuten-Programm
            für den Alltag
          </h2>
          <div className="text-input-placeholder">Alexander Srokovskyi</div>
          <div className="typo-h1 text-center font-bold lowercase">{t('common.from')} 10.95 €</div>
        </div>
        <a href="https://amzn.eu/d/0dwPdPkh" target="_blank" rel="noreferrer">
          <Button className="mt-4">{t('common.buy')}</Button>
        </a>
      </div>
    </div>
  );
};
