import React, { FC } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { useSelector } from '../../redux/store';

export interface TrainLevelProps {
  className?: string;
}

export const TrainLevel: FC<TrainLevelProps> = ({ className }) => {
  const { t } = useTranslation();

  const { trainStatus } = useSelector((state) => state.patient);
  const isNewLevel = trainStatus?.isNewLevel;
  const todayLevel = trainStatus?.todayLevel || 1;
  const trainLevel = trainStatus?.trainLevel || 3;
  const progress = isNewLevel ? 100 : Math.round(((trainLevel % 3) / 3) * 100);

  return (
    <div className={classNames('relative flex items-center', className)}>
      <div className="progress-section flex-center mr-8 flex-col">
        <CircularProgressbar
          className="w-22"
          value={progress}
          text={trainStatus ? `${progress}%` : '?'}
          strokeWidth={6}
          styles={buildStyles({
            rotation: 0.5,
            strokeLinecap: 'round',
            textSize: '1.25rem',
          })}
        />
        {trainStatus && <div className="typo-xs mt-2 text-center text-primary">{t('patient.trainingProcess')}</div>}
      </div>

      {trainStatus ? (
        <div>
          <h1>{t('common.level#', { level: todayLevel })}</h1>
          {isNewLevel ? (
            <p className="mt-2 max-w-86 text-xs leading-loose text-gray-dark">
              <b>{t('common.congratulations')}</b>&nbsp;
              <Trans i18nKey="patient.youFinishedLevel#" values={{ level: todayLevel }}>
                You are now at <span className="font-semibold text-primary">Level</span>
              </Trans>
              !
              <br />
              {t('patient.tomorrowYouWillDiscoverNewExercises')}
            </p>
          ) : (
            <p className="mt-2 max-w-86 text-xs leading-loose text-gray-dark">
              {t('patient.afterCompletingAllExercisesLevelUp')}
            </p>
          )}
        </div>
      ) : (
        <div>
          <h1>{t('patient.noPostureScoreYet')}</h1>
          <div className="mt-2">
            <span className="typo-button cursor-pointer text-primary">{t('common.learnMore')}</span>
          </div>
        </div>
      )}
    </div>
  );
};
