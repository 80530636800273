import moment from 'moment';
import { ContentModel } from '../../resources/models';
import i18n, { allLangs } from '../../locales/config';
import { LANGUAGE, PHOTO_TYPE, PHOTO_TYPES } from '../../resources/enums';

export const getCurrentLanguage = () => {
  return (i18n.language || 'en') as LANGUAGE;
};

export const getLanguageName = (lang?: string) => {
  const option = allLangs.find((item) => item.value === lang);
  return option?.name || 'English';
};

export const setCurrentLanguage = (lang?: string) => {
  const option = allLangs.find((item) => item.value === lang);
  if (option) {
    moment.locale(option.value);
    return i18n.changeLanguage(option.value);
  }
};

export const getLocaleContent = (contents: Partial<ContentModel>[]): Partial<ContentModel> | undefined => {
  const lang = getCurrentLanguage();
  return contents.find((item) => item.language === lang) || contents[0];
};

export const getEnumText = (options, value) => {
  const option = options.find((item) => item.value === value);
  return option?.text || value;
};

export const getPhotoTypeText = (value: PHOTO_TYPE) => getEnumText(PHOTO_TYPES, value);
