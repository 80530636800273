import { HttpService } from './http.service';
import {
  CreateChatRoomResponseDto,
  CreateDoctorUnavailabilityDto,
  CreateScheduleDto,
  CreateScheduleResponseDto,
  SetDateAvailabilitiesDto,
  SetWeeklyAvailabilityDto,
} from '../resources/dtos';
import { DoctorAvailabilityModel, DoctorUnavailabilityModel, ScheduleModel } from '../resources/models';

export class ConsultationsService {
  static getMyAvailabilities(weekly = true, hasSpinner = true): Promise<DoctorAvailabilityModel[]> {
    return HttpService.get(`/consultation/doctor-availability`, { is_weekly_hours: weekly }, hasSpinner).then(
      (result) => result.data.map((item) => new DoctorAvailabilityModel(item)),
    );
  }

  static setMyWeeklyAvailabilities(
    availableTimes: SetWeeklyAvailabilityDto[],
    hasSpinner = true,
  ): Promise<DoctorAvailabilityModel[]> {
    return HttpService.post(`/consultation/doctor-availability/multiple`, { availableTimes }, hasSpinner).then(
      (result) => result.data.map((item) => new DoctorAvailabilityModel(item)),
    );
  }

  static setMyAvailabilityForSpecificDay(
    data: SetDateAvailabilitiesDto,
    hasSpinner = true,
  ): Promise<DoctorAvailabilityModel> {
    return HttpService.post(`/consultation/doctor-availability/specific`, data, hasSpinner).then(
      (result) => new DoctorAvailabilityModel(result.data),
    );
  }

  static deleteMyAvailability(id: string, hasSpinner = true): Promise<void> {
    return HttpService.delete(`/consultation/doctor-availability/${id}`, null, hasSpinner);
  }

  static getMyUnavailabilities(doctorId: string, hasSpinner = true): Promise<DoctorUnavailabilityModel[]> {
    return HttpService.get(`/consultation/doctor-unavailability`, { doctor_id: doctorId }, hasSpinner).then((result) =>
      result.data.result.map((item) => new DoctorUnavailabilityModel(item)),
    );
  }

  static createMyUnavailability(
    data: CreateDoctorUnavailabilityDto,
    hasSpinner = true,
  ): Promise<DoctorUnavailabilityModel> {
    return HttpService.post(`/consultation/doctor-unavailability`, data, hasSpinner).then(
      (result) => new DoctorUnavailabilityModel(result.data),
    );
  }

  static deleteMyUnavailability(id: string, hasSpinner = true): Promise<void> {
    return HttpService.delete(`/consultation/doctor-unavailability/${id}`, null, hasSpinner);
  }

  static getScheduleTimeslots(year: number, month: number, hasSpinner = true): Promise<DoctorAvailabilityModel[]> {
    return HttpService.get(`/consultation/schedule/doctor/timeslots`, { year, month }, hasSpinner).then((result) =>
      result.data.map((item) => new DoctorAvailabilityModel(item)),
    );
  }

  static createSchedule(data: CreateScheduleDto, hasSpinner = true): Promise<CreateScheduleResponseDto> {
    return HttpService.post(`/consultation/schedule`, data, hasSpinner).then((result) => result.data);
  }

  static getScheduleList(type?: 'upcomming' | 'previous', hasSpinner = true): Promise<ScheduleModel[]> {
    return HttpService.get(`/consultation/schedule`, { type }, hasSpinner).then((result) =>
      result.data.result.map((item) => new ScheduleModel(item)),
    );
  }

  static getScheduleById(id: string, hasSpinner = true): Promise<ScheduleModel> {
    return HttpService.get(`/consultation/schedule/${id}`, {}, hasSpinner).then(
      (result) => new ScheduleModel(result.data),
    );
  }

  static cancelConsultation(id: string, hasSpinner = true): Promise<void> {
    return HttpService.patch(`/consultation/schedule/${id}/cancel`, {}, hasSpinner);
  }

  static createRoom(id: string, hasSpinner = true): Promise<CreateChatRoomResponseDto> {
    return HttpService.get(`/consultation/schedule/create-room/${id}`, {}, hasSpinner);
  }

  static completeConsultation(id: string, hasSpinner = true): Promise<CreateChatRoomResponseDto> {
    return HttpService.patch(`/consultation/schedule/complete`, { schedule_id: id }, hasSpinner);
  }
}
