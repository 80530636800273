import { createAsyncThunk } from '@reduxjs/toolkit';
import { setAccount, setProfileLoadingStatus } from '../reducers/auth.reducer';
import { LOADING_STATUS } from '../../resources/enums';
import { DoctorModel } from '../../resources/models';
import { AuthService, ToastService } from '../../services';
import { RootState } from '../store';
import { setCurrentLanguage } from '../../utils/helpers';

export const fetchAccountProfile = createAsyncThunk<
  DoctorModel,
  {
    force?: boolean;
    showSpinner?: boolean;
    showError?: boolean;
  }
>(
  'patient/fetchAccountProfile',
  async (arg, api) => {
    api.dispatch(setProfileLoadingStatus(LOADING_STATUS.PENDING));
    return AuthService.getProfile(arg.showSpinner)
      .then(async (doctor) => {
        api.dispatch(setAccount(doctor));
        api.dispatch(setProfileLoadingStatus(LOADING_STATUS.SUCCESS));

        setCurrentLanguage(doctor.language || 'en');
        return doctor;
      })
      .catch((err) => {
        if (arg.showError ?? true) {
          ToastService.showHttpError(err, 'toast.loadingAccountProfileFailed');
        }
        api.dispatch(setProfileLoadingStatus(LOADING_STATUS.FAILED));
        throw err;
      });
  },
  {
    condition(arg, api) {
      const root = api.getState() as RootState;
      const { profileLoadingStatus } = root.auth;
      return arg.force || profileLoadingStatus === LOADING_STATUS.NONE;
    },
  },
);
