import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { useSelector } from '../../redux/store';
import { useNavigate } from 'react-router-dom';

export interface PatientLevelCardProps {
  className?: string;
  editable?: boolean;
  showPostureScore?: boolean;
  linkToHome?: boolean;
  onEdit?: VoidFunction;
}

export const PatientLevelCard: FC<PatientLevelCardProps> = ({
  className,
  editable,
  showPostureScore,
  linkToHome,
  onEdit,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { profile, scan } = useSelector((state) => state.patient);

  const postureScore = scan ? Math.round(scan.healthScore * 100) : 0;

  const onClick = () => {
    if (linkToHome) {
      navigate(`/patients/${profile.id}`);
    }
  };

  return (
    <div
      className={classNames(
        'card1 flex items-center gap-4 px-4 py-2 shadow-card',
        linkToHome && 'cursor-pointer',
        className,
      )}
      onClick={onClick}
    >
      <div className="w-full">
        <div className="typo-sm font-medium text-gray-dark">{t('patientProfile.patientName')}</div>
        <div className="mt-1 flex items-end justify-between gap-2">
          <h1>{profile?.name || ''}</h1>
          {editable && (
            <span className="typo-link font-semibold uppercase" onClick={onEdit}>
              {t('common.change')}
            </span>
          )}
        </div>
      </div>

      {showPostureScore && scan && (
        <CircularProgressbar
          className="ml-auto w-20"
          value={postureScore}
          text={`${postureScore}%`}
          strokeWidth={8}
          styles={buildStyles({
            strokeLinecap: 'round',
          })}
        />
      )}
    </div>
  );
};
