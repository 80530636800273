import React, { FC } from 'react';
import classNames from 'classnames';

export interface IProgressBarProps {
  className?: string;
  theme?: 'primary' | 'gradient';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  rounded?: boolean;
  percent: number;
}

export const ProgressBar: FC<IProgressBarProps> = ({
  className,
  theme = 'primary',
  size = 'md',
  rounded = true,
  percent,
}) => {
  const progressBarClass = classNames(
    'relative w-full transition-all',
    theme === 'primary' && 'bg-progress-trail text-typo1 overflow-hidden',
    theme === 'gradient' && 'gradient-progress border border-secondary-dark',
    size === 'sm' && 'h-1',
    size === 'md' && 'h-1.5',
    rounded && 'rounded-xl',
    className,
  );

  const progressClass = classNames(
    'h-full transition-all',
    theme === 'primary' && 'bg-progress-path',
    rounded && 'rounded-xl',
  );

  return (
    <div className={progressBarClass}>
      {theme === 'gradient' ? (
        <div className="relative mx-[1px] flex h-full items-center">
          <div className="absolute h-2.5 rounded border border-black" style={{ left: `${percent}%` }} />
        </div>
      ) : (
        <div className={progressClass} style={{ width: `${percent}%` }} />
      )}
    </div>
  );
};
