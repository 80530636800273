import React, { FC } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Popup } from '../../components';
import { useSelector } from '../../redux/store';
import { getAccount } from '../../redux/selectors';
import { DOCTOR_ROLE } from '../../resources/enums';

export interface ScanCompletedPopupProps {
  className?: string;
  scanNumber: number;
  onClose: VoidFunction;
}

export const ScanCompletedPopup: FC<ScanCompletedPopupProps> = ({ className, scanNumber, onClose }) => {
  const { t } = useTranslation();

  const account = useSelector(getAccount);

  return (
    <Popup contentClass={classNames('text-typo1', className)} width="25rem" disableBackdrop>
      <h1>{t('common.congratulations')}</h1>
      <p className="mt-1">
        <Trans
          i18nKey="patientProfile.scanCompletedMessage"
          values={{
            number: scanNumber,
            user: (account.doctorRole === DOCTOR_ROLE.DOCTOR ? t('common.patient') : t('common.client')).toLowerCase(),
          }}
        />
      </p>
      <Button className="mt-4" fullWidth onClick={onClose}>
        {t('patientProfile.viewResult')}
      </Button>
    </Popup>
  );
};
