import { INFO_SIDE } from '../enums';

export class ScanExerciseModel {
  is_required?: boolean;
  is_visible?: boolean;
  side?: INFO_SIDE;
  weight?: number;
  edge_weight?: number;
  rep_r?: number;
  rep_l?: number;
  rep_both?: number;
  time_l_one?: number;
  time_r_one?: number;
  time_both_one?: number;
  duration_r?: number;
  duration_l?: number;
  duration_both?: number;
  doneAt?: string;

  constructor(init?: Partial<ScanExerciseModel>) {
    const data = {
      id: null,
      ...init,
    };

    this.is_required = data.is_required;
    this.is_visible = data.is_visible;
    this.side = data.side;
    this.weight = data.weight;
    this.edge_weight = data.edge_weight;
    this.rep_r = data.rep_r;
    this.rep_l = data.rep_l;
    this.rep_both = data.rep_both;
    this.time_l_one = data.time_l_one;
    this.time_r_one = data.time_r_one;
    this.time_both_one = data.time_both_one;
    this.duration_r = data.duration_r;
    this.duration_l = data.duration_l;
    this.duration_both = data.duration_both;
    this.doneAt = data.doneAt;
  }

  getLeftActionTime() {
    return this.time_l_one || this.time_both_one || 0;
  }

  getRightActionTime() {
    return this.time_r_one || this.time_both_one || 0;
  }

  getLeftRepeatCount() {
    return this.rep_l || this.rep_both || 0;
  }

  getRightRepeatCount() {
    return this.rep_r || this.rep_both || 0;
  }

  getLeftTotalTime() {
    return this.getLeftActionTime() * this.getLeftRepeatCount();
  }

  getRightTotalTime() {
    return this.getRightActionTime() * this.getRightRepeatCount();
  }
}
