import React, { ImgHTMLAttributes, FC, ReactNode, useState, useEffect } from 'react';
import classNames from 'classnames';
import { LoadingSpinner, SpinnerType } from '../LoadingSpinner';

export interface IImageProps extends Omit<ImgHTMLAttributes<HTMLImageElement>, 'placeholder' | 'loading'> {
  wrapperClass?: string;
  placeholderClass?: string;
  spinner?: boolean;
  spinnerSize?: SpinnerType;
  hideWhenLoading?: boolean;
  placeholder?: string | ReactNode;
  aspectRatio?: number;
  loading?: boolean;
}

export const Image: FC<IImageProps> = ({
  className,
  wrapperClass,
  placeholderClass,
  spinner,
  spinnerSize = 'md',
  hideWhenLoading,
  src,
  alt = '',
  placeholder,
  aspectRatio,
  loading,
  onClick,
  ...imageProps
}) => {
  const [loaded, setLoaded] = useState<boolean>();

  useEffect(() => {
    setLoaded(src ? undefined : false);
  }, [src]);

  return (
    <div
      className={classNames(
        'flex-center relative overflow-hidden',
        hideWhenLoading && 'hide-when-loading',
        !loaded && 'loading',
        loaded === false && 'load-error',
        wrapperClass,
      )}
      style={{ aspectRatio }}
      onClick={onClick}
    >
      {src && (
        <img
          className={classNames(
            'max-h-full max-w-full',
            !loaded && hideWhenLoading && 'pointer-events-none',
            className,
          )}
          src={src}
          alt={alt}
          {...imageProps}
          onLoad={() => setLoaded(true)}
          onError={() => setLoaded(false)}
        />
      )}

      {!loading && !loaded && (
        <div className={classNames('absolute', placeholderClass)}>
          {placeholder ? typeof placeholder === 'string' ? <img src={placeholder} alt={alt} /> : placeholder : alt}
        </div>
      )}

      {(loading || loaded === undefined) && spinner && (
        <div className="absolute">
          <LoadingSpinner size={spinnerSize} />
        </div>
      )}
    </div>
  );
};
