import { HttpService } from './http.service';
import { UserModel } from '../resources/models';
import { CreatePatientDto, PaginatedResultDto, SearchPatientsDto, UpdatePatientDto } from '../resources/dtos';
import { formatPaginatedResult, trimObject } from '../utils/helpers';

export class PatientsService {
  static search(query?: SearchPatientsDto, hasSpinner = true): Promise<PaginatedResultDto<UserModel>> {
    return HttpService.get(`/users`, trimObject(query), hasSpinner).then((result) =>
      formatPaginatedResult<UserModel>(UserModel, result.data),
    );
  }

  static find(id: string, hasSpinner = true): Promise<UserModel> {
    return HttpService.get(`/users/${id}`, {}, hasSpinner).then((result) => new UserModel(result.data));
  }

  static create(data: CreatePatientDto, hasSpinner = true): Promise<UserModel> {
    return HttpService.post(`/users`, data, hasSpinner).then((res) => new UserModel(res.data));
  }

  static update(id: string, data: UpdatePatientDto, hasSpinner = true): Promise<UserModel> {
    return HttpService.patch(`/users/${id}`, data, hasSpinner).then((res) => new UserModel(res.data));
  }

  static delete(id: string, hasSpinner = true): Promise<void> {
    return HttpService.delete(`/users/${id}`, {}, hasSpinner);
  }

  static sendAgreementsEmailToPatient(patientId: string, hasSpinner = true): Promise<void> {
    return HttpService.post(`/users/${patientId}/send-policy-and-agreements`, {}, hasSpinner);
  }
}
