import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PhotoScanner } from '../../../../../components';
import { MainLayout } from '../../../../../layouts';
import { PageBackButton } from '../../../../../sections';
import { useSelector } from '../../../../../redux/store';
import { PHOTO_TYPE, PHOTO_TYPE_VALUES } from '../../../../../resources/enums';
import { IPhoto } from '../../../../../resources/interfaces';
import { ScanModel } from '../../../../../resources/models';
import { ScansService, ToastService } from '../../../../../services';
import { getPhotoTypeText } from '../../../../../utils/helpers';

export const PhotoScan = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id, type } = useParams<{ id: string; type: PHOTO_TYPE }>();
  const { profile } = useSelector((store) => store.patient);
  const [scan, setScan] = useState<ScanModel>();

  useEffect(() => {
    if (!id) {
      navigate(`/patients/${profile.id}/scanner`, { replace: true });
      return;
    }

    ScansService.find(id)
      .then((data) => {
        setScan(data);
      })
      .catch(() => {
        ToastService.error('toast.loadingScanFailed');
        navigate(`/patients/${profile.id}/scanner`, { replace: true });
      });
  }, [id, profile.id, navigate]);

  const onUpload = (photo: IPhoto) => {
    if (!scan) {
      return;
    }

    ScansService.uploadPhoto(scan.id, type, photo.blob)
      .then(() => {
        ToastService.success('toast.photoUploaded');

        const index = PHOTO_TYPE_VALUES.indexOf(type);
        if (index < 2) {
          navigate(`/patients/${profile.id}/scanner/${scan.id}/photos/${PHOTO_TYPE_VALUES[index + 1]}`);
        } else {
          navigate(`/patients/${profile.id}/scanner`);
        }
      })
      .catch((err) => {
        ToastService.showHttpError(err, 'toast.uploadingPhotoFailed');
      });
  };

  const sidebar = (
    <>
      <h2>{t('patient.photoTips')}</h2>
      <div className="m-auto">
        <img
          className="max-w-full"
          src={`/assets/images/photo-tips/${type}-${profile.gender?.toLowerCase()}.png`}
          alt=""
        />
      </div>
    </>
  );

  return (
    <MainLayout className="!p-0" sidebar={sidebar} sidebarClass="flex flex-col !bg-black/40">
      <div className="relative h-screen">
        <PhotoScanner
          key={`${id}-${type}`}
          className="h-full w-full"
          header={
            <>
              <PageBackButton className="text-inherit" />
              <h1 className="ml-auto text-inherit">{t(getPhotoTypeText(type))}</h1>
            </>
          }
          onTakePhoto={onUpload}
        />
      </div>
    </MainLayout>
  );
};
