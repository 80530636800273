import React from 'react';
import axios from 'axios';
import { toast, ToastOptions } from 'react-toastify';
import i18n from '../locales/config';

export class ToastService {
  static info(message: string | JSX.Element) {
    ToastService.show(message, { type: 'info' });
  }

  static success(message: string | JSX.Element) {
    ToastService.show(message, { type: 'success' });
  }

  static warning(message: string | JSX.Element) {
    ToastService.show(message, { type: 'warning' });
  }

  static error(message: string | JSX.Element) {
    ToastService.show(message, { type: 'error' });
  }

  static show(message: string | JSX.Element, options: ToastOptions) {
    if (message && typeof message === 'string') {
      message = i18n.t(message);
    }
    toast(message, {
      ...options,
    });
  }

  static showHttpError(err, defaultMessage: string | JSX.Element) {
    if (!axios.isAxiosError(err)) {
      ToastService.error(err?.message || defaultMessage);
      return;
    }

    const response = err?.response;
    const data = response?.data;

    if (!response) {
      ToastService.error('Network Error!');
      return;
    }
    if (!data) {
      ToastService.error(`${response.status} error - ${response.statusText}`);
      return;
    }
    if (data.message) {
      ToastService.error(data.message.toString());
      return;
    }

    if (response.status === 400 && !data.message) {
      const message = (
        <div>
          <div className="font-bold">Bad Request!</div>
          {Object.entries(data).map(([key, value]) => (
            <div className="flex items-start">
              {key !== 'non_field_errors' && <span className="mr-1 shrink-0 font-semibold">{key}:</span>}
              <div>
                {Array.isArray(value) ? (
                  <>
                    {value.map((msg) => (
                      <div>{msg}</div>
                    ))}
                  </>
                ) : (
                  <>{value}</>
                )}
              </div>
            </div>
          ))}
        </div>
      );
      ToastService.error(message);
      return;
    }

    ToastService.error(defaultMessage);
  }
}
