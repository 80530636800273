import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { LightBulbIcon } from '../../icons';
import { useSelector } from '../../redux/store';

export interface IPhotoTipsCardProps {
  className?: string;
}

export const PhotoTipsCard: FC<IPhotoTipsCardProps> = ({ className }) => {
  const { t } = useTranslation();
  const { profile } = useSelector((root) => root.patient);

  return (
    <Link
      className={classNames('card-primary block px-6 py-3', className)}
      to={`/patients/${profile.id}/scanner/photo-tips`}
    >
      <div className="flex items-center">
        <LightBulbIcon className="text-typo1" size={24} />
        <h6 className="ml-2 mr-4">{t('patient.photoTips')}</h6>
        <i className="fa fa-angle-right ml-auto p-1" />
      </div>
      <p className="mt-2">
        <Trans i18nKey="patient.explorePhotoTips" />
      </p>
    </Link>
  );
};
