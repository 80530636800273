import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export interface ScanningLoaderProps {
  className?: string;
}

export const ScanningLoader: FC<ScanningLoaderProps> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('flex flex-col items-center justify-evenly bg-[#061115] text-center', className)}>
      <h1 className="text-white">{t('patient.scanningInProgress')}</h1>
      <div className="w-full max-w-86">
        <img className="mt-8 w-full" src="/assets/images/patient/scanning.gif" alt="" />
      </div>
    </div>
  );
};
