import React, { FC } from 'react';
import { Icon } from './Icon';
import { IIconProps } from './types';

export const CameraIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M19.1888 4H18.6792L16.4882 1.168C16.2058 0.805806 15.8445 0.512417 15.4315 0.310054C15.0185 0.107691 14.5648 0.00166304 14.1046 0H10.2282C9.76803 0.00166304 9.31427 0.107691 8.90129 0.310054C8.48832 0.512417 8.12696 0.805806 7.84458 1.168L5.65358 4H5.14396C3.81411 4.00159 2.53919 4.52888 1.59885 5.46622C0.658507 6.40356 0.129523 7.67441 0.12793 9V19C0.129523 20.3256 0.658507 21.5964 1.59885 22.5338C2.53919 23.4711 3.81411 23.9984 5.14396 24H19.1888C20.5187 23.9984 21.7936 23.4711 22.7339 22.5338C23.6743 21.5964 24.2033 20.3256 24.2049 19V9C24.2033 7.67441 23.6743 6.40356 22.7339 5.46622C21.7936 4.52888 20.5187 4.00159 19.1888 4ZM9.43366 2.39C9.52763 2.26905 9.64804 2.17106 9.78572 2.10348C9.9234 2.0359 10.0747 2.00051 10.2282 2H14.1046C14.258 2.00066 14.4093 2.03611 14.547 2.10368C14.6846 2.17125 14.8051 2.26916 14.8991 2.39L16.1451 4H8.18768L9.43366 2.39ZM22.1984 19C22.1984 19.7956 21.8814 20.5587 21.3169 21.1213C20.7525 21.6839 19.987 22 19.1888 22H5.14396C4.34576 22 3.58025 21.6839 3.01584 21.1213C2.45142 20.5587 2.13434 19.7956 2.13434 19V9C2.13434 8.20435 2.45142 7.44129 3.01584 6.87868C3.58025 6.31607 4.34576 6 5.14396 6H19.1888C19.987 6 20.7525 6.31607 21.3169 6.87868C21.8814 7.44129 22.1984 8.20435 22.1984 9V19Z"
        />
        <path
          fill={color}
          d="M12.1664 8C10.9759 8 9.81215 8.35189 8.82229 9.01118C7.83243 9.67047 7.06093 10.6075 6.60535 11.7039C6.14977 12.8003 6.03057 14.0067 6.26282 15.1705C6.49508 16.3344 7.06835 17.4035 7.91016 18.2426C8.75196 19.0818 9.82449 19.6532 10.9921 19.8847C12.1597 20.1162 13.37 19.9974 14.4699 19.5433C15.5697 19.0892 16.5098 18.3201 17.1712 17.3334C17.8326 16.3467 18.1856 15.1867 18.1856 14C18.184 12.4092 17.5494 10.884 16.4209 9.75911C15.2924 8.63424 13.7623 8.00159 12.1664 8ZM12.1664 18C11.3727 18 10.5969 17.7654 9.93699 17.3259C9.27709 16.8864 8.76275 16.2616 8.45903 15.5307C8.15531 14.7998 8.07584 13.9956 8.23068 13.2196C8.38552 12.4437 8.7677 11.731 9.3289 11.1716C9.89011 10.6122 10.6051 10.2312 11.3835 10.0769C12.1619 9.92252 12.9688 10.0017 13.702 10.3045C14.4353 10.6072 15.062 11.1199 15.5029 11.7777C15.9439 12.4355 16.1792 13.2089 16.1792 14C16.1792 15.0609 15.7564 16.0783 15.0039 16.8284C14.2513 17.5786 13.2307 18 12.1664 18Z"
        />
      </svg>
    )}
  </Icon>
);
