import React, { FC, ReactNode, useEffect } from 'react';
import classNames from 'classnames';
import { ignoreEvent } from '../../utils/helpers';

export interface IPopupProps {
  wrapperClass?: string;
  backdropClass?: string;
  contentClass?: string;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  height?: string;
  minHeight?: string;
  maxHeight?: string;
  disableBackdrop?: boolean;
  onClose?: () => void;
  children: ReactNode;
}

export const Popup: FC<IPopupProps> = ({
  wrapperClass,
  contentClass,
  width,
  minWidth,
  maxWidth = '90vw',
  height,
  minHeight,
  maxHeight = '90vh',
  disableBackdrop,
  onClose,
  children,
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const popupWrapperClass = classNames(
    'fixed top-0 left-0 z-max w-screen h-screen flex-center',
    'bg-overlay1 cursor-default transition-all animate-fade-in',
    wrapperClass,
  );

  const popupContentClass = classNames(
    'bg-popup rounded-10p p-8 overflow-auto transition-all animate-slide-in-up',
    contentClass,
  );

  const onBackdropClick = () => {
    if (!disableBackdrop && onClose) {
      onClose();
    }
  };

  return (
    <div className={popupWrapperClass} onClick={onBackdropClick}>
      <div
        className={popupContentClass}
        style={{ width, minWidth, maxWidth, height, minHeight, maxHeight }}
        onClick={ignoreEvent}
      >
        {children}
      </div>
    </div>
  );
};
