import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Radio } from '../../components';
import { allLangs } from '../../locales/config';
import { fetchAccountProfile } from '../../redux/apis';
import { getAccount } from '../../redux/selectors';
import { useDispatch, useSelector } from '../../redux/store';
import { AuthService, ToastService } from '../../services';

export interface LanguageEditorProps {
  className?: string;
  onBack: () => void;
}

export const LanguageEditor: FC<LanguageEditorProps> = ({ className, onBack }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector(getAccount);

  const onChange = async (lang: string) => {
    AuthService.updateDoctorProfile({ language: lang })
      .then(() => {
        ToastService.success('toast.languageUpdated');
        dispatch(fetchAccountProfile({ force: true, showSpinner: false, showError: false }));
      })
      .catch((err) => ToastService.showHttpError(err, 'toast.changeLanguageFailed'));
  };

  return (
    <div className={classNames('flex h-full flex-col gap-6', className)}>
      <div className="flex-center relative">
        <div className="typo-link absolute left-0 p-2 font-semibold text-primary" onClick={onBack}>
          <i className="fa fa-angle-left" />
          <span className="ml-1.5">{t('common.back')}</span>
        </div>
        <h2 className="text-center">{t('common.language')}</h2>
      </div>

      <div className="flex flex-col gap-2">
        {allLangs.map((language) => {
          const isActive = language.value === user.language;
          return (
            <div
              key={language.value}
              className="flex cursor-pointer items-center gap-2"
              onClick={() => onChange(language.value)}
            >
              <Radio className="scale-75" checked={isActive} />
              <div className="typo-sm">{language.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
