import React, { FC, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Widget } from '@typeform/embed-react';
import { Popup } from '../../components';
import { CONFIG } from '../../constants';
import { UserModel } from '../../resources/models';
import { PatientsService } from '../../services';

export interface AnamnesisFormsPopupProps {
  className?: string;
  patient: UserModel;
  onClose(completed: boolean): void;
}

export const AnamnesisFormsPopup: FC<AnamnesisFormsPopupProps> = ({ className, patient, onClose }) => {
  const { t } = useTranslation();

  const [typeFormRefId, setTypeFormRefId] = useState<string>();

  useEffect(() => {
    if (patient.typeFormRefId) {
      setTypeFormRefId(patient.typeFormRefId);
    } else {
      const id = uuidv4();
      setTypeFormRefId(id);
      patient.typeFormRefId = id;
      PatientsService.update(patient.id, { typeFormRefId: id }, false);
    }
  }, [patient]);

  const onSubmit = () => {
    console.log('----- onSubmit typeform');
    onClose(true);
  };

  return (
    <Popup contentClass={classNames('flex flex-col text-typo1', className)} width="60rem" height="90vh" disableBackdrop>
      <div className="mb-4 flex items-center justify-between gap-4">
        <h1>{t('patientProfile.anamnesis')}</h1>
        <i className="fa fa-times cursor-pointer text-2xl" onClick={() => onClose(false)} />
      </div>
      {typeFormRefId && (
        <Widget
          className="flex-1"
          key={typeFormRefId}
          id={CONFIG.ANAMNESIS_TYPEFORM_ID}
          keepSession
          preselect={{ ref_typeform_id: typeFormRefId }}
          transitiveSearchParams={['ref_typeform_id']}
          onSubmit={onSubmit}
        />
      )}
    </Popup>
  );
};
