import { EXERCISE_TYPE, GENDER, INFO_SIDE, LANGUAGE, STATUS, TYPE_SIDE } from '../enums';
import { ContentModel } from './content.model';
import { FileModel } from './file.model';
import { ScanExerciseModel } from './scan-exercise.model';
import { store } from '../../redux/store';
import { getAccount } from '../../redux/selectors';
import { getLocaleContent, isToday } from '../../utils/helpers';

export class ExerciseModel {
  id: string;
  key: string;
  type: EXERCISE_TYPE;
  content: Partial<ContentModel>[];
  equipment: string;
  video: string;
  videoVimeo: string;
  side?: INFO_SIDE;
  typeSide?: TYPE_SIDE;
  rep_r?: number;
  rep_l?: number;
  rep_both?: number;
  time_l_one?: number;
  time_r_one?: number;
  time_both_one?: number;
  duration_r?: number;
  duration_l?: number;
  duration_both?: number;
  subtitles?: Partial<FileModel>;
  coverMale: Partial<FileModel>;
  coverFemale: Partial<FileModel>;
  animationMale: Partial<FileModel>;
  animationFemale: Partial<FileModel>;
  scan?: ScanExerciseModel;
  createdAt: string;
  updatedAt: string;

  constructor(init?: Partial<ExerciseModel>) {
    const data = {
      id: null,
      scans: [],
      ...init,
    };

    this.id = data.id;
    this.key = data.key;
    this.type = data.type;
    if (data.content && typeof data.content === 'object' && !Array.isArray(data.content)) {
      data.content = [data.content];
    }
    this.content = (data.content || []).map((item) => new ContentModel(item));
    this.equipment = data.equipment;
    this.video = data.video;
    this.videoVimeo = data.videoVimeo;
    this.side = data.side;
    this.typeSide = data.typeSide;
    this.rep_r = data.rep_r;
    this.rep_l = data.rep_l;
    this.rep_both = data.rep_both;
    this.time_l_one = data.time_l_one;
    this.time_r_one = data.time_r_one;
    this.time_both_one = data.time_both_one;
    this.duration_r = data.duration_r;
    this.duration_l = data.duration_l;
    this.duration_both = data.duration_both;
    if (data.subtitles) {
      this.subtitles = new FileModel(data.subtitles);
    }
    if (data.coverMale) {
      this.coverMale = new FileModel(data.coverMale);
    }
    if (data.coverFemale) {
      this.coverFemale = new FileModel(data.coverFemale);
    }
    if (data.animationMale) {
      this.animationMale = new FileModel(data.animationMale);
    }
    if (data.animationFemale) {
      this.animationFemale = new FileModel(data.animationFemale);
    }
    if (!data.scan && data.scans) {
      data.scan = Array.isArray(data.scans) ? data.scans[0] : data.scans;
    }
    if (data.scan) {
      this.scan = new ScanExerciseModel(data.scan);
    }
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

  getCover(): Partial<FileModel> {
    const account = getAccount(store.getState());
    // TODO: check for doctor
    // if (account?.gender === GENDER.FEMALE) {
    //   return this.coverFemale;
    // }
    return this.coverMale;
  }

  getAnimation(): Partial<FileModel> {
    const account = getAccount(store.getState());
    // TODO: check for doctor
    // if (account?.gender === GENDER.FEMALE) {
    //   return this.animationFemale;
    // }
    return this.animationMale;
  }

  getLocalizedContent() {
    return getLocaleContent(this.content);
  }

  isSymmetric() {
    return Boolean(this.time_both_one || this.scan?.time_both_one);
  }

  getLeftActionTime() {
    return this.time_l_one || this.scan?.time_l_one || this.time_both_one || this.scan?.time_both_one || 0;
  }

  getRightActionTime() {
    return this.time_r_one || this.scan?.time_r_one || this.time_both_one || this.scan?.time_both_one || 0;
  }

  getLeftRepeatCount() {
    return this.scan?.rep_l || this.rep_l || this.scan?.rep_both || this.rep_both || 0;
  }

  getRightRepeatCount() {
    return this.scan?.rep_r || this.rep_r || this.scan?.rep_both || this.rep_both || 0;
  }

  getLeftTotalTime() {
    return this.getLeftActionTime() * this.getLeftRepeatCount();
  }

  getRightTotalTime() {
    return this.getRightActionTime() * this.getRightRepeatCount();
  }

  getStatus() {
    return isToday(this.scan?.doneAt) ? STATUS.COMPLETED : STATUS.PENDING;
  }

  getContentByLanguage(language: string = LANGUAGE.EN): Partial<ContentModel> {
    if (!this.content || this.content.length === 0) {
      return;
    }
    let currentContent = this.content.find((e) => e.language === language);
    if (!currentContent && language !== LANGUAGE.EN) {
      currentContent = this.content.find((e) => e.language === LANGUAGE.EN);
    }

    return currentContent || this.content[0];
  }
}
