import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from '../../redux/store';
import { Button } from '../../components';
import { FoldIcon, ScanFocusIcon } from '../../icons';
import { ScanResultSummary } from '../ScanResultSummary';
import { ScanModel } from '../../resources/models';

export interface PatientScanOverviewProps {
  className?: string;
  onViewScan(scan: ScanModel): void;
  onCompareScans(scans: [ScanModel, ScanModel]): void;
}

export const PatientScanOverview: FC<PatientScanOverviewProps> = ({ className, onCompareScans, onViewScan }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { profile, scan, lastScan } = useSelector((state) => state.patient);

  const onScan = () => {
    navigate(`/patients/${profile.id}/scanner`, { replace: true });
  };

  return (
    <div className={className}>
      {!scan && <h2 className="mb-4 font-medium">{t('patientProfile.thereWillBeResultsHere')}</h2>}

      <div className="card2 flex justify-between gap-3 px-6 py-3.5 shadow-card-lg max-md:flex-col md:items-center">
        <div>
          {!scan ? (
            <div className="flex items-center gap-3 text-typo1">
              <ScanFocusIcon />
              <h3>{t('patient.goScan')}</h3>
            </div>
          ) : (
            <div className="flex items-center gap-2 text-typo1">
              <FoldIcon />
              <h3 className="font-bold text-typo3">{t('patientProfile.scanResults')}</h3>
            </div>
          )}
          <p className="typo-sm mt-3 text-gray-dark md:max-w-72">{t('patient.needToScanToGetExercises')}</p>
        </div>
        {!scan ? (
          <Button className="max-md:w-full" onClick={onScan}>
            {t('patientProfile.scan')}
          </Button>
        ) : (
          <Button className="max-md:w-full" disabled={!lastScan} onClick={() => onCompareScans([lastScan, scan])}>
            {t('patientProfile.compareYourResults')}
          </Button>
        )}
      </div>

      {scan && <ScanResultSummary className="mt-8" scan={scan} onViewDetail={() => onViewScan(scan)} />}
      {lastScan && <ScanResultSummary className="mt-6" scan={lastScan} onViewDetail={() => onViewScan(lastScan)} />}
    </div>
  );
};
