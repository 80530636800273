import React, { FC } from 'react';
import { Icon } from './Icon';
import { IIconProps } from './types';

export const SettingsIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 25">
        <mask id="mask0_6231_18691" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
          <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_6231_18691)">
          <path
            d="M5.85 12.5L5.55 11C5.35 10.9167 5.1625 10.8292 4.9875 10.7375C4.8125 10.6458 4.63333 10.5333 4.45 10.4L3 10.85L2 9.15L3.15 8.15C3.11667 7.93333 3.1 7.71667 3.1 7.5C3.1 7.28333 3.11667 7.06667 3.15 6.85L2 5.85L3 4.15L4.45 4.6C4.63333 4.46667 4.8125 4.35417 4.9875 4.2625C5.1625 4.17083 5.35 4.08333 5.55 4L5.85 2.5H7.85L8.15 4C8.35 4.08333 8.5375 4.17083 8.7125 4.2625C8.8875 4.35417 9.06667 4.46667 9.25 4.6L10.7 4.15L11.7 5.85L10.55 6.85C10.5833 7.06667 10.6 7.28333 10.6 7.5C10.6 7.71667 10.5833 7.93333 10.55 8.15L11.7 9.15L10.7 10.85L9.25 10.4C9.06667 10.5333 8.8875 10.6458 8.7125 10.7375C8.5375 10.8292 8.35 10.9167 8.15 11L7.85 12.5H5.85ZM6.85 9.5C7.4 9.5 7.87083 9.30417 8.2625 8.9125C8.65417 8.52083 8.85 8.05 8.85 7.5C8.85 6.95 8.65417 6.47917 8.2625 6.0875C7.87083 5.69583 7.4 5.5 6.85 5.5C6.3 5.5 5.82917 5.69583 5.4375 6.0875C5.04583 6.47917 4.85 6.95 4.85 7.5C4.85 8.05 5.04583 8.52083 5.4375 8.9125C5.82917 9.30417 6.3 9.5 6.85 9.5ZM14.8 23.5L14.35 21.4C14.0667 21.3 13.8042 21.1792 13.5625 21.0375C13.3208 20.8958 13.0833 20.7333 12.85 20.55L10.85 21.2L9.45 18.8L11.05 17.4C11.0167 17.1 11 16.8 11 16.5C11 16.2 11.0167 15.9 11.05 15.6L9.45 14.2L10.85 11.8L12.85 12.45C13.0833 12.2667 13.3208 12.1042 13.5625 11.9625C13.8042 11.8208 14.0667 11.7 14.35 11.6L14.8 9.5H17.6L18.05 11.6C18.3333 11.7 18.5958 11.8208 18.8375 11.9625C19.0792 12.1042 19.3167 12.2667 19.55 12.45L21.55 11.8L22.95 14.2L21.35 15.6C21.3833 15.9 21.4 16.2 21.4 16.5C21.4 16.8 21.3833 17.1 21.35 17.4L22.95 18.8L21.55 21.2L19.55 20.55C19.3167 20.7333 19.0792 20.8958 18.8375 21.0375C18.5958 21.1792 18.3333 21.3 18.05 21.4L17.6 23.5H14.8ZM16.2 19.5C17.0333 19.5 17.7417 19.2083 18.325 18.625C18.9083 18.0417 19.2 17.3333 19.2 16.5C19.2 15.6667 18.9083 14.9583 18.325 14.375C17.7417 13.7917 17.0333 13.5 16.2 13.5C15.3667 13.5 14.6583 13.7917 14.075 14.375C13.4917 14.9583 13.2 15.6667 13.2 16.5C13.2 17.3333 13.4917 18.0417 14.075 18.625C14.6583 19.2083 15.3667 19.5 16.2 19.5Z"
            fill={color}
          />
        </g>
      </svg>
    )}
  </Icon>
);
