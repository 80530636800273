import { FC, ReactNode } from 'react';
import { FormProvider as Form, UseFormReturn } from 'react-hook-form';

export interface FormProviderProps {
  className?: string;
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
  children: ReactNode;
}

export const FormProvider: FC<FormProviderProps> = ({ className, children, onSubmit, methods }) => {
  return (
    <Form {...methods}>
      <form className={className} onSubmit={onSubmit}>
        {children}
      </form>
    </Form>
  );
};
