import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ScanPhoto } from '../ScanPhoto';
import { PHOTO_TYPE } from '../../resources/enums';
import { ScanModel } from '../../resources/models';
import { ProgressBar } from '../../components';

const deviations = [
  {
    type: PHOTO_TYPE.FRONT,
    fields: ['headPosition', 'shoulderPosition', 'legAxis'],
  },
  {
    type: PHOTO_TYPE.SIDE,
    fields: ['cervicalSpine', 'thoracicSpine', 'lumbarSpine'],
  },
  {
    type: PHOTO_TYPE.SIT,
    fields: ['headPosition', 'shoulderPosition', 'hipPosition'],
  },
];

export interface DeviationCardProps {
  className?: string;
  scan: ScanModel;
  position: PHOTO_TYPE;
  title: string;
}

export const DeviationCard: FC<DeviationCardProps> = ({ className, scan, position, title }) => {
  const { t } = useTranslation();

  const deviationScores = useMemo(() => scan.getDeviation(), [scan]);
  const selectedDeviation = useMemo(() => deviations.find((item) => item.type === position), [position]);
  const deviationScore = deviationScores[position];

  return (
    <div className={classNames('card3 !border-0 px-3 pb-4', className)}>
      <div className="typo-sm mb-2 text-center text-typo2">{title}</div>
      <ScanPhoto scan={scan} type={position} aspectRatio={3 / 4} />

      <div className="mt-2 flex flex-col gap-3">
        {selectedDeviation.fields.map((field) => (
          <div key={field}>
            <div className="typo-sm mb-1 font-medium text-typo2">{t(`common.postureScore.${field}`)}</div>
            <div>
              <div className="typo-xs mb-0.5 flex items-center justify-between text-typo2">
                <span>{t('common.low')}</span>
                <span>{t('common.high')}</span>
              </div>
              <ProgressBar theme="gradient" percent={deviationScore[field] || 0} size="md" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
