import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Image } from '../../components';
import { CameraIcon } from '../../icons';
import { PHOTO_TYPE, STATUS } from '../../resources/enums';
import { ScanModel } from '../../resources/models';
import { getAnalyzedPhoto } from '../../utils/helpers';

export interface IScanPhotoProps {
  className?: string;
  scan?: ScanModel;
  type: PHOTO_TYPE;
  aspectRatio?: number;
  label?: ReactNode;
  labelClass?: string;
  onClick?: () => void;
}

export const ScanPhoto: FC<IScanPhotoProps> = ({ className, scan, type, aspectRatio, label, labelClass, onClick }) => {
  const [photo, setPhoto] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [showCanvas, setShowCanvas] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    setShowCanvas(false);
    if (!scan) {
      setPhoto(undefined);
      return;
    }
    const src = scan[type]?.src;
    if (!src || scan.status !== STATUS.COMPLETED || !scan.points) {
      setPhoto(src);
      return;
    }

    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    setLoading(true);
    setShowCanvas(false);
    getAnalyzedPhoto(scan, type)
      .then((image) => {
        if (image) {
          const ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
          setShowCanvas(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [scan, type]);

  return (
    <div className={className}>
      <div className="relative">
        <Image
          className="h-full w-full object-cover"
          wrapperClass={classNames('rounded-10p', !photo && 'card1', onClick && 'cursor-pointer')}
          src={photo}
          placeholder={<CameraIcon className="text-typo1" size={24} />}
          aspectRatio={aspectRatio}
          spinner
          spinnerSize="sm"
          loading={loading}
          onClick={onClick}
        />
        <div
          className={classNames(
            'pointer-events-none absolute left-0 top-0 h-full w-full overflow-hidden rounded-lg',
            !showCanvas && 'hidden',
          )}
        >
          <canvas ref={canvasRef} className="h-full w-full" width={600} height={800} />
        </div>
      </div>
      {label && <div className={classNames('typo-xs mt-2 text-center text-typo1', labelClass)}>{label}</div>}
    </div>
  );
};
