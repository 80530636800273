import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormProvider, Popup, RHFTextField } from '../../components';
import { ScanModel } from '../../resources/models';
import { ScansService, ToastService } from '../../services';

export interface ISharePhotosPopupProps {
  className?: string;
  scan: ScanModel;
  onClose(): void;
}

type FormData = {
  email: string;
};

export const SharePhotosPopup: FC<ISharePhotosPopupProps> = ({ className, scan, onClose }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const formSchema = Yup.object().shape({
    email: Yup.string().email(t('validationErrors.invalidEmail')).required(t('validationErrors.requiredField')),
  });

  const defaultValues = {
    email: '',
  };

  const methods = useForm<FormData>({
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const onSubmit = (form: FormData) => {
    setLoading(true);
    ScansService.shareScan(scan.id, form.email, false)
      .then(() => {
        ToastService.success('toast.photoShared');
        onClose();
      })
      .catch((err) => ToastService.showHttpError(err, 'toast.photoSharingFailed'))
      .finally(() => setLoading(false));
  };

  return (
    <Popup contentClass={classNames('text-typo1', className)} width="21rem" onClose={onClose}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <h1>{t('patient.sharePhotos')}</h1>
        <p className="mt-1">{t('patient.sharePhotosHelperText')}</p>
        <RHFTextField className="mt-4" name="email" placeholder={t('common.email')} />
        <Button className="mt-4" type="submit" fullWidth disabled={!isValid} loading={loading}>
          {t('common.share')}
        </Button>
        <Button className="mt-4" type="button" variant="outline" fullWidth onClick={onClose}>
          {t('common.cancel')}
        </Button>
      </FormProvider>
    </Popup>
  );
};
