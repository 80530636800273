import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PatientProfile } from './Home';
import { ScannerRouting } from './Scanner/routing';
import { ExercisesRouting } from './Exercises/routing';
import { PatientGuard } from '../../../providers/PatientGuard';

export const PatientProfileRouting = () => {
  return (
    <PatientGuard>
      <Routes>
        <Route index element={<PatientProfile />} />
        <Route path="/scanner/*" element={<ScannerRouting />} />
        <Route path="/exercises/*" element={<ExercisesRouting />} />
      </Routes>
    </PatientGuard>
  );
};
