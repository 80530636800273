import React, { FC } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Popup } from '../../components';

export interface IDeleteScanConfirmPopupProps {
  className?: string;
  onDelete(): void;
  onCancel(): void;
}

export const DeleteScanConfirmPopup: FC<IDeleteScanConfirmPopupProps> = ({ className, onDelete, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Popup contentClass={classNames('text-typo1', className)} width="25rem" disableBackdrop>
      <h1>{t('patientProfile.deleteScan')}</h1>
      <p className="mt-1">
        <Trans i18nKey="patientProfile.deleteScanConfirmMessage" />
      </p>
      <Button className="mt-4" theme="danger" fullWidth onClick={onDelete}>
        {t('patientProfile.deleteScan')}
      </Button>
      <Button className="mt-4" variant="outline" fullWidth onClick={onCancel}>
        {t('common.cancel')}
      </Button>
    </Popup>
  );
};
