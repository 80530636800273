import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';

export interface ITab {
  value: unknown;
  label: ReactNode;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
}

export interface TabsProps {
  className?: string;
  tabs: ITab[];
  value: unknown;
  fullWidth?: boolean;
  onChange(value: unknown): void;
}

export const Tabs: FC<TabsProps> = ({ className, tabs, value, fullWidth, onChange }) => {
  return (
    <div className={classNames('flex items-center gap-4', className)}>
      {tabs.map((tab, i) => (
        <div
          key={i}
          className={classNames(
            'flex cursor-pointer items-center gap-3 rounded-t border-b-2 px-4 py-2 transition-all',
            value === tab.value
              ? 'border-primary bg-white'
              : 'border-transparent bg-transparent hover:border-primary/60 hover:bg-white/80',
            fullWidth ? 'flex-1' : 'min-w-40',
          )}
          onClick={() => onChange(tab.value)}
        >
          {tab.startAdornment}
          <div className="flex-center flex-1 font-semibold">{tab.label}</div>
          {tab.endAdornment}
        </div>
      ))}
    </div>
  );
};
