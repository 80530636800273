import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ChevronRightIcon } from '../../../icons';
import { MainLayout } from '../../../layouts';
import { AvatarUpload, LanguageEditor, Market, NameEditor } from '../../../sections';
import { getAccount } from '../../../redux/selectors';
import { useSelector } from '../../../redux/store';
import { getLanguageName } from '../../../utils/helpers';

export const Settings = () => {
  const user = useSelector(getAccount);
  const { t } = useTranslation();

  const [openedPanel, setOpenedPanel] = useState<string>();

  const sidebar = useMemo(() => {
    if (openedPanel === 'nameEditor') {
      return <NameEditor onBack={() => setOpenedPanel(undefined)} />;
    }
    if (openedPanel === 'languageEditor') {
      return <LanguageEditor onBack={() => setOpenedPanel(undefined)} />;
    }
    if (openedPanel === 'market') {
      return <Market onBack={() => setOpenedPanel(undefined)} />;
    }
    return null;
  }, [openedPanel]);

  return (
    <MainLayout sidebar={sidebar} sidebarOverlap>
      <div className="max-w-140">
        <div className="mb-8 flex items-end justify-between gap-4">
          <h1 className="font-medium">{t('settings.title')}</h1>
          <a className="typo-button-lg cursor-pointer font-medium text-primary" href="mailto:support@iplena.com">
            {t('common.support')}
          </a>
        </div>

        <div className="typo-sm flex flex-col gap-4">
          <AvatarUpload />

          <div
            className={classNames(
              'flex cursor-pointer items-center justify-between gap-4 px-4 py-3 transition-all',
              openedPanel === 'nameEditor' ? 'card2' : 'card1',
            )}
            onClick={() => setOpenedPanel('nameEditor')}
          >
            <div>{t('settings.username')}</div>
            <div className="text-input-placeholder">{user?.name || ''}</div>
          </div>

          <div className="card1 flex flex-col gap-3 px-4 py-3">
            <Link className="flex w-full items-center justify-between" to="/privacy-policy" target="_blank">
              <div>{t('common.privacyPolicy')}</div>
              <ChevronRightIcon className="text-input-placeholder" />
            </Link>
            <hr className="border-input-border" />
            <Link className="flex w-full items-center justify-between" to="/terms-of-use" target="_blank">
              <div>{t('common.termsAndConditions')}</div>
              <ChevronRightIcon className="text-input-placeholder" />
            </Link>
          </div>

          <div
            className={classNames(
              'flex cursor-pointer items-center justify-between gap-4 px-4 py-3 transition-all',
              openedPanel === 'languageEditor' ? 'card2' : 'card1',
            )}
            onClick={() => setOpenedPanel('languageEditor')}
          >
            <div>{t('common.language')}</div>
            <div className="flex items-center gap-1 text-input-placeholder">
              <div>{getLanguageName(user?.language)}</div>
              <ChevronRightIcon />
            </div>
          </div>

          <div
            className={classNames(
              'flex cursor-pointer items-center justify-between gap-4 px-4 py-3 transition-all',
              openedPanel === 'market' ? 'card2' : 'card1',
            )}
            onClick={() => setOpenedPanel('market')}
          >
            <div>{t('common.market')}</div>
            <ChevronRightIcon className="text-input-placeholder" />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
