import { FC, ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import { CheckIcon } from '../../../icons';

export interface ICheckboxProps {
  className?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  checked?: boolean;
  label?: ReactNode;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

export const Checkbox: FC<ICheckboxProps> = ({
  className,
  size = 'md',
  checked = false,
  label,
  disabled,
  onChange,
}) => {
  const [value, setValue] = useState(checked);

  const wrapperClass = classNames(
    'flex items-center gap-2',
    disabled ? 'opacity-60 cursor-default pointer-events-none' : 'cursor-pointer',
    className,
  );

  const checkboxClass = classNames(
    'shrink-0 inline-flex items-center justify-center',
    'text-primary border-2',
    size === 'md' && 'w-4.5 h-4.5 rounded-sm m-0.5',
    value ? 'border-primary' : 'border-label',
  );

  const iconSize = {
    md: 18,
  };

  useEffect(() => {
    setValue(checked);
  }, [checked]);

  const onToggleCheck = () => {
    setValue(!value);
    if (onChange) {
      onChange(!value);
    }
  };

  return (
    <div className={wrapperClass} onClick={onToggleCheck}>
      <div className={checkboxClass}>{value && <CheckIcon size={iconSize[size]} />}</div>
      {label}
    </div>
  );
};
