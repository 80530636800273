import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { JaaSMeeting } from '@jitsi/react-sdk';
import { LoadingSpinner } from '../../../../components';
import { CONFIG } from '../../../../constants';
import { getAccount } from '../../../../redux/selectors';
import { useSelector } from '../../../../redux/store';
import { CreateChatRoomResponseDto } from '../../../../resources/dtos';
import { SCHEDULE_STATUS } from '../../../../resources/enums';
import { ScheduleModel } from '../../../../resources/models';
import { ConsultationsService } from '../../../../services';

export const AppointmentCall = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const account = useSelector(getAccount);

  const [schedule, setSchedule] = useState<ScheduleModel | null>();
  const [room, setRoom] = useState<CreateChatRoomResponseDto | null>();

  const isActiveAppointment = useMemo(
    () =>
      [SCHEDULE_STATUS.ACTIVE, SCHEDULE_STATUS.RE_OPEN, SCHEDULE_STATUS.IN_PROGRESS].includes(schedule?.status) &&
      new Date(schedule.endTime).getTime() > Date.now(),
    [schedule],
  );

  useEffect(() => {
    ConsultationsService.getScheduleById(id, false)
      .then((data) => {
        setSchedule(data);
      })
      .catch(() => {
        setSchedule(null);
      });

    ConsultationsService.createRoom(id, false)
      .then((data) => {
        setRoom(data);
      })
      .catch(() => {
        setRoom(null);
      });
  }, [id]);

  const onClose = () => {
    navigate(`/appointments/${id}`);
  };

  const onComplete = () => {
    ConsultationsService.completeConsultation(id);
    onClose();
  };

  if (isActiveAppointment && room) {
    return (
      <div className="h-screen w-screen bg-black">
        <JaaSMeeting
          appId={CONFIG.JITSI_APP_ID}
          jwt={room.jwt}
          roomName={room.room_id}
          userInfo={{
            displayName: account.name,
            email: account.email,
          }}
          getIFrameRef={(node) => {
            node.style.width = '100%';
            node.style.height = '100%';
          }}
          onReadyToClose={onComplete}
        />
      </div>
    );
  }

  return (
    <div className="relative h-screen w-screen bg-training-bg text-training-text">
      <div className="pointer-events-none absolute left-0 top-0 h-full w-full bg-training-overlay" />

      <div className="flex-center relative h-full w-full p-8">
        {schedule === undefined || room === undefined ? <LoadingSpinner /> : t('appointments.call.initFailed')}
      </div>

      <div
        className="flex-center typo-btn absolute left-4 top-4 cursor-pointer p-3 font-medium uppercase sm:left-16 sm:top-10"
        onClick={onClose}
      >
        <i className="fa fa-arrow-left mr-3 text-xs" /> {t('common.cancel')}
      </div>
    </div>
  );
};
