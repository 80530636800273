import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, Popup } from '../../components';
import { ExercisesService, ToastService } from '../../services';
import { ExerciseModel, UserModel } from '../../resources/models';

export interface ISendFeedbackPopupProps {
  className?: string;
  patient: UserModel;
  exercise: ExerciseModel;
  onClose(): void;
}

export const SendFeedbackPopup: FC<ISendFeedbackPopupProps> = ({ className, patient, exercise, onClose }) => {
  const { t } = useTranslation();
  const [content, setContent] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = () => {
    if (!content) {
      return;
    }

    setSubmitting(true);
    ExercisesService.sendFeedback(patient.id, exercise.id, content, false)
      .then(() => {
        ToastService.success('toast.feedbackSent');
        onClose();
      })
      .catch((err) => ToastService.showHttpError(err, 'toast.sendingFeedbackFailed'))
      .finally(() => setSubmitting(false));
  };

  return (
    <Popup contentClass={classNames('text-typo1', className)} width="32rem" disableBackdrop onClose={onClose}>
      <h1>{t('patientProfile.sendFeedback.title')}</h1>
      <p className="mt-4">{t('patientProfile.sendFeedback.description')}</p>

      <div className="mt-8">
        <label className="mb-1 block text-15p font-semibold text-typo1">{exercise.getLocalizedContent()?.title}</label>
        <textarea
          className="w-full rounded-10p border border-input-border bg-input-bg px-4 py-2.5 text-17p text-input-text outline-none transition-all focus:border-input-border-focus"
          rows={5}
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
      </div>

      <Button className="mt-8" type="submit" fullWidth loading={submitting} disabled={!content} onClick={onSubmit}>
        {t('common.send')}
      </Button>
      <Button className="mt-4" type="button" variant="outline" fullWidth onClick={onClose}>
        {t('common.cancel')}
      </Button>
    </Popup>
  );
};
