import React, { forwardRef, InputHTMLAttributes, ReactNode, useState } from 'react';
import classNames from 'classnames';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export interface PhoneInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  fullWidth?: boolean;
  value?: string;
  label?: ReactNode;
  labelClass?: string;
  inputClass?: string;
  helperText?: ReactNode;
  helperClass?: string;
  error?: boolean;
  onChange?: (value: string) => void;
}

export const PhoneInput = forwardRef<any, PhoneInputProps>(
  (
    {
      className,
      fullWidth,
      value,
      label,
      labelClass,
      inputClass,
      helperText,
      helperClass,
      error,
      disabled,
      onFocus,
      onBlur,
      ...inputProps
    },
    ref,
  ) => {
    const [focused, setFocused] = useState(false);

    const wrapperClassName = classNames('relative', fullWidth && 'w-full', className);

    const inputClassName = classNames(
      'min-h-11 !text-17p !w-full !rounded-10p outline-none transition-all',
      disabled
        ? '!bg-white/16 !text-white/48 !border-white/32'
        : [
            '!bg-input-bg !text-input-text',
            error ? '!border-danger' : focused ? '!border-input-border-focus' : '!border-input-border',
          ],
      inputClass,
    );

    const buttonClassName = classNames(
      '!rounded-l-10p !pl-1 !border-r-0 outline-none transition-all',
      disabled
        ? '!bg-white/16 !text-white/48 !border-white/32'
        : [
            '!bg-input-bg !text-input-text',
            error ? '!border-danger' : focused ? '!border-input-border-focus' : '!border-input-border',
          ],
      '[&>.selected-flag]:!rounded-l-10p',
    );

    const labelClassName = classNames(
      'block font-semibold mb-1',
      'text-15p',
      error ? 'text-danger' : 'text-typo1',
      labelClass,
    );

    const helperClassName = classNames('mt-1', 'text-sm', error ? 'text-danger' : 'text-typo1', helperClass);

    return (
      <div ref={ref} className={wrapperClassName}>
        {label && <label className={labelClassName}>{label}</label>}
        <ReactPhoneInput
          containerClass="!font-primary"
          inputClass={inputClassName}
          buttonClass={buttonClassName}
          value={value}
          disabled={disabled}
          onFocus={(e) => {
            setFocused(true);
            onFocus && onFocus(e);
          }}
          onBlur={(e) => {
            setFocused(false);
            onBlur && onBlur(e);
          }}
          {...inputProps}
        />
        {helperText && <div className={helperClassName}>{helperText}</div>}
      </div>
    );
  },
);
