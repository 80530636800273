export class TimeslotModel {
  id: string;
  dow: number;
  from: string;
  to: string;
  disabled?: boolean;

  constructor(init?: any) {
    const data = {
      id: null,
      ...init,
    };

    this.id = data.id;
    this.dow = data.dow;
    this.from = data.from ?? data.from_time;
    this.to = data.to ?? data.to_time;
    this.disabled = data.disabled;
  }
}
