import React, { FC } from 'react';
import { Icon } from './Icon';
import { IIconProps } from './types';

export const ArrowLeftLongIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M1.37597 13.6908L5.25497 17.6976C5.4425 17.8912 5.69681 18 5.96197 18C6.22714 18 6.48145 17.8912 6.66897 17.6976C6.85644 17.5039 6.96176 17.2412 6.96176 16.9673C6.96176 16.6934 6.85644 16.4307 6.66897 16.237L3.11397 12.567H23.5C23.7652 12.567 24.0195 12.4582 24.2071 12.2645C24.3946 12.0708 24.5 11.808 24.5 11.5341C24.5 11.2601 24.3946 10.9974 24.2071 10.8037C24.0195 10.61 23.7652 10.5012 23.5 10.5012H3.05297L6.66897 6.76299C6.85644 6.56929 6.96176 6.3066 6.96176 6.03271C6.96176 5.75881 6.85644 5.49613 6.66897 5.30243C6.48145 5.10878 6.22714 5 5.96197 5C5.69681 5 5.4425 5.10878 5.25497 5.30243L1.37497 9.30916C0.814401 9.89134 0.499813 10.6791 0.5 11.5002C0.500187 12.3213 0.815135 13.1089 1.37597 13.6908Z"
        />
      </svg>
    )}
  </Icon>
);
