import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MainLayout } from '../../../layouts';
import { UserModel } from '../../../resources/models';
import { CreatePatient } from '../../../sections';
import { useState } from 'react';

export const NewPatient = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [createdPatient, setCreatedPatient] = useState<UserModel>();
  const [version, setVersion] = useState(Date.now());

  const onCreatePatient = (newPatient: UserModel) => {
    if (newPatient.hasInsurance) {
      setCreatedPatient(newPatient);
    } else {
      navigate(`/patients/${newPatient.id}`);
    }
  };

  const onClose = () => {
    if (createdPatient) {
      navigate(`/patients/${createdPatient.id}`);
    }

    setCreatedPatient(undefined);
    setVersion(Date.now());
  };

  return (
    <MainLayout>
      <h1 className="font-medium">{t('common.newPatient')}</h1>

      <div className="mt-8 max-w-140">
        <CreatePatient key={version} onCreate={onCreatePatient} onClose={onClose} />
      </div>
    </MainLayout>
  );
};
