import { HttpService } from './http.service';
import { CreateScanRequestDto, PaginateRequestDto, SearchScansRequestDto } from '../resources/dtos';
import { LANGUAGE, PHOTO_TYPE } from '../resources/enums';
import { ITrainStatus } from '../resources/interfaces';
import { ExerciseModel, IssueModel, ScanModel } from '../resources/models';
import { formatPaginatedResult, getCurrentLanguage } from '../utils/helpers';
import { CONFIG } from '../constants';

export class ScansService {
  static search(query: SearchScansRequestDto, hasSpinner = true) {
    return HttpService.get('/scans', query, hasSpinner).then((result) =>
      formatPaginatedResult<ScanModel>(ScanModel, result.data),
    );
  }

  static searchPatientScans(patientId: string, query: SearchScansRequestDto, hasSpinner = true) {
    return HttpService.get(`/scans/patient/${patientId}`, query, hasSpinner).then((result) =>
      formatPaginatedResult<ScanModel>(ScanModel, result.data),
    );
  }

  static find(id: string, hasSpinner = true): Promise<ScanModel> {
    return HttpService.get(`/scans/${id}`, {}, hasSpinner).then((result) => new ScanModel(result.data));
  }

  static createPatientScan(data: CreateScanRequestDto, hasSpinner = true): Promise<ScanModel> {
    return HttpService.post('/scans/patient', data, hasSpinner).then((result) => new ScanModel(result.data));
  }

  static uploadPhoto(id: string, type: PHOTO_TYPE, file: Blob, hasSpinner = true): Promise<ScanModel> {
    const formData = new FormData();
    formData.append('file', file);

    return HttpService.patch(`/scans/${id}/${type}`, formData, hasSpinner).then((result) => new ScanModel(result.data));
  }

  static analyze(id: string, hasSpinner = true) {
    return HttpService.get(`/scans/${id}/analyze`, {}, hasSpinner).then((result) => new ScanModel(result.data));
  }

  static searchExercises(scanId: string, query: PaginateRequestDto = {}, hasSpinner = true) {
    return HttpService.get(`/scans/${scanId}/exercises`, query, hasSpinner).then((result) =>
      formatPaginatedResult<ExerciseModel>(ExerciseModel, result.data),
    );
  }

  static getDailyExercises(scanId: string, query?: { lang: LANGUAGE }, hasSpinner = true): Promise<ExerciseModel[]> {
    return HttpService.get(`/scans/${scanId}/daily-exercises`, query, hasSpinner).then((res) =>
      res.data.map((item) => new ExerciseModel(item)),
    );
  }

  static getTrainStatus(scanId: string, hasSpinner = true): Promise<ITrainStatus> {
    return HttpService.get(`/scans/${scanId}/train-status`, {}, hasSpinner).then((res) => res.data);
  }

  static searchIssues(scanId: string, query: PaginateRequestDto = {}, hasSpinner = true) {
    return HttpService.get(`/scans/${scanId}/issues`, query, hasSpinner).then((result) =>
      formatPaginatedResult<IssueModel>(IssueModel, result.data),
    );
  }

  static searchFeatures(scanId: string, query: PaginateRequestDto = {}, hasSpinner = true) {
    return HttpService.get(`/scans/${scanId}/features`, query, hasSpinner).then((result) =>
      formatPaginatedResult<IssueModel>(IssueModel, result.data),
    );
  }

  static exportReportAsPdf(scanId: string, hasSpinner = true): Promise<Blob> {
    return HttpService.request(
      'GET',
      `/scans/${scanId}/report/pdf`,
      {
        params: {
          theme: 'iplena',
          language: getCurrentLanguage(),
        },
        responseType: 'blob',
      },
      hasSpinner,
    );
  }

  static shareScan(scanId: string, email: string, hasSpinner = true): Promise<void> {
    return HttpService.post(`/scans/${scanId}/share`, { email, theme: CONFIG.THEME }, hasSpinner);
  }

  static delete(id: string, hasSpinner = true): Promise<ScanModel> {
    return HttpService.delete(`/scans/${id}`, {}, hasSpinner).then((result) => new ScanModel(result.data));
  }

  static unlockAccess(id: string, hasSpinner = true): Promise<ScanModel> {
    return HttpService.patch(`/scans/${id}/subscribed`, {}, hasSpinner).then((result) => new ScanModel(result.data));
  }
}
