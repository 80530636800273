import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { SharePhotosPopup } from '../SharePhotosPopup';
import { STATUS } from '../../resources/enums';
import { ScanModel } from '../../resources/models';
import { ToastService } from '../../services';

export interface ISharePhotosButtonProps {
  className?: string;
  scan: ScanModel;
}

export const SharePhotosButton: FC<ISharePhotosButtonProps> = ({ className, scan }) => {
  const { t } = useTranslation();
  const [showSharePopup, setShowSharePopup] = useState(false);

  const onSharePhotos = () => {
    if (scan?.status !== STATUS.COMPLETED) {
      ToastService.error('toast.uploadPhotosFirst');
      return;
    }
    setShowSharePopup(true);
  };

  return (
    <>
      <span className={classNames('typo-link font-medium uppercase', className)} onClick={onSharePhotos}>
        {t('common.share')}
      </span>

      {showSharePopup && <SharePhotosPopup scan={scan} onClose={() => setShowSharePopup(false)} />}
    </>
  );
};
