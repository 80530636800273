import {
  GENDER,
  POLICY_AGREEMENT,
  ROLE,
  USER_QUIZ_GOAL,
  USER_QUIZ_LIFESTYLE,
  USER_QUIZ_PROBLEM,
  USER_STATUS,
} from '../enums';
import { ScanModel } from './scan.model';
import { SubscriptionModel } from './subscription.model';

export class UserModel {
  id: string;
  status: USER_STATUS;
  role: ROLE;
  gender: GENDER;
  name: string;
  doctorName: string;
  hasInsurance: boolean;
  email?: string;
  phoneNumber?: string;
  birth: number;
  level: number;
  trainLevel: number;
  deviceToken?: string;
  language?: string;
  country?: string;
  address?: string;
  quizGoal: USER_QUIZ_GOAL;
  quizBackDisorders: USER_QUIZ_PROBLEM;
  quizLifestyle: USER_QUIZ_LIFESTYLE;
  appVersion?: string;
  scan?: ScanModel;
  spineScan?: ScanModel;
  subscription?: SubscriptionModel;
  isFirstLogin?: boolean;
  policyAndAgreements: POLICY_AGREEMENT[];
  typeFormRefId?: string;
  isSubmittedAnamnesis: boolean;

  constructor(init?: Partial<UserModel>) {
    const data = {
      id: null,
      gender: GENDER.MALE,
      policyAndAgreements: [],
      ...init,
    };

    this.id = data.id;
    this.status = data.status;
    this.role = data.role;
    this.gender = data.gender;
    this.name = data.name;
    this.doctorName = data.doctorName;
    this.hasInsurance = data.hasInsurance;
    this.email = data.email;
    this.phoneNumber = data.phoneNumber;
    this.birth = data.birth;
    this.level = data.level;
    this.trainLevel = data.trainLevel;
    this.deviceToken = data.deviceToken;
    this.language = data.language;
    this.country = data.country;
    this.address = data.address;
    this.quizGoal = data.quizGoal;
    this.quizBackDisorders = data.quizBackDisorders;
    this.quizLifestyle = data.quizLifestyle;
    this.appVersion = data.appVersion;
    this.isFirstLogin = data.isFirstLogin;
    this.policyAndAgreements = data.policyAndAgreements;
    this.typeFormRefId = data.typeFormRefId;
    this.isSubmittedAnamnesis = data.isSubmittedAnamnesis;

    if (data.scan) {
      this.scan = new ScanModel(data.scan);
    }
    if (data.spineScan) {
      this.spineScan = new ScanModel(data.spineScan);
    }
    if (data.subscription) {
      this.subscription = new SubscriptionModel(data.subscription);
    }
  }

  get isCompletedAgreements() {
    return Object.values(POLICY_AGREEMENT).every((form) => this.policyAndAgreements?.includes(form));
  }
}
