import { HttpService } from './http.service';
import { FileModel } from '../resources/models';

export class FilesService {
  static uploadPhoto(file: Blob, hasSpinner = true): Promise<FileModel> {
    const formData = new FormData();
    formData.append('file', file);

    return HttpService.post(`/files`, formData, hasSpinner).then((result) => new FileModel(result.data));
  }
}
