import { IPhoto } from '../../resources/interfaces';
import { ScanModel } from '../../resources/models';
import { PHOTO_TYPE } from '../../resources/enums';

export const cropImageFromGeometry = async (src, rect, scaleX, scaleY): Promise<IPhoto> => {
  if (!src || !rect) {
    return null;
  }

  const canvas = document.createElement('canvas');
  canvas.width = rect.width;
  canvas.height = rect.height;

  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    src,
    rect.x * scaleX,
    rect.y * scaleY,
    rect.width * scaleX,
    rect.height * scaleY,
    0,
    0,
    rect.width,
    rect.height,
  );

  const blob: Blob = await new Promise((resolve) => {
    canvas.toBlob(resolve, 'image/jpeg');
  });
  const dataURL = canvas.toDataURL('image/jpeg');

  canvas.remove();

  return { blob, dataURL };
};

export const getDataUrlFromBlob = (blob: Blob) => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      const dataURL = reader.result;
      resolve(dataURL as string);
    };

    reader.readAsDataURL(blob);
  });
};

export const getAnalyzedPhoto = async (scan: ScanModel, type: PHOTO_TYPE) => {
  const src = scan[type]?.src;
  if (!src || !scan.points) {
    return undefined;
  }

  const img = document.createElement('img');
  try {
    await new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
      img.src = src;
    });
  } catch {
    return undefined;
  }

  const canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;

  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0);

  const color = '#22B2D5';
  const lineWidth = Math.floor(img.width / 200);

  const pointGroups = scan.getPointGroups(type);
  pointGroups.forEach((group) => {
    drawPointGroup(ctx, group, color, lineWidth);
  });

  if (type === PHOTO_TYPE.SIDE && scan.points.line) {
    ctx.fillStyle = color;
    ctx.lineWidth = 0;
    const r = lineWidth / 3;
    scan.points.line.forEach((point) => {
      ctx.beginPath();
      ctx.ellipse(point[0], point[1], r, r, 0, 0, 2 * Math.PI);
      ctx.fill();
      ctx.closePath();
    });
  }

  return canvas;
};

export const drawPointGroup = (ctx: CanvasRenderingContext2D, points: number[][], color: string, lineWidth: number) => {
  if (points.length === 1) {
    drawPoint(ctx, points[0], color, lineWidth);
  } else if (points.length === 4) {
    drawLine(ctx, [points[0], points[2], points[1], points[3], points[0]], color, lineWidth);
  } else {
    drawLine(ctx, points, color, lineWidth);
  }
};

export const drawLine = (ctx: CanvasRenderingContext2D, points: number[][], color: string, lineWidth: number) => {
  points = points.filter((point) => point?.length >= 2);
  ctx.beginPath();
  ctx.moveTo(points[0][0], points[0][1]);
  points.slice(1).forEach((point) => {
    ctx.lineTo(point[0], point[1]);
  });
  ctx.strokeStyle = color;
  ctx.lineWidth = lineWidth;
  ctx.stroke();
  ctx.closePath();

  points.forEach((point) => {
    drawPoint(ctx, point, color, lineWidth);
  });
};

export const drawPoint = (ctx: CanvasRenderingContext2D, point: number[], color: string, lineWidth: number) => {
  if (point?.length >= 2) {
    const r = lineWidth * 1.5;
    ctx.beginPath();
    ctx.ellipse(point[0], point[1], r, r, 0, 0, 2 * Math.PI);
    ctx.fillStyle = color;
    ctx.lineWidth = 0;
    ctx.fill();

    ctx.ellipse(point[0], point[1], r * 1.5, r * 1.5, 0, 0, 2 * Math.PI);
    ctx.fillStyle = 'transparent';
    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth / 3;
    ctx.stroke();
    ctx.closePath();
  }
};
