import { FC, PropsWithChildren, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner } from '../components';
import { MainLayout } from '../layouts';
import { fetchDailyExercises, fetchIssues, fetchPatientProfile, fetchTrainStatus } from '../redux/apis';
import { setLastScan } from '../redux/reducers/patient.reducer';
import { useDispatch, useSelector } from '../redux/store';
import { LANGUAGE, SCAN_TYPE, STATUS } from '../resources/enums';
import { ScansService } from '../services';

export const PatientGuard: FC<PropsWithChildren> = ({ children }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const { patientId } = useParams<{ patientId: string }>();
  const { profile, scan } = useSelector((state) => state.patient);

  const scanId = scan?.id;
  const scanStatus = scan?.status;
  const language = (i18n.language || 'en') as LANGUAGE;

  useEffect(() => {
    if (patientId) {
      dispatch(fetchPatientProfile({ patientId, force: false, showError: true }));
    }
  }, [dispatch, patientId]);

  useEffect(() => {
    if (scanId && scanStatus === STATUS.COMPLETED) {
      dispatch(fetchIssues({ scanId, query: { lang: language }, force: true }));
    }
  }, [dispatch, language, scanId, scanStatus]);

  useEffect(() => {
    if (scanId && scanStatus === STATUS.COMPLETED) {
      ScansService.searchPatientScans(patientId, {
        type: SCAN_TYPE.POSTURE,
        status: STATUS.COMPLETED,
        desc: 'createdAt',
        page: 1,
        take: 2,
      })
        .then((res) => {
          dispatch(setLastScan(res.result[1]));
        })
        .catch(() => {
          dispatch(setLastScan(undefined));
        });
    } else {
      dispatch(setLastScan(undefined));
    }
  }, [dispatch, patientId, scanId, scanStatus]);

  useEffect(() => {
    if (scanId && scanStatus === STATUS.COMPLETED) {
      dispatch(fetchTrainStatus({ force: true, showSpinner: false, showError: false }));
    }
  }, [scanId, scanStatus, dispatch]);

  useEffect(() => {
    if (scanId) {
      dispatch(
        fetchDailyExercises({
          query: { lang: language },
          force: true,
        }),
      );
    }
  }, [dispatch, scanId, scanStatus, language]);

  if (profile?.id !== patientId) {
    return (
      <MainLayout>
        <div className="flex-center h-full w-full">
          <LoadingSpinner />
        </div>
      </MainLayout>
    );
  }

  return <>{children}</>;
};
