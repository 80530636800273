export const TermsOfUse = () => (
  <div className="typo-sm container mx-auto max-w-222 p-8 sm:p-16">
    <h1 className="font-bold">Allgemeine Geschäftsbedingungen der ScienceMed GmbH</h1>
    <br />
    <p>(Stand: 01. Oktober 2023)</p>
    <br />
    <br />

    <h2 className="font-semibold">1. Geltungsbereich</h2>
    <br />
    <p>
      1.1 Die vorliegenden Allgemeinen Geschäftsbedingungen (AGB) gelten für alle Geschäftsbeziehungen zwischen der
      ScienceMed GmbH (nachfolgend "Anbieter" genannt) und ihren Kunden („Nutzer“), sofern der Nutzer Unternehmer (§ 14
      BGB), eine juristische Person des öffentlichen Rechts oder ein öffentlich-rechtliches Sondervermögen im Sinne von
      § 310 Absatz 1 BGB ist.
    </p>
    <br />
    <p>
      1.2 Unsere Allgemeinen Geschäftsbedingungen gelten ausschließlich. Abweichende, entgegenstehende oder ergänzende
      Allgemeine Geschäftsbedingungen des Nutzers werden nur dann und insoweit Vertragsbestandteil, als wir ihrer
      Geltung ausdrücklich zugestimmt haben. Dieses Zustimmungserfordernis gilt auch dann, wenn der Nutzer im Rahmen der
      Bestellung auf seine AGB verweist und wir den AGB nicht ausdrücklich widersprochen haben.
    </p>
    <br />
    <p>
      1.3 Diese Allgemeinen Geschäftsbedingungen gelten für Verträge über die Nutzung der Software "PhysioScan" sowie
      für damit verbundene Dienstleistungen. Sie gelten in der zum Zeitpunkt der Bestellung des Nutzers gültigen bzw. in
      der ihm zuletzt in Textform mitgeteilten Fassung als Rahmenvereinbarung auch für gleichartige künftige Verträge.
    </p>
    <br />
    <p>
      1.4 Im Einzelfall getroffene, individuelle Vereinbarungen mit dem Nutzer (einschließlich Nebenabreden, Ergänzungen
      und Änderungen) haben Vorrang vor diesen Allgemeinen Geschäftsbedingungen. Für den Inhalt derartiger
      Vereinbarungen ist, vorbehaltlich des Gegenbeweises, ein schriftlicher Vertrag maßgebend.
    </p>
    <br />
    <p>
      1.5 Rechtserhebliche Erklärungen sowie Anzeigen des Nutzers sind ausschließlich schriftlich per Post an die
      Geschäftsführung adressiert abzugeben. Andere Formen der Kommunikation, wie beispielsweise E-Mail oder SMS, werden
      nicht akzeptiert.
    </p>
    <br />
    <p>
      1.6 Sofern Hinweise auf die Geltung gesetzlicher Vorschriften erfolgen, ist zu beachten, dass diesen lediglich
      eine klarstellende Bedeutung zukommt. Es gelten die gesetzlichen Vorschriften - auch wenn keine entsprechende
      Klarstellung erfolgt ist - in den Grenzen, in denen sie nicht durch die Allgemeinen Geschäftsbedingungen
      abgeändert oder ausgeschlossen werden.
    </p>
    <br />
    <br />

    <h2 className="font-semibold">2. Angebot und Vertragsabschluss</h2>
    <br />
    <p>
      2.1 Unsere Angebote sind freibleibend und unverbindlich. Dies gilt auch für die Nutzung der Software "PhysioScan".
      An allen im Zusammenhang mit der Auftragserteilung dem Nutzer überlassenen Unterlagen behalten wir uns Eigentums-
      und Urheberrechte vor. Diese Unterlagen dürfen Dritten nicht zugänglich gemacht werden, es sei denn, wir erteilen
      dazu dem Nutzer unsere ausdrückliche schriftliche Zustimmung.
    </p>
    <br />
    <p>
      2.2 Die Bestellung der Nutzung von "PhysioScan" durch den Nutzer stellt ein unverbindliches Vertragsangebot dar.
      Wir sind berechtigt, dieses Vertragsangebot innerhalb von zwei Wochen nach dessen Zugang bei uns anzunehmen.
    </p>
    <br />
    <p>
      2.3 Die Annahme des Vertragsangebots des Nutzers kann entweder schriftlich (z. B. durch eine Auftragsbestätigung)
      oder durch Bereitstellung des Zugangs zur Nutzung von "PhysioScan" erklärt werden. Sofern wir das Angebot des
      Nutzers nicht innerhalb der Frist von Ziffer 2.2 annehmen, sind an den Nutzer übermittelte Unterlagen unverzüglich
      an uns zurückzusenden.
    </p>
    <br />
    <br />

    <h2 className="font-semibold">3. Leistungserbringung</h2>
    <br />
    <p>
      3.1 Die ScienceMed GmbH handelt eigenverantwortlich und weisungsfrei. Die ScienceMed GmbH kann ihre
      Dienstleistungen frei auf dem Markt anbieten, insbesondere auch gegenüber Wettbewerbern des Nutzers.
    </p>
    <br />
    <p>
      3.2 Die Leistungserbringung erfolgt auf Grundlage einer gegenseitigen, vertrauensvollen Zusammenarbeit. Dabei
      informieren sich die Vertragsparteien wechselseitig unverzüglich über alle Umstände, die für die Durchführung des
      Auftrags von Bedeutung sind. Nach Vertragsabschluss erfolgt eine Kontaktaufnahme durch uns, um die Einzelheiten
      der Leistungserfüllung abzustimmen.
    </p>
    <br />
    <p>
      3.3 Die Leistungserbringung erfolgt anhand der vom Nutzer rechtzeitig und unaufgefordert zur Verfügung zu
      stellenden Informationen, Unterlagen, Daten oder sonstigen erforderlichen Mitwirkungshandlungen. Der Nutzer hat
      seine Angaben ausschließlich und eigenverantwortlich auf Richtig- und Vollständigkeit zu überprüfen. Die
      Interpretation, Auswertung, Weiterverwendung etc. der von uns empfohlenen Maßnahmen liegen allein im
      Verantwortungs- und Risikobereich des Kunden und unterliegen ausschließlich seiner Entscheidung.
    </p>
    <br />
    <p>
      3.4 Der ScienceMed GmbH ist ein sachkundiger Ansprechpartner zu benennen, welcher so zu bevollmächtigen ist, dass
      er alle erforderlichen Entscheidungen entweder selbst treffen oder zeitnah mit den Entscheidungsträgern abstimmen
      und veranlassen kann.
    </p>
    <br />
    <p>
      3.5 Die ScienceMed GmbH sendet Informationen i.d.R. an die vom Nutzer angegebene E-Mail-Adresse. Der Nutzer trägt
      Sorge für den regelmäßigen Abruf und die Konfigurierung von SPAM-Filter u.ä., um die Kenntnisnahme unserer E-Mails
      sicherzustellen.
    </p>
    <br />
    <p>
      3.6 Kann eine Partei ihren Verpflichtungen und Obliegenheiten aus von ihr nicht zu vertretenden Gründen nicht
      rechtzeitig nachkommen, wie zum Beispiel bei höherer Gewalt(insbesondere Krieg, Naturkatastrophen) oder sonstigen
      zum Zeitpunkt des Vertragsabschlusses nicht vorhersehbare Ereignissen (wie z.B. rechtmäßige Streiks und
      Aussperrungen, Erkrankung des Dienstleisters), verlängert sich die Leistungszeit entsprechend angemessen bzw.
      können vereinbarte Termine verschoben werden. Im Fall der Unmöglichkeit oder nicht nur vorübergehenden
      Leistungsverhinderung kann von beiden Vertragsparteien der Rücktritt erklärt werden. Die Parteien informieren sich
      gegenseitig über sich abzeichnende Leistungsverzögerungen und -ausfälle und stimmen einen Ersatztermin ab.
    </p>
    <br />
    <p>
      3.7 Die ScienceMed GmbH ist berechtigt, nach eigenem Ermessen im üblichen Umfang Unterauftragnehmer einzuschalten,
      insb. im Fall der Verhinderung bei der Leistungserbringung (z.B. durch Erkrankung des Dozenten bei Schulungen). Es
      wird keine persönliche Erbringung durch eine spezielle Person vereinbart.
    </p>
    <br />
    <p>
      3.8 Die ScienceMed GmbH schuldet bei der Erbringung der vereinbarten Dienstleistung ohne ausdrückliche Zusage
      keinen bestimmten Arbeitserfolg.
    </p>
    <br />
    <p>
      3.9 Sofern nicht anders vereinbart, stellt der Nutzer für Inhouse-Schulungen die Räumlichkeiten in ausreichender
      Größe und mit erforderlicher technischer und möblierter Ausstattung einschließlich sanitärer Einrichtung für die
      zu erwartende Teilnehmerzahl zur Verfügung sowie die ausreichende Bewirtung der Teilnehmer sicher. Die Nutzung
      durch uns erfolgt kostenfrei.
    </p>
    <br />
    <br />

    <h2 className="font-semibold">4. Teilnahme am Schulungen</h2>
    <br />
    <p>
      4.1 Die Teilnahme an Schulungen erfolgt vollständig in eigener Verantwortung des Teilnehmers. Jeder Teilnehmer
      sollte vor der Teilnahme seinen Gesundheitszustand selbst einschätzen und gegebenenfalls ärztlichen Rat einholen,
      um zu bestimmen, ob er für die Teilnahme geeignet ist.
    </p>
    <br />
    <p>
      4.2 Die ScienceMed GmbH übernimmt keine Haftung für Verletzungen oder Gesundheitsschäden, die Teilnehmern während
      der Kurse entstehen könnten, es sei denn, diese resultieren aus vorsätzlichem oder grob fahrlässigem Verhalten
      seitens der ScienceMed GmbH oder ihrer Vertreter.
    </p>
    <br />
    <p>
      4.3 Mit der Teilnahme an der Schulung erklärt sich der Teilnehmer damit einverstanden, alle Risiken, die mit der
      Teilnahme am Kurs verbunden sind, zu übernehmen und die ScienceMed GmbH sowie dessen Vertreter von allen
      Ansprüchen, Verlusten oder Schäden freizustellen, die in Verbindung mit der Teilnahme entstehen könnten.
    </p>
    <br />
    <p>
      4.4 Stornierungsbedingungen für Inhouse-Schulungstermine: Da die Organisation unserer Schulungen mit signifikanten
      Kosten verbunden sind, insbesondere wenn es um die Buchung und Vorauszahlung von Reise- und Unterkunftskosten für
      unsere Seminarleiter geht, gelten folgende Stornierungsbedingungen:
    </p>
    <br />
    <ol className="list-lower-latin">
      <li>
        Stornierungsgebühren: Für Stornierungen, die weniger als 5 Tage vor dem geplanten Termin vorgenommen werden,
        erhebt die ScienceMed GmbH eine Stornierungsgebühr von 150€. Diese Gebühr soll die Kosten für die Planung und
        Vorbereitung der Schulung decken.
      </li>
      <li>
        Verauslagte Kosten: Für Kosten, die bereits für Reise und Unterkunft der Dozenten verauslagt wurden und nicht
        rückerstattet werden können, wird unabhängig vom Zeitpunkt der Stornierung eine Gebühr von 100% dieser Kosten
        erhoben. Kunden werden dazu angehalten, bei der Buchung präzise Informationen über die Höhe der anfallenden
        Kosten zu erfragen.
      </li>
      <li>
        Stornierung innerhalb von 48 Stunden: Erfolgt eine Stornierung weniger als 48 Stunden vor dem geplanten Termin,
        wird Stornierungsgebühr von 650€ zzg. verauslagter Kosten in Rechnung gestellt. Diese Regelung soll
        sicherstellen, dass die ScienceMed GmbH in der Lage ist, die mit der kurzfristigen Stornierung verbundenen
        Verluste zu decken.
      </li>
    </ol>
    <br />
    <p>
      Durch die Buchung eines Schulungstermins erklärt sich der Kunde mit den oben genannten Stornierungsbedingungen
      einverstanden und erkennt deren Notwendigkeit im Rahmen der organisatorischen Vorbereitung und Planungssicherheit
      an.
    </p>
    <br />
    <br />

    <h2 className="font-semibold">5. Preise und Zahlungsvereinbarungen</h2>
    <br />
    <p>
      5.1 Die Kosten für die Nutzung der Software sowie für Onboarding und Schulung sind gemäß den zum Zeitpunkt des
      Vertragsschlusses aktuellen Preisen festgelegt. Diese Preise verstehen sich zuzüglich der gesetzlichen
      Umsatzsteuer in der am Tag der Rechnungslegung geltenden gesetzlichen Höhe.
    </p>
    <br />
    <p>
      5.2 Die vereinbarte Vergütung gilt für den vereinbarten Leistungsumfang. Mehr- oder Sonderleistungen sind
      zusätzlich zu vergüten und werden gesondert in Rechnung gestellt.
    </p>
    <br />
    <p>
      5.3 Die Zahlung des vereinbarten Betrags hat ausschließlich auf das Konto der ScienceMed GmbH zu erfolgen.
      Skontoabzüge sind nur bei ausdrücklicher schriftlicher Vereinbarung zulässig. <br />
      Bei Erteilung eines SEPA-Basis- oder eines SEPA-Firmenlastschrift-Mandats erfolgt der Einzug der Lastschrift mit
      Fälligkeit der Rechnung. Die Frist für die Vorabankündigung (Pre-Notification) wird dabei auf 1 Tag verkürzt. Der
      Kunde hat für eine ausreichende Deckung des Kontos zum Zeitpunkt der Fälligkeit zu sorgen. Bei Nichteinlösung oder
      Rückbuchung der Lastschrift hat der Kunde die entstehenden Kosten zu erstatten, soweit diese vom Kunden zu
      vertreten sind.
    </p>
    <br />
    <p>
      5.4 Sofern nichts anderes vereinbart wurde, ist der vereinbarte Betrag sofort ab Rechnungsstellung fällig und zu
      zahlen. Die ScienceMed GmbH behält sich das Recht vor,auch im Rahmen einer laufenden Geschäftsbeziehung
      Lieferungen ganz oder teilweise nur gegen Vorkasse durchzuführen.
    </p>
    <br />
    <p>
      5.5 Gerät der Nutzer mit der Zahlung in Verzug, ist er verpflichtet, Verzugszinsen in Höhe von acht Prozentpunkten
      über dem Basiszinssatz gemäß § 288 Absatz 2 BGB zu zahlen. Die Geltendmachung eines weiteren Verzugsschadens
      bleibt vorbehalten.
    </p>
    <br />
    <p>
      5.6 Im Falle einer Zahlungseinstellung, eines Insolvenzantrags oder einer fruchtlosen Vollstreckung gegen den
      Kunden werden alle Rechnungsforderungen sofort fällig.
    </p>
    <br />
    <p>
      5.7 Bei Zahlungsunfähigkeit des Nutzers behalten wir uns das Recht vor, nach den gesetzlichen Vorschriften zur
      Leistungsverweigerung und gegebenenfalls zum Rücktritt vom Vertrag berechtigt zu sein.
    </p>
    <br />
    <p>
      5.8 Die Rechnungslegung kann auch elektronisch per E-Mail unter Beachtung der gesetzlichen Anforderungen erfolgen.
    </p>
    <br />
    <p>
      5.9 Einwände gegen die Rechnungsstellung sind innerhalb von sechs Wochen nach Erhalt der Rechnung schriftlich
      geltend zu machen. Ansonsten gilt die Rechnung als anerkannt.
    </p>
    <br />
    <br />

    <h2 className="font-semibold">6. Kostenstruktur und Preisanpassungen:</h2>
    <br />
    <p>
      6.1 Grundlage der Kostenberechnung: Die Preise für die von der ScienceMed GmbH erbrachten Leistungen basieren auf
      der zum Zeitpunkt des Vertragsschlusses gültigen Preisliste. Diese Preisliste reflektiert die aktuellen
      Marktbedingungen und die Kosten, die mit der Erbringung der vereinbarten Leistungen verbunden sind.
    </p>
    <br />
    <p>
      6.2 Bedingungen für Preisanpassungen: Die ScienceMed GmbH behält sich das Recht vor, die Preise angemessen
      anzupassen, sollte es zu signifikanten Veränderungen in den Kostenfaktoren kommen, die die Preisgestaltung
      beeinflussen. Solche Faktoren umfassen unter anderem signifikante Änderungen in den Kosten für Waren oder
      Dienstleistungen, Lizenzgebühren, Wartungsgebühren, Servergebühren, Materialkosten, Lohnkosten, Kosten für
      Zusatzleistungen oder gesetzlichen Vorgaben.
    </p>
    <br />
    <p>
      6.3 Verfahren bei Preisanpassungen: Im Falle einer notwendigen Preisanpassung verpflichtet sich die ScienceMed
      GmbH, den Nutzer mindestens 30 Tage vor dem geplanten Inkrafttreten der neuen Preise schriftlich zu informieren.
      Die Mitteilung beinhaltet die Gründe für die Preisanpassung sowie eine Übersicht über die angepassten Preise.
    </p>
    <br />
    <p>
      6.4 Recht auf Vertragskündigung: Sollte der Nutzer mit den angepassten Preisen nicht einverstanden sein, hat er
      das Recht, den Vertrag unter den bisherigen Bedingungen innerhalb von 14 Tagen nach Erhalt der Mitteilung über die
      Preisanpassung zu kündigen. Erfolgt keine Kündigung innerhalb dieser Frist, werden die angepassten Preise als
      akzeptiert betrachtet.
    </p>
    <br />
    <p>
      6.5 Wirksamkeit von Preisanpassungen: Preisanpassungen werden nicht rückwirkend angewandt und finden keine
      Anwendung auf Leistungen, die vor dem Datum des Inkrafttretens der neuen Preise bereits bezahlt oder in Rechnung
      gestellt wurden.
    </p>
    <br />
    <br />

    <h2 className="font-semibold">7. Kündigung</h2>
    <br />
    <p>
      7.1 Ordentliche Kündigung: Dieser Vertrag kann von jeder der Parteien mit einer Frist von 30 Tagen vor Ablauf des
      jeweiligen Vertragsjahres ordentlich gekündigt werden. Erfolgt keine rechtzeitige Kündigung, verlängert sich der
      Vertrag automatisch um ein weiteres Jahr.
    </p>
    <br />
    <p>
      7.2 Außerordentliche Kündigung: Eine fristlose Kündigung dieses Vertrags ist unter folgenden Umständen möglich:
    </p>
    <br />
    <ul>
      <li>
        sofern der Kunde für zwei aufeinander folgende Fälligkeitstermine mit der Bezahlung der geschuldeten Vergütung
        oder eines nicht unerheblichen Teils der Vergütung in Verzug gerät,
      </li>
      <li>
        bei wesentlicher Verschlechterung der Vermögensverhältnisse des Kunden, insbesondere bei Zahlungseinstellung,
        fruchtloser Vollstreckungen gegen den Kunden, Stellung eines Insolvenzantrags oder Eröffnung des
        Insolvenzverfahrens oder deren Ablehnung mangels Masse.
      </li>
    </ul>
    <br />
    <p>
      7.3 Form der Kündigung: Rechtserhebliche Erklärungen sowie Anzeigen, einschließlich der Kündigung dieses Vertrags,
      sind ausschließlich schriftlich per Post an die Geschäftsführung der ScienceMed GmbH adressiert abzugeben. Andere
      Formen der Kommunikation, wie beispielsweise E-Mail oder SMS, werden nicht als gültige Form der
      Kündigungserklärung akzeptiert.
    </p>
    <br />
    <p>
      7.4 Sonderkündigungsrecht: Das Recht beider Parteien zur Kündigung aus wichtigem Grund, unberührt von den
      vorherigen Bestimmungen, bleibt hiervon unberührt. Ein wichtiger Grund liegt insbesondere dann vor, wenn die
      Fortsetzung des Vertragsverhältnisses bis zum Ablauf der ordentlichen Kündigungsfrist oder bis zur vereinbarten
      Beendigung des Vertrags für eine Partei unzumutbar ist.
    </p>
    <br />
    <br />

    <h2 className="font-semibold">8. Kündigung des Support-Pakets</h2>
    <br />
    <p>8.1 Der Kunde hat das Recht, das monatliche Support-Paket jederzeit zu kündigen.</p>
    <br />
    <p>
      8.2 Die Kündigung des monatlichen Support-Pakets muss schriftlich erfolgen und an die Geschäftsführung der
      ScienceMed GmbH gerichtet sein.
    </p>
    <br />
    <p>
      8.3 Die Kündigung wird zum Ende des laufenden Abrechnungszeitraums wirksam, nachdem die ScienceMed GmbH die
      schriftliche Kündigung erhalten hat.
    </p>
    <br />
    <p>
      8.4 Der Kunde bleibt bis zum Ende des laufenden Abrechnungszeitraums berechtigt, den Support-Service in Anspruch
      zu nehmen und die entsprechenden Gebühren zu entrichten.
    </p>
    <br />
    <p>
      8.5 Nach Wirksamwerden der Kündigung werden keine weiteren Gebühren für das monatliche Support-Paket berechnet,
      und der Kunde hat keinen Anspruch mehr auf die in diesem Zeitraum angebotenen Support-Leistungen.
    </p>
    <br />
    <br />

    <h2 className="font-semibold">9. Zurückbehaltungsrechte</h2>
    <br />
    <p>
      9.1 Der Nutzer hat das Recht auf Aufrechnung oder Zurückbehaltung nur dann, wenn sein Anspruch rechtskräftig
      festgestellt oder unbestritten ist und sein Gegenanspruch auf dem gleichen Vertragsverhältnis beruht. Etwaige
      Mängel bei der Lieferung beeinträchtigen nicht die Gegenrechte des Nutzers.
    </p>
    <br />
    <br />

    <h2 className="font-semibold">10. Lieferfrist und Lieferverzug</h2>
    <br />
    <p>
      10.1 Die Lieferfrist wird individuell vereinbart oder von der ScienceMed GmbH bei Annahme der Bestellung
      angegeben.
    </p>
    <br />
    <p>
      10.2 Sollte die ScienceMed GmbH vertraglich vereinbarte Lieferfristen aufgrund von Umständen, die sie nicht zu
      vertreten hat, nicht einhalten können, wird der Nutzer unverzüglich über diese Verzögerung informiert,
      gleichzeitig wird eine neue voraussichtliche Lieferfrist mitgeteilt. Falls eine verspätete Lieferung auch
      innerhalb der neu bekanntgegebenen Lieferfrist nicht möglich ist, behält sich die ScienceMed GmbH das Recht vor,
      ganz oder teilweise vom Vertrag zurückzutreten. Bereits geleistete Zahlungen des Nutzers werden in diesem Fall
      unverzüglich erstattet. Nichtverfügbarkeit der Leistung kann beispielsweise vorliegen, wenn ein technisches oder
      regulatorisches Problem vorliegt, ein kongruentes Deckungsgeschäft abgeschlossen wurde, sonstige Störungen
      auftreten (z. B. aufgrund höherer Gewalt) oder die ScienceMed GmbH im Einzelfall nicht zur Beschaffung
      verpflichtet ist.
    </p>
    <br />
    <p>
      10.3 Ein Lieferverzug seitens der ScienceMed GmbH als Anbieter der Software richtet sich nach den gesetzlichen
      Vorschriften. Voraussetzung für einen Lieferverzug seitens der ScienceMed GmbH ist jedoch eine postalische Mahnung
      durch den Nutzer. Im Falle eines Lieferverzugs kann der Nutzer pauschalierten Ersatz seines Verzugsschadens
      geltend machen. Die Schadenspauschale beträgt für jede vollendete Kalenderwoche des Verzugs 0,5% des Nettopreises
      (Nutzungsgebühr), jedoch insgesamt höchstens 5% des Gesamtwerts der verspäteten Scans. Die ScienceMed GmbH behält
      sich das Recht vor,einen Nachweis darüber zu erbringen, dass dem Nutzer kein Schaden oder nur ein geringerer
      Schaden als die genannte Pauschale entstanden ist.
    </p>
    <br />
    <p>
      10.4 Die Rechte des Nutzers sowie die gesetzlichen Rechte der ScienceMed GmbH, insbesondere im Falle eines
      Ausschlusses der Leistungspflicht, bleiben unberührt.
    </p>
    <br />
    <p>10.5 Nutzung, Risikoübergang, Abnahme, Verzögerung der Annahme</p>
    <br />
    <p>
      10.6 Die Nutzung der Dienstleistung erfolgt ab dem Zeitpunkt, an dem der erste Scan durchgeführt wird. Die
      Dienstleistung wird von den Servern der ScienceMed GmbH bereitgestellt. Falls der Nutzer die Ergebnisse an einen
      anderen Bestimmungsort übertragen möchte (z. B. in ein internes System), trägt er die hiermit verbundenen Kosten.
      Falls nichts anderes vertraglich vereinbart wurde, behält sich ScienceMed das Recht vor, den Übertragungsweg (z.
      B. Datenträger, Datenübertragung) selbst zu bestimmen.
    </p>
    <br />
    <p>
      10.7 Mit der Durchführung des Scans und der Bereitstellung der Ergebnisse geht das Risiko des zufälligen
      Untergangs und der zufälligen Verschlechterung auf den Nutzer über. Falls eine Abnahme der Ergebnisse vereinbart
      wurde, ist diese für den Risikoübergang maßgeblich. Gesetzliche Vorschriften des Dienstleistungsrechts bleiben
      davon unberührt. Die Abnahme der Ergebnisse gilt auch dann als erfolgt, wenn der Nutzer im Verzug der Annahme ist.
    </p>
    <br />
    <p>
      10.8 Falls sich der Nutzer im Verzug der Annahme befindet oder die Bereitstellung der Ergebnisse aus anderen, vom
      Nutzer zu vertretenden Gründen verzögert wird, behält sich die ScienceMed GmbH das Recht auf Ersatz des
      entstandenen Schadens vor, einschließlich Mehraufwendungen wie Speicherkosten.
    </p>
    <br />
    <br />

    <h2 className="font-semibold">11. Hinweise zur Datenverarbeitung</h2>
    <br />
    <p>
      11.1 Die uns übermittelten persönlichen Daten werden zur Auftragsbearbeitung und Vertragsabwicklung unter
      Beachtung der Datenschutzbestimmungen (§ 28 BDSG) erhoben, gespeichert und ggf., soweit erforderlich, an Dritte
      (wie z.B. das beauftragte Transportunternehmen und Kreditinstitut) weitergegeben. Eine Weitergabe kann auch
      erfolgen, wenn wir auf Anforderung einer staatlichen Einrichtung im Rahmen zwingender nationaler
      Rechtsvorschriften oder auf Grund einer gerichtlichen Entscheidung hierzu verpflichtet sind oder die Weitergabe
      zur Durchsetzung unserer Rechte bei Missbrauch und Geltendmachung von Forderungen dient.
    </p>
    <br />
    <p>11.2 Wir speichern die Daten bis zum Ablauf der steuer- und handelsrechtlichen Aufbewahrungsfristen.</p>
    <br />
    <p>
      11.3 Der Kunde hat das Recht auf unentgeltliche Auskunft über gespeicherte personenbezogene Daten, deren Erhebung,
      Verarbeitung oder Nutzung sowie ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten.
    </p>
    <br />
    <br />

    <h2 className="font-semibold">12. Eigentumsvorbehalt und Datenlöschung</h2>
    <br />
    <p>
      12.1 Die ScienceMed GmbH behält sich das Recht vor, die Erbringung der Dienstleistungen einzustellen oder den
      Zugang zur Software zu sperren, falls der Nutzer seinen Zahlungsverpflichtungen nicht nachkommt oder anderweitig
      gegen vertragliche Vereinbarungen verstößt.
    </p>
    <br />
    <p>
      12.2 Jegliche Ansprüche des Nutzers auf Eigentum an der Software oder den erbrachten Dienstleistungen werden
      hiermit ausgeschlossen. Die Software wird dem Nutzer lediglich zur Nutzung gemäß den Bestimmungen des Vertrags
      überlassen.
    </p>
    <br />
    <p>
      12.3 Die ScienceMed GmbH behält sich das Recht vor, Daten und Inhalte des Nutzers im Rahmen der Vertragsbeziehung
      zu speichern und zu verarbeiten. Der Nutzer räumt der ScienceMed GmbH hiermit das Recht ein, die vom Nutzer
      bereitgestellten Daten und Inhalte im Rahmen der vertraglichen Vereinbarungen zu nutzen.
    </p>
    <br />
    <p>
      13.4 Die ScienceMed GmbH behält sich das Recht vor, die Nutzungsbedingungen und Funktionen der Software zu
      aktualisieren oder zu ändern, um die Qualität der Dienstleistungen zu verbessern oder gesetzlichen Anforderungen
      nachzukommen. Der Nutzer wird über derartige Änderungen rechtzeitig informiert.
    </p>
    <br />
    <p>
      14.5 Bei Beendigung des Vertragsverhältnisses hat der Nutzer keinen Anspruch auf weitere Nutzung der Software oder
      der erbrachten Dienstleistungen, es sei denn, dies ist ausdrücklich vereinbart. Die ScienceMed GmbH behält sich
      das Recht vor, den Zugang des Nutzers zur Software nach Beendigung des Vertragsverhältnisses zu sperren und alle
      damit verbundenen Daten und Inhalte zu löschen.
    </p>
    <br />
    <p>
      14.6 Bei vertragswidrigem Verhalten des Nutzers, insbesondere bei Nichtzahlung des fälligen Entgelts, ist die
      ScienceMed GmbH berechtigt, vom Vertrag zurückzutreten oder die Dienstleistungen aufgrund des Eigentumsvorbehalts
      einzufordern.
    </p>
    <br />
    <p>
      14.7 Die Herausgabeforderung enthält keine Rücktrittserklärung; wir behalten uns lediglich das Recht vor, die
      Dienstleistungen einzufordern und den Rücktritt vorzubehalten. Vor Geltendmachung dieser Rechte muss dem Nutzer
      eine angemessene Zahlungsfrist gesetzt worden sein, sofern gesetzlich nicht anders vorgesehen.
    </p>
    <br />
    <p>
      14.8 Im Falle von Zahlungsausständen des Nutzers ist die ScienceMed GmbH berechtigt, den Zugang des Nutzers zur
      Software zu sperren und alle damit verbundenen Daten und Inhalte nach einer angemessenen Frist zu löschen.
    </p>
    <br />
    <p>
      14.9 Die angemessene Frist zur Begleichung offener Zahlungen und Vermeidung der Datenlöschung beträgt 30 Tage.
      Nach Ablauf dieser Frist behält sich die ScienceMed GmbH das Recht vor, alle Daten und Inhalte des Nutzers zu
      löschen, ohne dass der Nutzer hierdurch Ansprüche auf Schadensersatz oder sonstige Entschädigungen geltend machen
      kann.
    </p>
    <br />
    <p>
      14.10 Die ScienceMed GmbH ist nicht verpflichtet, den Nutzer vor der Löschung seiner Daten und Inhalte zu warnen.
      Es liegt in der Verantwortung des Nutzers, seineZahlungsverpflichtungen rechtzeitig zu erfüllen, um die Löschung
      seiner Daten zu verhindern.
    </p>
    <br />
    <p>
      14.11 Bis zum Widerruf ist der Nutzer befugt, die Dienstleistungen im ordnungsgemäßen Rahmen weiterzuverwenden
      oder zu nutzen. In diesem Fall gelten die nachfolgenden Bestimmungen:
    </p>
    <br />
    <ol className="list-lower-latin">
      <li>
        Der Nutzer tritt uns bereits jetzt sämtliche Forderungen ab, und zwar in Höhe des vereinbarten Entgelts. Diese
        Abtretung nehmen wir an. Die Pflichten des Nutzers gelten auch für die abgetretenen Forderungen.
      </li>
      <li>
        Der Nutzer ist zur Einziehung der abgetretenen Forderungen berechtigt, solange er seinen Zahlungsverpflichtungen
        nachkommt und keine Mangelhaftigkeit seiner Leistungsfähigkeit vorliegt. Im Falle der Ausübung eines Rechts
        können wir vom Nutzer verlangen, die abgetretenen Forderungen und deren Schuldner offenzulegen, alle
        erforderlichen Angaben zu machen, die Unterlagen auszuhändigen und den Schuldnern die Abtretung mitzuteilen. Wir
        behalten uns auch das Recht vor, die Befugnis zur Nutzung der Dienstleistungen unter Eigentumsvorbehalt zu
        widerrufen.
      </li>
    </ol>
    <br />
    <br />

    <h2 className="font-semibold">15. Urheberrecht</h2>
    <br />
    <p>
      15.1 Sämtliche Urheber- und Leistungsschutzrechte an unseren Leistungen und Materialien (wie z.B. Bilder, Fotos,
      Texte, Zeichnungen, Animationen, Unterrichts-/Trainingsmaterialien und -inhalte, elektronisch gespeicherte
      Präsentationen, Ton- und Bildaufzeichnungen etc.) liegen bei uns. Der Nutzer wird diese beachten, nicht verletzen
      und insbesondere unterlassen, sie ohne unsere Zustimmung Dritten zugänglich zu machen, zu vervielfältigen oder zu
      veröffentlichen oder Schutzrechtsanmeldungen hierüber vorzunehmen.
    </p>
    <br />
    <p>
      15.2 An Angeboten, Abbildungen, Zeichnungen, Mustern, Katalogen, Prospekten, Berichten, Konzepten, Entwürfen,
      Aufstellungen und Unterlagen für Seminare, Kurse u.ä. sowie anderen Unterlagen, die dem Kunden im Rahmen der
      Vertragsanbahnung sowie -durchführung – gleich in welcher Form – zur Verfügung gestellt wurden, behalten wir uns
      das Eigentum sowie die Urheberrechte vor. Sie dürfen ohne unsere Zustimmung Dritten nicht zugänglich gemacht,
      vervielfältigt oder veröffentlicht werden. Auf unser Verlangen sind sie samt jeglicher Kopien unverzüglich
      zurückzugeben oder zu vernichten (einschließlich Löschen von den Computersystemen und Speichereinheiten), wenn sie
      vom Kunden im ordnungsgemäßen Geschäftsgang nicht mehr benötigt werden; im Fall der Vernichtung/Löschung ist diese
      unverzüglich schriftlich zu bestätigen.
    </p>
    <br />
    <br />

    <h2 className="font-semibold">16. Geheimhaltung</h2>
    <br />
    <p>
      16.1 Der Kunde verpflichtet sich, den Vertragsinhalt sowie alle im Rahmen der geschäftlichen Beziehung zur
      ScienceMed GmbH erfahrenen Geschäfts- und Betriebsgeheimnisse, und sonstigen als vertraulich gekennzeichneten
      Informationen oder solche Informationen, die nach den Umständen ihrer Weitergabe oder ihrem Inhalt nach als
      vertraulich anzusehen sind, streng vertraulich zu behandeln. Dies umfasst insbesondere die Verpflichtung, solche
      Informationen nicht ohne die ausdrückliche schriftliche Zustimmung der ScienceMed GmbH Dritten zugänglich zu
      machen.
    </p>
    <br />
    <p>
      16.2 Für den Fall, dass der Kunde gegen diese Geheimhaltungsverpflichtungen verstößt, vereinbaren die Parteien
      bereits jetzt, dass die ScienceMed GmbH berechtigt ist, eine angemessene Vertragsstrafe zu fordern, deren Höhe im
      Einzelfall von der ScienceMed GmbH festzusetzen und im Streitfall vom zuständigen Gericht zu überprüfen ist.
      Darüber hinaus ist der Kunde bei einem solchen Verstoß zum Ersatz des hierdurch entstehenden Schadens
      verpflichtet. Dies schließt nicht die Geltendmachung weiterer Schadensersatzansprüche aus.
    </p>
    <br />
    <p>
      16.3 Zusätzlich behält sich die ScienceMed GmbH das Recht vor, bei drohenden oder erfolgten Verstößen gegen die
      Geheimhaltungsvereinbarung gerichtliche Unterlassungsansprüche geltend zu machen, um die weitere Verbreitung
      vertraulicher Informationen zu verhindern.
    </p>
    <br />
    <p>
      16.4 Die Verpflichtungen zur Geheimhaltung gelten auch nach Beendigung der Geschäftsbeziehung zwischen dem Kunden
      und der ScienceMed GmbH fort.
    </p>
    <br />
    <br />

    <h2 className="font-semibold">17. Verantwortung für Updates und geeignete Geräte:</h2>
    <br />
    <p>
      17.1 Updates: Der Nutzer verpflichtet sich, alle von der ScienceMed GmbH bereitgestellten Updates für die App
      zeitnah zu installieren. Dies ist notwendig, um die Sicherheit, Leistung und Funktionalität der App
      aufrechtzuerhalten und zu verbessern. Der Nutzer erkennt an, dass die Nutzung veralteter Versionen der App zu
      einer suboptimalen Leistung führen kann und in einigen Fällen Sicherheitsrisiken bergen könnte.
    </p>
    <br />
    <p>
      17.2 Geeignete Geräte: Es liegt in der Verantwortung des Nutzers, sicherzustellen, dass die Geräte, auf denen die
      App "PhysioScan" installiert und genutzt wird, den technischen Anforderungen der App entsprechen. Die ScienceMed
      GmbH stellt die notwendigentechnischen Spezifikationen und Systemanforderungen zur Verfügung. Der Nutzer muss
      diese Anforderungen regelmäßig überprüfen und seine Geräte entsprechend anpassen, um die Kompatibilität und
      optimale Funktionalität der App sicherzustellen.
    </p>
    <br />
    <p>
      17.3 Konsequenzen bei Nichteinhaltung: Der Nutzer ist sich bewusst, dass die Nichtbeachtung dieser Verpflichtungen
      zu einer beeinträchtigten Funktionalität der App führen kann und in solchen Fällen die ScienceMed GmbH keine
      Haftung für daraus resultierende Leistungseinbußen oder Schäden übernimmt. Die Verantwortung für die Behebung von
      Problemen, die aus der Nutzung nicht kompatibler Geräte oder nicht aktualisierter App-Versionen resultieren, liegt
      ausschließlich beim Nutzer.
    </p>
    <br />
    <p>
      17.4 Unterstützung: Die ScienceMed GmbH stellt Support zur Durchführung von Updates sowie Informationen zu den
      technischen Anforderungen der App zur Verfügung.
    </p>
    <br />
    <br />

    <h2 className="font-semibold">18. Mängelansprüche des Nutzers</h2>
    <br />
    <p>
      18.1 Die Rechte des Nutzers bei Sach- und Rechtsmängeln der Software, einschließlich Falsch- und Minderleistungen
      sowie unsachgemäßer Ausführung oder mangelhafter Anleitungen, unterliegen den gesetzlichen Vorschriften, sofern
      nicht anders festgelegt.
    </p>
    <br />
    <p>
      18.2 Wir haften nur gemäß ausdrücklicher Beschaffenheitsvereinbarung. Wir übernehmen keine Haftung für öffentliche
      Äußerungen des Herstellers oder Dritter. Bei der Software handelt es sich um eine Beta-Version.
    </p>
    <br />
    <p>
      18.3 Wir haften nicht für Mängel, die dem Nutzer bei Vertragsschluss bekannt waren oder grob fahrlässig unbekannt
      blieben.
    </p>
    <br />
    <p>
      18.4 Mängelansprüche des Nutzers bestehen nur, wenn er seinen Untersuchungs- und Anzeigepflichten nachkommt.
      Offensichtliche Mängel sind innerhalb von 5 Arbeitstagen nach Bereitstellung der Software schriftlich anzuzeigen.
      Der Kunde ist verpflichtet, eine detaillierte Beschreibung der aufgetretenen Probleme oder Fehler bereitzustellen
      sowie alle relevantenInformationen zur Verfügung zu stellen, um eine effektive Prüfung durch den Anbieter zu
      ermöglichen.
    </p>
    <br />
    <p>
      18.5 Wir haben das Recht zur Nacherfüllung durch Nachbesserung oder Neuverrichtung. Die Art der Nacherfüllung kann
      vom Nutzer verweigert werden, falls sie unzumutbar ist.
    </p>
    <br />
    <p>
      18.6 Der Nutzer ist verpflichtet, dem Anbieter angemessene Zeit und Gelegenheit zur Nacherfüllung zu geben. Hierzu
      gehört auch, dass der Nutzer dem Anbieter alle erforderlichen Informationen zur Verfügung stellt, um den Mangel zu
      beheben. Sollte eine Korrektur erforderlich sein, wird der Anbieter die notwendigen Schritte zur Behebung des
      Mangels einleiten.
    </p>
    <br />
    <p>
      18.7 Die Aufwendungen für Prüfung und Nacherfüllung erstatten wir gemäß gesetzlicher Vorschriften und diesen
      Bedingungen. Bei unberechtigtem Mangelbeseitigungsverlangen können wir Kosten einfordern.
    </p>
    <br />
    <p>
      18.8 Bei erfolgloser Fristsetzung oder entbehrlicher Frist besteht das Recht zum Rücktritt gemäß den gesetzlichen
      Vorschriften.
    </p>
    <br />
    <p>
      18.9 Ansprüche auf Aufwendungsersatz sind grundsätzlich ausgeschlossen. Dies gilt insbesondere für
      Dienstleistungsverträge und Verträge über digitale Produkte, sofern nicht gesetzlich anders vorgeschrieben.
    </p>
    <br />
    <p>18.10 Schadensersatzansprüche des Nutzers bestehen nur gemäß Ziffer 19.</p>
    <br />
    <br />

    <h2 className="font-semibold">19. Sonstige Haftung</h2>
    <br />
    <p>
      19.1 Wir haften gemäß den gesetzlichen Maßgaben für Verletzungen von vertraglichen und außervertraglichen
      Pflichten, sofern nichts anderes aus diesen Bedingungen oder anderen Bestimmungen hervorgeht.
    </p>
    <br />
    <p>
      19.2 Wir haften für Schadensersatz nur bei Vorsatz und grober Fahrlässigkeit. Bei einfacher Fahrlässigkeit haften
      wir nur für Schäden an Leben, Körper oder Gesundheit und fürVerletzungen wesentlicher Vertragspflichten. Unsere
      Haftung ist in letzterem Fall auf den vorhersehbaren, typischerweise eintretenden Schaden begrenzt.
    </p>
    <br />
    <p>
      19.3 Diese Haftungsbeschränkungen gelten auch gegenüber Dritten und bei Pflichtverletzungen durch Personen, deren
      Verschulden wir zu vertreten haben.
    </p>
    <br />
    <p>
      19.4 Der Nutzer kann nur bei von uns zu vertretenden Pflichtverletzungen zurücktreten oder kündigen, die nicht aus
      einem Mangel resultieren.
    </p>
    <br />
    <br />

    <h2 className="font-semibold">20. Rechtswahl und Gerichtsstand</h2>
    <br />
    <p>
      20.1 Für alle Vertragsbeziehungen im Zusammenhang mit der Nutzung unserer Software gilt ausschließlich das Recht
      der Bundesrepublik Deutschland.
    </p>
    <br />
    <p>
      21.2 Für alle Streitigkeiten aus oder im Zusammenhang mit der Nutzung unserer Software oder den daraus
      resultierenden Vertragsbeziehungen ist ausschließlich der Gerichtsstand an unserem Geschäftssitz zuständig. Dies
      gilt auch, wenn der Kunde keinen allgemeinen Gerichtsstand in Deutschland hat oder sein Wohnsitz oder gewöhnlicher
      Aufenthalt zum Zeitpunkt der Klageerhebung nicht bekannt ist.
    </p>
    <br />
    <p>
      22.3 Unbeschadet der Regelung in Absatz 21.2 behalten wir uns das Recht vor, auch am Erfüllungsort der
      Dienstleistung oder am allgemeinen Gerichtsstand des Kunden Klage zu erheben. Dies gilt insbesondere für Fälle, in
      denen gesetzliche Vorschriften einen spezifischen Gerichtsstand vorsehen oder die Wahl des Gerichtsstands aufgrund
      der Art der Dienstleistung oder der Lizenzvereinbarung gerechtfertigt ist.
    </p>
    <br />
    <br />

    <h2 className="font-semibold">23. Gewährleistungsfristen</h2>
    <br />
    <p>Software und digitale Dienstleistungen:</p>
    <ul className="list-disc">
      <li>
        Für Unternehmen: Die Gewährleistungsfrist beträgt 1 Jahr ab dem Tag der Bereitstellung der Software bzw. der
        Erbringung der Dienstleistung. Innerhalb dieses Zeitraums verpflichten wir uns zur Behebung von Mängeln der
        Software oder der Dienstleistung, sofern diese nachweislich bereits zum Zeitpunkt der Bereitstellung bestanden
        haben.
      </li>
    </ul>
    <p> Physische Produkte</p>
    <ul className="list-disc">
      <li>Neue Artikel für Unternehmen: Die Gewährleistungsfrist beträgt 1 Jahr.</li>
      <li>
        Gebrauchte Artikel für Unternehmen: Es wird keine Gewährleistung übernommen, es sei denn, es wurde ausdrücklich
        etwas anderes vereinbart.
      </li>
    </ul>
    <br />
    <br />

    <h2 className="font-semibold">24. Erstattung für Nacherfüllungskosten</h2>
    <br />
    <p>
      Für unsere digitalen Produkte und Dienstleistungen übernehmen wir, der Dienstleister, die Kosten, die für die
      Nacherfüllung im Falle eines nachgewiesenen Mangels, der bereits zum Zeitpunkt der Bereitstellung bestand,
      erforderlich sind. Dies umfasst Kosten, die direkt mit der Behebung des Mangels verbunden sind, wie z.B. Kosten
      für die erneute Bereitstellungder Dienstleistung oder die Bereitstellung von Updates. Ein Ausschluss dieser
      Verpflichtung durch AGB ist nicht zulässig.
    </p>
    <br />
    <br />

    <h2 className="font-semibold">25. Haftung für Mängel</h2>
    <br />
    <p>
      Wir verpflichten uns, die Qualität und Funktionalität unserer bereitgestellten Software und Dienstleistungen zu
      gewährleisten. Sollten Mängel festgestellt werden, die bereits zum Zeitpunkt der Bereitstellung bestanden, bemühen
      wir uns um eine zeitnahe Behebung dieser Mängel. Unser Ziel ist es, die Zufriedenheit und das Vertrauen unserer
      Nutzer zu sichern.
    </p>
    <br />
    <br />

    <h2 className="font-semibold">26. Änderungen im Gewährleistungsrecht</h2>
    <br />
    <p>
      Die Gewährleistungsrechte für Mängel unserer digitalen Produkte und Dienstleistungen werden gemäß den aktuellen
      gesetzlichen Bestimmungen gehandhabt.
    </p>
    <br />
    <br />

    <h2 className="font-semibold">27. Beschränkung auf Nacherfüllung</h2>
    <br />
    <p>
      Bei Mängeln unserer digitalen Produkte oder Dienstleistungen steht zunächst das Recht auf Nacherfüllung im
      Vordergrund. Sollte die Nacherfüllung fehlschlagen, unzumutbar sein oder verweigert werden, sind wir bereit, in
      Übereinstimmung mit den gesetzlichen Bestimmungen und unter Berücksichtigung der spezifischen Umstände,
      angemessene alternative Lösungen zu finden
    </p>
    <br />
    <br />

    <h2 className="font-semibold">28. Haftungsbeschränkungen</h2>
    <br />
    <p>
      Unsere Haftung für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit sowie für andere
      Schäden, die auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung unsererseits beruhen, ist gemäß den
      gesetzlichen Vorschriften nicht beschränkt.
    </p>
    <br />
    <br />

    <h2 className="font-semibold">29. Verzugszinsen</h2>
    <br />
    <p>
      Im Falle des Zahlungsverzugs gelten die gesetzlichen Verzugszinsen. Für Geschäftsbeziehungen mit Unternehmen
      beträgt der Zinssatz 9 Prozentpunkte über dem Basiszinssatz. Die aktuellen Basiszinssätze können bei der Deutschen
      Bundesbank ermittelt werden.
    </p>
    <br />
    <br />
  </div>
);
