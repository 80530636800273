import { FC, PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchFirstAidKits, fetchPhotoTips } from '../redux/apis';
import { useDispatch } from '../redux/store';
import { LANGUAGE } from '../resources/enums';

export const DataLoader: FC<PropsWithChildren> = ({ children }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const language = (i18n.language || 'en') as LANGUAGE;

  useEffect(() => {
    dispatch(
      fetchPhotoTips({
        query: { lang: language },
        force: true,
      }),
    );
  }, [dispatch, language]);

  useEffect(() => {
    dispatch(
      fetchFirstAidKits({
        query: { lang: language },
      }),
    );
  }, [dispatch, language]);

  return <>{children}</>;
};
