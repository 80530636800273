import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Popup } from '../../components';

export interface PaperworkCompletedPopupProps {
  className?: string;
  onClose: VoidFunction;
}

export const PaperworkCompletedPopup: FC<PaperworkCompletedPopupProps> = ({ className, onClose }) => {
  const { t } = useTranslation();

  return (
    <Popup contentClass={classNames('text-typo1', className)} width="21rem" disableBackdrop>
      <h2 className="text-center">{t('patientList.paperworkCompletedPopup.title')}</h2>
      <Button className="mt-4" fullWidth onClick={onClose}>
        {t('common.close')}
      </Button>
    </Popup>
  );
};
