import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Popup } from '../../components';
import { ExerciseModel } from '../../resources/models';

export interface ExitTrainingPopupProps {
  className?: string;
  exercise: ExerciseModel;
  onExit(): void;
  onClose(): void;
}

export const ExitTrainingPopup: FC<ExitTrainingPopupProps> = ({ className, onExit, onClose }) => {
  const { t } = useTranslation();

  return (
    <Popup contentClass={classNames('text-typo1', className)} width="21rem" disableBackdrop onClose={onClose}>
      <h1>{t('patient.exitTraining')}</h1>
      <p className="mt-1">{t('patient.exitTrainingConfirmText')}</p>
      <Button className="mt-4" fullWidth onClick={onExit}>
        {t('common.exit')}
      </Button>
      <Button className="mt-4" variant="outline" fullWidth onClick={onClose}>
        {t('common.cancel')}
      </Button>
    </Popup>
  );
};
