import { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { GenderSelect, GenderSelectProps } from '../../form-controls';

export type RHFGenderSelectProps = Omit<GenderSelectProps, 'onChange'> & {
  name: string;
};

export const RHFGenderSelect: FC<RHFGenderSelectProps> = ({ name, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <GenderSelect
          {...field}
          value={field.value}
          onChange={(value) => field.onChange({ target: { value } })}
          {...other}
        />
      )}
    />
  );
};
