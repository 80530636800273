import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { IssueCard } from '../IssueCard';
import { UnlockScanButton } from '../UnlockScanButton';
import { HealthIcon } from '../../icons';
import { IssueModel, ScanModel } from '../../resources/models';

export interface ScanIssuesProps {
  className?: string;
  scan?: ScanModel;
  issues: IssueModel[];
  onViewIssue?: (issue: IssueModel) => void;
  onUnlock?: VoidFunction;
}

export const ScanIssues: FC<ScanIssuesProps> = ({ className, scan, issues, onViewIssue, onUnlock }) => {
  const { t } = useTranslation();

  const locked = useMemo(() => scan && !scan.isSubscribed, [scan]);

  return (
    <div className={classNames('flex flex-col gap-3', className)}>
      {!issues.length ? (
        <div className="card3 px-4 py-3">
          <div className="flex items-center">
            <HealthIcon className="text-typo1" size={24} />
            <h6 className="ml-3">{t('patient.healthIssues.noIssues')}</h6>
          </div>
          <p className="mt-2">{t('patient.healthIssues.workoutPrepared')}.</p>
        </div>
      ) : (
        <>
          {(locked ? issues.slice(0, 1) : issues).map((item) => (
            <IssueCard key={item.id} issue={item} onClick={onViewIssue ? () => onViewIssue(item) : undefined} />
          ))}
          {locked && (
            <>
              <UnlockScanButton className="w-full" scan={scan} onUnlock={onUnlock} />
              <div className="pointer-events-none flex flex-col gap-3 blur-sm">
                {issues.slice(1).map((item) => (
                  <IssueCard key={item.id} issue={item} />
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
